import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const DELETE_PROJECT = createActionType("DELETE_PROJECT", "PROJECT");

export const deleteProject = (id: string, options?: ActionOption) => ({
  type: DELETE_PROJECT.START,
  meta: {
    ...options,
    id,
  },
});
