import { EditProjectContainer } from "../../../../container"




const EditSacrificePage = () => {
  
    
  return <EditProjectContainer/>
}

export default EditSacrificePage
