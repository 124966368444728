import { createActionType } from "../../../../_shared/utils";

export const SEND_METHOD = createActionType("SEND_METHOD", "CONTRACT");

export const sendMethod = (
  contractName: string,
  functionName: string,
  functionArgs: any[],
  sendArgs: any,
  options: {
    key?: string;
    onFinish?: () => void;
    onError?: (e: any) => void;
    noSuccessMessage?: boolean;
    noErrorMessage?: boolean;
    successMessage?: string;
    errorMessage?: string;
    estimateGas?: boolean;
  }
) => ({
  type: SEND_METHOD.START,
  meta: {
    contractName,
    functionName,
    functionArgs,
    sendArgs,
    options,
  },
});
