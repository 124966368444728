import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_ALL_WHITELIST = createActionType(
  "GET_ALL_WHITELIST",
  "WHITELIST"
);

export const getAllWhitelists = (options?: ActionOption) => ({
  type: GET_ALL_WHITELIST.START,
  meta: {
    ...options,
  },
});
