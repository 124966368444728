import { Middleware } from "redux";
import { RootState } from "../../../state";
import { serverRequest } from "../../../actions/app/api";
import { APP_URL, POST } from "../../../../_shared/constants";
import { CREATE_PROJECT } from "../../../actions";

export const createProject: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_PROJECT.START) {
      const { payload, onFinish, ...rest } = action.meta;
      dispatch(
        serverRequest({
          method: POST,
          url: `${APP_URL.project.create}`,
          onFinish,
          payload,
          onSuccess: CREATE_PROJECT.SUCCESS,
          ...rest,
        })
      );
    }
  };
