import {  DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Dropdown, Form, Input, Menu, Space, Tooltip, Typography } from "antd";
import { ReferralBonusWrapper, FormWrapper } from "./index.styled";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import dayjs from 'dayjs';
const {Text} = Typography;

interface ReferralBonusComponentProps{
    onFinish? : ()=>void;
    loading: boolean;
    project: ProjectNamespace.Project;
}
export const ReferralBonusComponent = (props: ReferralBonusComponentProps) => {
    const {onFinish, project, loading} = props;

    const [form] = useForm();
    const initialValue = {
        
        phases: project.phases,
        startDayTime: project.startDayTime?dayjs(project.startDayTime*1000):dayjs(),
        referralBonusPercent: project.referralBonusPercent,
        refereeBonusPercent: project.refereeBonusPercent,
        uplineMustBeSacrificer: project.uplineMustBeSacrificer,
        minContributionAmount: project.minContributionAmount,
    }
    
    useEffect(() => {
        form.setFieldsValue(initialValue)
        console.log({initialValue})
    }, [project]);
    
    return (
          <ReferralBonusWrapper>
            
             <FormWrapper
                name="distribution_token"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={'optional'}
                form={form}
                >
                <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Upline Bonus</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="referralBonusPercent"
                        
                        rules={[
                                { required: false, message: 'Please input percentage!', },
                                {
                                    message: 'Value must be 0 - 100!',
                                    validator: async (_, value) => {
                                        if (!value || value < 0  || value > 100) {
                                            return Promise.reject(new Error('Value must be 0 - 100'));
                                        }
                                    },
                                }
                            ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Referee Bonus</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>
                            
                        </Space>}
                        
                        name="refereeBonusPercent"
                        rules={[
                            { required: false, message: 'Please input percentage!', },
                            {
                                message: 'Value must be 0 - 100!',
                                validator: async (_, value) => {
                                    if (!value || value < 0  || value > 100) {
                                        return Promise.reject(new Error('Value must be 0 - 100'));
                                    }
                                },
                            }
                        ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        name="uplineMustBeSacrificer"
                        // requiredMark={'optional'}
                        valuePropName="checked"
                    >
                        <Checkbox defaultChecked={false}>Upline must be a previous sacrificer</Checkbox>
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Minimum Contribution Amount</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="minContributionAmount"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input amount!',  },
                                {
                                    validator: async (_, value) => {
                                        console.log(`Please input amount must be 0 or more!`, value)
                                        if (!value || value < 0) {
                                            return Promise.reject(new Error('Please input amount must be 0 or more'));
                                        }
                                    },
                                }]}
                        
                    >
                        <Input type={"number"} min="0" placeholder="Enter Amount" suffix="USD" />
                    </Form.Item>

                    <Divider style={{marginTop: 40}} />

                    

                    <Form.Item>
                        
                        <Button loading={loading} shape="round" className="finish-btn float-right"  type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
            </FormWrapper>
          </ReferralBonusWrapper>
    );
};