import { Avatar, Button, Form, Input, Row, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { NETWORKS_TYPE } from "../../../../../_shared";
import { ModalWrapper } from "../index.styled";
import { FormWrapper } from "./index.styled";
import Web3 from "web3";
import _ from "lodash";

interface AddTokenModalProps {
  visible: boolean;
  onFinish?: ((values: any) => void) | undefined;
  onClose?: () => void;
  currentNetwork: NETWORKS_TYPE;
}
export const AddTokenModal = (props: AddTokenModalProps) => {
  const { visible, onFinish, onClose, currentNetwork } = props;
  const [form] = Form.useForm();

  const [addresses, setAddresses] = useState<string[]>([]);

  const handleTokenClicked = (address: string) => {
    const items = _.uniq<string>(
      (form.getFieldValue("token_address") ?? "")
        .split(",")
        .filter((v: string) => Web3.utils.isAddress(v))
    );
    if (
      items.find(
        (v: string) => v.trim().toLowerCase() === address.toLowerCase()
      )
    ) {
      setAddresses(
        items
          .filter((v: string) => v.toLowerCase() !== address.toLowerCase())
          .filter((v: string) => v !== "")
      );
    } else {
      setAddresses(items.concat(address).filter((v: string) => v !== ""));
    }
  };

  const handleTokensChanged = () => {
    const items = (form.getFieldValue("token_address") ?? "")
      .split(",")
      .filter((v: string) => Web3.utils.isAddress(v));
    if (
      !(
        addresses.length === items.length &&
        addresses.every(function (value, index) {
          return value === items[index];
        })
      )
    ) {
      handleTokenClicked("");
    }
  };

  useEffect(() => {
    // console.log('CURRENTNETWROL', addresses);
    if (addresses.length === 0) form.setFieldsValue({ token_address: "" });
    if (addresses.length === 1)
      form.setFieldsValue({ token_address: addresses[0] });
    if (addresses.length > 1)
      form.setFieldsValue({ token_address: addresses.join(",") });
  }, [addresses]);

  return (
    <ModalWrapper
      title="Add New Token"
      footer={null}
      visible={visible}
      onCancel={onClose}
    >
      <FormWrapper
        name="add_token"
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        onFinish={(data) => {
          onFinish?.(data);
          form.resetFields();
        }}
        autoComplete="off"
        requiredMark={"optional"}
      >
        <Form.Item
          label="Token Address"
          name="token_address"
          // requiredMark={'optional'}
          rules={[{ required: true, message: "Please input token address!" }]}
        >
          <Input
            onChange={handleTokensChanged}
            placeholder="Enter Token Addresses (comma separated)"
          />
        </Form.Item>
        <Form.Item>
          <Row space-between className="flex">
            <Space>
              {(currentNetwork?.acceptedTokens ?? []).map(
                (n) =>
                  n && (
                    <Button
                      icon={<Avatar src={n.logo}  size={18} className="bg-gray-50"/>}
                      onClick={() => handleTokenClicked(n.address)}
                      shape="round"
                      className={
                        `${!!addresses.find(
                          (v: string) =>
                            v.toLowerCase() === (n?.address ?? "").toLowerCase()
                        )
                          ? "selected-btn"
                          : ""} h-auto`
                      }
                      type="ghost"
                    >
                      {n.symbol}
                    </Button>
                  )
              )}
            </Space>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button className="submit-btn" type="primary" htmlType="submit">
            Add Token
          </Button>
        </Form.Item>
      </FormWrapper>
    </ModalWrapper>
  );
};
