import { Middleware } from "redux";
import { RootState } from "../../../state";
import { serverRequest } from "../../../actions/app/api";
import { GENERIC } from "../../../actions";

export const getGeneric: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GENERIC.START) {
      const { id, payload, onFinish, suffix, urlSuffix, method, ...rest } =
        action.meta;
      dispatch(
        serverRequest({
          method,
          url: `${urlSuffix}${suffix ?? ""}`,
          onFinish,
          payload,
          onSuccess: GENERIC.SUCCESS,
          ...rest,
        })
      );
    }
  };
