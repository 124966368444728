import { GlobalToken } from "antd";
import styled from "styled-components";

export const PmContainer = styled.div<{ token: GlobalToken }>`
  th {
    background-color: ${({ token }) => token.colorBgLayout}!important;
    color: ${({ token }) => token.colorTextDisabled}!important;
    white-space: nowrap;
  }
  th,
  td {
    border-bottom: 20px solid ${({ token }) => token.colorBgLayout}!important;
  }
  .ant-pagination-total-text {
    margin-right: auto !important;
  }
  .ant-table-content {
    overflow: auto;
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--landing-background-3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--landing-background);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--landing-background-2);
    }
  }

  tr:not(.ant-table-placeholder) {
    th:last-child,
    td:last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: 2.5rem;
      gap: 8px;
    }
  }
  /* .ant-table-placeholder{
    display: none;
  } */

  /* var(--success-color) */
`;
