import { createActionType } from "../../../../_shared/utils";

export const RESET_METHOD = createActionType("RESET_METHOD", "CONTRACT");
type optionType = {
  key?: string;
  callKey?: string;
  onFinish?: (d: any) => any;
  onError?: (e: any) => any;
};
export const resetMethod = (
  contractName: string,
  functionName: string,
  args: Array<any>,
  options?: optionType
) => ({
  type: RESET_METHOD.START,
  meta: {
    contractName,
    functionName,
    args,
    options,
  },
});
