import styled from "styled-components";

export const ShareWrapper = styled.div`
  .ant-form,
  .ant-form-item-control,
  .ant-input-textarea,
  .ant-picker,
  .ant-input-number {
    width: 100% !important;
  }
  .ant-form-item-control {
    input,
    select,
    .ant-select-selector {
      color: var(--text-color) !important;
      height: 56px !important;
      ::placeholder {
        color: var(--disabled-color);
      }
    }
    textarea {
      background-color: transparent;
    }
  }

  .ant-input-disabled {
    user-select: auto !important;
    cursor: pointer !important;
  }
`;
