import { message, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SacrificeComponent } from "../../components";
import { WalletConnectorContext } from "../../contexts";
import { useProject, useSacProxy, useUIState, useUser } from "../../hooks";

import {
  copyToClipboard,
  EMPTY_ADDRESS,
  getCurrentFullUrl,
  NETWORKS_TYPE,
  resolveCurrentNetworkObject,
} from "../../_shared";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { UserNamespace } from "../../_shared/namespaces/user";
import { UserDataStat } from "../../_shared/utils/type";
import { Container } from "./index.styled";
import { SacrificeComponentV2 } from "../../components/sac_v2";
import { ShareModal } from "../../components/view-project/libs/modal/share";

export const ViewSacrificeContainer = () => {
  const { address, chainId, switchNetwork, provider, loadingSigning } =
    useContext(WalletConnectorContext);
  const KEY = `@@view-a-sac`;

  const WEB3_KEY = `@@view-a-sac-web-3`;
  const MY_KEY = `@@view-a-sac-me`;
  const { uiLoaders } = useUIState();

  const { id } = useParams();
  const loading = uiLoaders[KEY];

  const [project, setProject] = useState<ProjectNamespace.Project>();

  const [userDataStats, setUserDataStats] = useState<UserNamespace.User>();
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false);

  const [toggle, setToggle] = useState(false);

  const { handleGet } = useProject({
    key: KEY,
  });

  const { handleGet: handleGetUser } = useUser({
    key: MY_KEY,
  });

  const {
    sacrificePhaseDay,
    address: sacAddress,
    pointRate,

    acceptedTokens,
    getSacrificePhaseDay,
    getSacrificeContractAddress,
    getPointRate,
    getAcceptedTokens,
    getUsdDecimals,
    initialized: proxyInitialized,
    usdDecimals,
    // setProjectContractVersion,
  } = useSacProxy({
    key: WEB3_KEY,
    address,
    provider,
    project: project ?? null,
  });

  const [setOpenShare, setSetOpenShare] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<NETWORKS_TYPE>();
  const [loadingNetworkSwitch, setLoadingNetworkSwitch] = useState(false);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [loadingVote, setLoadingVote] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [projectUtils, setProjectUtils] =
    useState<ProjectNamespace.UtilsData>();

  useEffect(() => {
    if (id && proxyInitialized) {
      getUsdDecimals();
      setLoadingProject(true);
      handleGet(`${id}/signature`, {
        onFinish: (data: any) => {
          setProject(data);
          // console.log('CONTRACTVERSS')
          // setProjectContractVersion(data?.contractVersion??9)
          setLoadingProject(false);
        },
      });
    }
  }, [id, proxyInitialized]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(`-----*******`, projectUtils);
  }, []);

  const loadData = useCallback(
    (id?: string) => {
      if (id)
        handleGet(`${id}/signature`, {
          onFinish: (data: any) => {
            setProject(data);
          },
        });

      if (address) {
        setLoadingUserData(true);
        handleGetUser(`${address}/project/${id}`, {
          onFinish: (data: any) => {
            setUserDataStats(data);
            setLoadingUserData(false);
          },
          onError: (e) => {
            setLoadingUserData(false);
          },
        });
      }
    },
    [address, handleGet]
  );

  useEffect(() => {
    loadData(id);
  }, [address]);

  useEffect(() => {
    setTimeout(() => {
      loadData(id);
    }, 3000);
  }, [toggle]);

  useEffect(() => {
    if (
      project &&
      provider &&
      project.signature &&
      proxyInitialized &&
      chainId
    ) {
      getSacrificeContractAddress(`${project.id}`, project.contractVersion);
    }
  }, [project, chainId, proxyInitialized]);

  useEffect(() => {
    if (
      chainId &&
      project?.networks?.length &&
      !project?.networks.includes(String(chainId))
    ) {
      console.log("CHAINID----->", project?.networks[0], provider);
      switchNetwork?.(Number(project?.networks[0])).catch((e) => {
        console.log({ cccc: e });
      });
    }
  }, [project, chainId, provider]);

  useEffect(() => {
    if (sacAddress && provider && proxyInitialized) {
      getSacrificePhaseDay();
      getPointRate();
      if (project?.projectAddress) getAcceptedTokens(project.projectAddress);
      // getUsdDecimals();
    }
  }, [provider, sacAddress, proxyInitialized]);

  useEffect(() => {
    if (chainId && proxyInitialized && sacAddress) {
      setCurrentNetwork(resolveCurrentNetworkObject(`${chainId}`));
      if (project?.projectAddress) getAcceptedTokens(project.projectAddress);
      if (sacAddress) {
        getUsdDecimals();
      }
    }
  }, [chainId, proxyInitialized, sacAddress]);

  useEffect(() => {
    if (!id) return;
    loadUtils(id);
  }, [id, address]);

  const loadUtils = useCallback(
    (id: string) => {
      handleGet(`${id}/chainData/${address ?? EMPTY_ADDRESS}`, {
        onFinish: (data: any) => {
          setProjectUtils(data);
        },
      });
    },
    [address, handleGet]
  );

  if (loadingSigning) {
    return <h1>Please Sign on metamasK</h1>;
  }

  const handleOnClose = () => {
    setSetOpenShare(false);
  };

  const handleOnCopy = () => {
    copyToClipboard(`${getCurrentFullUrl(address ?? "")}`);
    
  };
  const handleShareButton = () => {
    setSetOpenShare(true);
  };
  const handleFollow = (action: string | undefined = "follow") => {
    if (loadingFollow) return;
    setLoadingFollow(true);
    handleGet(`${id}/${action}`, {
      method: "put",
      onFinish: (data: any) => {
        setToggle((old) => !old);
        setLoadingFollow(false);
      },
      onError: () => {
        setLoadingFollow(false);
      },
    });
  };

  const handleOnFinishSacrifice = () => {
    setLoadingUserData(true);
    setTimeout(() => setToggle((old) => !old), 5000);
  };

  const handleSwitchNetwork = async (data: any) => {
    setLoadingNetworkSwitch(true);

    switchNetwork?.(data.chainId)
      .then(() => {
        setLoadingNetworkSwitch(false);
      })
      .catch((_) => setLoadingNetworkSwitch(false));
  };

  const onCountDownComplete = () => {
    setToggle((old) => !old);
  };

  const shareData = {
    visible: setOpenShare,
    onClose: handleOnClose,
    onCopied: handleOnCopy,
    text: getCurrentFullUrl(address ?? ""),
  };

  const dataStates = {
    project: { ...project, fullUrl: getCurrentFullUrl(address ?? "") },
    sacrificePhaseDay,
    pointRate,
    currentNetwork,
    sacAddress,
    acceptedTokens,
    deployToggle: toggle,
    loadingNetworkSwitch,
    provider,
    userDataStats: userDataStats as unknown as UserDataStat,
    loading: loading,
    usdDecimals: Number(usdDecimals ?? 0),
    projectUtils,
  };

  return (
    <Container>
      {/* <SacrificeComponent
            {...dataStates}
            handleShareButton={handleShareButton} 
            
            // handleAmountNetworkChange={handleAmountNetworkChange}
            handleOnFinishSacrifice={handleOnFinishSacrifice}
            handleSwitchNetwork={handleSwitchNetwork}
            onComplete={onCountDownComplete}
            shareData={shareData}
            uiLoaders={uiLoaders}
            /> */}
      {!project && <Spin style={{ marginTop: "30vh" }} />}
      {!!project && (
        <>
          <SacrificeComponentV2
            address={address}
            {...dataStates}
            handleShareButton={handleShareButton}
            handleFollow={handleFollow}
            handleOnFinishSacrifice={handleOnFinishSacrifice}
            handleSwitchNetwork={handleSwitchNetwork}
            onComplete={onCountDownComplete}
            shareData={shareData}
            uiLoaders={{ ...uiLoaders, loadingFollow, loadingUserData }}
            handleVote={() => {
              if (!id) return;
              setTimeout(function () {
                loadUtils(id);
              }, 5000);
            }}
          />
          <ShareModal {...shareData} subHeading="Your Referral Link" />
        </>
      )}
    </Container>
  );
};
