import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GENERIC = createActionType("GENERIC", "GENERIC");

export const getGeneric = (
  options?: ActionOption,
  payload?: Record<string, any>
) => ({
  type: GENERIC.START,
  meta: {
    ...options,
    payload,
  },
});
