import { Space } from "antd";
import styled from "styled-components";

export const SpaceWrapper = styled(Space)`
    &, .header-row, .list-item-row{
        width: 100%;
    }
    
    .header-row{
        
        justify-content: space-between;
        .ant-typography{
            color: var(--disabled-color)!important;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .list-item-row{
        justify-content: space-between;   
        border-bottom: 1px solid var(--border);
        padding-bottom: 24px;
        margin-bottom: 24px;
        .ant-typography{
            
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
`;
