import { Button, message, notification, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { WalletConnectorContext } from "../../contexts";

import { Container } from "./index.styled";
import Web3 from "web3";
import { sacAdminAbi } from "../../_shared/ABIcode/sac-admin";
import { NETWORKS, PSAC_TOKEN_ADDRESS, SAC_ADMIN_ADDRESS } from "../../_shared";
import { size } from "lodash";

export const FaucetContainer = () => {
  const { address, provider, loadingSigning } = useContext(
    WalletConnectorContext
  );

  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const KEY = `@@faucet`;

  const getToken: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );

    try {
      const getToken: any = await contract.methods["getToken"](
        PSAC_TOKEN_ADDRESS
      ).send({
        from: address,
      });
      console.log({ getToken });
      notification.success({
        message: "Sent Successfully",
      });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const addPSACToken: () => Promise<boolean> = async () => {
    // const web3 = new Web3(provider);

    // const contract = new web3.eth.Contract(
    //   sacAdminAbi as any,
    //   SAC_ADMIN_ADDRESS
    // );

    // const _network = NETWORKS.find(item=>item.chainId=="369");

    try {
      await window?.ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: PSAC_TOKEN_ADDRESS,
            symbol: "tSAC",
            decimals: 18,
          },
        },
      });
      console.log({ getToken });
      notification.success({
        message: "Added Successfully",
      });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  return (
    <Container>
      <Button
        onClick={async () => {
          setLoading(true);
          await getToken();
          setLoading(false);
        }}
        type="primary"
        className="mt-20 w-fit mx-auto"
        loading={loading}
        size="large"
      >
        {" "}
        Get 10,000,000 PSAC Tokens
      </Button>

      <Button
        onClick={async () => {
          setLoadingSwitch(true);
          await addPSACToken()
          setLoadingSwitch(false);
        }}
        type="primary"
        ghost
        className="mt-20 w-fit mx-auto"
        loading={loadingSwitch}
        size="large"
      >
        {" "}
        Add PSAC Token to meta mask
      </Button>
    </Container>
  );
};
