import { ActionOption } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/state";
import { useEffect } from "react";
import {
  createWhitelist,
  updateWhitelist,
  deleteWhitelist,
  getAllWhitelists,
  getWhitelist,
} from "../../redux/actions";
import { WhitelistNamespace } from "../../_shared/namespaces/whitelist";

interface UseWhitelist {
  whitelist: WhitelistNamespace.Whitelist;
  allWhitelists: WhitelistNamespace.Whitelist[];
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => void;
  handleGetAll: (options?: ActionOption) => void;
  handleDelete: (id: string, options?: ActionOption) => void;
  handleGet: (id: string, options?: ActionOption) => void;
}

interface UseWhitelistProps {
  key: string;
  autoFetch?: boolean;
  options?: ActionOption;
}

export const useWhitelist = (parameter: UseWhitelistProps): UseWhitelist => {
  const { key, autoFetch = false, options: defaultOptions = {} } = parameter;

  const dispatch = useDispatch();

  const { whitelist, allWhitelists } = useSelector((state: RootState) => {
    return {
      whitelist: state.whitelist.whitelist[key] ?? {},
      allWhitelists: state.whitelist.allWhitelists[key] ?? [],
    };
  });

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      createWhitelist(payload, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleUpdate = (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => {
    dispatch(
      updateWhitelist(payload, id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(
      deleteWhitelist(id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGetAll = (options?: ActionOption) => {
    dispatch(
      getAllWhitelists({
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGet = (id: string, options?: ActionOption) => {
    dispatch(
      getWhitelist(id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  useEffect(() => {
    if (autoFetch) {
      handleGetAll({
        key: key,
      });
    }
  }, [autoFetch]);

  return {
    whitelist,
    allWhitelists,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleGetAll,
    handleGet,
  };
};
