
import {  Button, Col, Row, Space, Typography } from "antd";
import { Container} from "./index.styled";

const { Title } = Typography;
interface JoinSectionProps {
    text?: string;
}
export const JoinSection = (props: JoinSectionProps) => {
    const { } = props;
    
    return (
        <Container>
          <Row className="main-join-container ant-row-center">
            <Col xs={24} lg={12} className="left">
              <Space>
                <Space className="title-container" size={8} direction="vertical">
                  <Title>Join the Telegram Community</Title>
                  <a  rel="noreferrer" href="https://t.me/PulseSac" target="_blank">
                    <Button type="primary">Join Community</Button>
                  </a>
                </Space>
              </Space>
            </Col>
            <Col span={12} className="right">
              <img src="./stripe.svg" alt="..." />
            </Col>
          </Row>
        </Container>
      );
};


