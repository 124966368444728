
import { Typography, Row, Col, Space, Tabs, Avatar, Progress, Collapse } from "antd";

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;




interface AvailableSectionProps {
    text?: string;
}
export const AvailableSection = (props: AvailableSectionProps) => {
    const { } = props;
    


    const renderRows =  [
        {
            url:'/logo-sm.svg',
            name:'PulseSac Green',
            startDate:'Starts 20 July, 2022',
            endDate:'Ends 25 September,2022',
            total:'100,000,000 PBALLS',
        },
        {
            url:'/verified.svg',
            name:'PulseSac Redd',
            startDate:'Starts 20 July, 2022',
            endDate:'Ends 25 September,2022',
            total:'100,000,000 PBALLS',
        },
        {
            url:'/pulsesac_logo.svg',
            name:'PulseSac Green',
            startDate:'Starts 20 July, 2022',
            endDate:'Ends 25 September,2022',
            total:'100,000,000 PBALLS',
        },
        {
            url:'/download.jpg',
            name:'PulseSac Brown',
            startDate:'Starts 20 July, 2022',
            endDate:'Ends 25 September,2022',
            total:'100,000,000 PBALLS',
        },
        
    ].map((_, key)=>(
        <>
            <Row key={key} className="list-row desktop-list-row" align="middle">
                <Col className="gutter-row" span={8}>
                    <Space className="col-one" size={20}>
                        <Avatar size={40} shape="square" src={_.url}/>
                        <Text>{_.name}</Text>
                    </Space>
                </Col>
                <Col className="gutter-row" span={5}>
                    <Space className="col-two" direction="vertical">
                        <Text>{_.startDate}</Text>
                        <Text>{_.endDate}</Text>
                    </Space>
                </Col>
                <Col className="gutter-row" span={5}>
                    <Space className="col-two" direction="vertical">
                        <Text>{_.total}</Text>
                    </Space>
                </Col>
                <Col className="gutter-row" style={{flexGrow: 1}}>
                    <Progress status="success" percent={Math.floor(Math.random() * 100)+1} showInfo={false} strokeWidth={14} />
                </Col>
            </Row>
            <Collapse className="mobile-available-collapse" key={key} >
                <Panel header={
                    <Row key={key} className="list-row" align="middle"  style={{width:'100%', margin: 0}}>
                        <Col className="gutter-row" span={4}>
                            <Space className="col-one" size={20}>
                                <Avatar size={40} shape="square" src={_.url}/>
                            </Space>
                        </Col>
                        <Col className="gutter-row" >
                            <Space className="col-one" size={20}>
                                <Text>{_.name}</Text>
                            </Space>
                        </Col>
                    </Row>
                } key="1">
                    <Row className="col-two" justify="space-between" style={{marginBottom: 24}}>
                        <Text className="muted-title">Start Date</Text>
                        <Text>{_.startDate}</Text>
                    </Row>
                    <Row className="col-two" justify="space-between" style={{marginBottom: 24}}>
                        <Text className="muted-title">End Date</Text>
                        <Text>{_.endDate}</Text>
                    </Row>
                    <Row className="col-two" justify="space-between" style={{marginBottom: 24}}>
                        <Text className="muted-title">Available Tokens</Text>
                        <Text>{_.total}</Text>
                    </Row>
                    <Row className="col-two" justify="space-between" style={{marginBottom: 24}}>
                        <Col>
                            <Text className="muted-title">Sacrifice Progress</Text>
                        </Col>
                        <Col span={12}>
                            <Progress  status="success" percent={Math.floor(Math.random() * 100)+1} showInfo={false} strokeWidth={14} />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </>
    ));

    const headers = <Row className="header-row list-row"  style={{marginTop: '64px'}}>
    <Col className="gutter-row" span={8}>
        
    </Col>
    <Col className="gutter-row" span={5}>
        Schedule
    </Col>
    <Col className="gutter-row" span={5}>
        Available Tokens
    </Col>
    <Col className="gutter-row" style={{flexGrow: 1}}>
        Sacrifice Progress
    </Col>
</Row>
    
    
    return (<>
                

                <Row className="list-row-header">
                    <Title level={2} style={{}}>
                        Available Sacrifices
                    </Title>
                
                </Row>

                <Tabs className="available-tab" defaultActiveKey="1" centered>
                    <TabPane tab="Ongoing Project Sacrifice" key="1">
                        {headers}
                        {renderRows.length? renderRows : 'No Projects Found. Please check back later'}
                    </TabPane>
                    <TabPane tab="Upcoming Project Sacrifice" key="2">
                    {headers}
                    <Row className="text-center"> {renderRows.length? renderRows : 'No Projects Found. Please check back later'}</Row>
                    </TabPane>
                    <TabPane tab="Past Project Sacrifice" key="3">
                    {headers}
                    {renderRows.length? renderRows : 'No Projects Found. Please check back later'}
                    </TabPane>
                </Tabs>
            </>)
};

