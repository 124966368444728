import { EditProjectComponent } from "../../components";
import { useProject, useUIState } from "../../hooks";
import { notification } from "antd";

import { useContext, useEffect, useState } from "react";
import { Container } from "./index.styled";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { WalletConnectorContext } from "../../contexts";
import moment from "moment";
import dayjs from "dayjs";

export const EditProjectContainer = () => {
  const KEY = `@@edit-project`;
  const navigate = useNavigate();
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  const { uiLoaders } = useUIState();
  const loading = uiLoaders[KEY];
  const [totalPhaseDays, setTotalPhaseDays] = useState(0);
  const {address, connect, loading: loadingConnect} = useContext(WalletConnectorContext);
  const { project, handleGet, handleUpdate } = useProject({
    key: KEY,
  });
  const [toggle, setToggle] = useState(false);
  const tab = searchParams.get("tab"); // "text"


  useEffect(() => {
    if(id) handleGet(`${id}/signature`);
  }, [id, toggle]);

  useEffect(() => {
    if(address && project.user && `${address}`.toLowerCase() !== project.user){
      navigate("/projects")
    }
  }, [address, project.user]);

  useEffect(() => {
    if(!project) return;
    const {startDayTime, phases=[]} = project;
    const daysDiff = moment.duration(dayjs(startDayTime).startOf('day').diff(dayjs().startOf('day'))).asDays() 
    + 
    phases.reduce((prev:number, current:any)=>(prev +current.duration), 0);
    setTotalPhaseDays(daysDiff)
  }, [project]);
  
  

  const updateProject = (data: any, cb: ()=>void) =>{
    
    handleUpdate(data,`${id}`, {
      onFinish: (_: Record<string, any>) => {
        notification.open({
          message: 'Success',
          description:
            'Project Updated Successfully'
        });
        cb()
        
        
      },
    });
    

    // router.push('/projects/new/1')
  }
  const handleBackArrow = ()=>{
    navigate(-1)
  }
  const _OnSubmitFormOne =  (data: any)=>{
    updateProject(data, ()=>{
      setToggle(old=>!old)
    })
    
    console.log('data', data);
  }

  const _OnSubmitFormTwo =  (data: any)=>{
    const {uplineMustBeSacrificer, startDayTime} = data;
    const mData = {
      ...data, 
      uplineMustBeSacrificer: !!uplineMustBeSacrificer, 
      startDayTime: startDayTime.unix()
    }
    updateProject(mData, ()=>{
      setToggle(old=>!old)
    })
    console.log('data', {...data, startDayTime: data.startDayTime.unix()}, totalPhaseDays);
  }

  const _OnSubmitFormThree = (data: any) =>{
    const mData = {
      ...data,
        vestingSchedule: data.vestingSchedule.map((vestingSchedule:any)=>({
          ...vestingSchedule, 
          timestamp: vestingSchedule.timestamp.unix()
        }
        )),
    }
    updateProject(mData, ()=>{
      setToggle(old=>!old)
    })
    console.log('data: any',mData, {...data})
    
    

    // router.push('/projects/new/1')
  }
  const handleConnect = ()=>{
    connect?.();
  }
  useEffect(() => {
    console.log({tab})
  }, [tab]);
  return (
    <Container>
        <EditProjectComponent
          project={project} 
          handleBackArrow={handleBackArrow} 
          onComplete={_OnSubmitFormThree}
          _OnSubmitFormOne={_OnSubmitFormOne}
          _OnSubmitFormTwo={_OnSubmitFormTwo}
          loading={loading}
          loadingConnect={loadingConnect}
          handleConnect={handleConnect}
          address={address}
          totalPhaseDays={totalPhaseDays}
          />
    </Container>
  );
};
