import { ShareAltOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Space, Spin, Typography } from "antd";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { computeEndDate } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { UtilCard } from "../../../utils";
const { Text } = Typography;

interface ListDatail{
  key: ReactNode,
  value: ReactNode,
}

interface TopDetailComponentProps{
    detailList: Array<ListDatail>;
    loadingAddress: boolean
    handleBackButton: (data?:any)=>void;
    handleShareButton: (data?:any)=>void;
    project: ProjectNamespace.Project;
    isOwner: boolean
}

export const TopDetailComponent = (props: TopDetailComponentProps) => {
  const {detailList, loadingAddress, project, isOwner, handleBackButton, handleShareButton} = props;
  const navigate = useNavigate();
  // console.log(`project`, project)

  const renderDataList = detailList.map((data, key)=>(
    <Row key={key} justify="space-between" align="middle" >
              <Col md={12} sm={24}>
                <Text className="muted">{data.key}</Text>
              </Col>
              <Col md={12}  sm={24} style={{textAlign: 'right'}}>
                <Text className="">{data.value}</Text>
              </Col>
    </Row>
  ))
  return (
        <>
          <Space className="header-row" align="center" >
            <Button type="text" onClick={handleBackButton} icon={
              <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 7H20.5M1.5 7L7.54545 1.5M1.5 7L7.54545 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>
            }/>
            <Text>Back to Projects</Text>
          </Space>
         
          <Space className="profile-row" size={20} align="center">
              <Avatar src={project.logo} alt={project.logo} size={62} shape="square" />
              <Space className="title-row" direction="vertical">
              <Text className="name">{project.title}</Text>
                <Text className="date">{(new Date(project.startDayTime * 1000)).toDateString()
                  } - {(new Date(computeEndDate(project.startDayTime, project.phases))).toDateString()
                  }</Text>
                
              </Space>
             
              <Space className="extra-row">
             
                <Button onClick={handleShareButton} icon={<ShareAltOutlined />} type="text"/>
                {/* <Button icon={<MoreOutlined />} type="text"/> */}
              </Space>
              {isOwner && 
                    <Button onClick={()=>navigate(`/projects/${project.id}/edit`)} shape="round">Edit</Button>
             }
          </Space>
          <UtilCard className="detail-card" style={{padding: '20px 20px'}}>
            {loadingAddress?<Spin/>:renderDataList}
          </UtilCard>
        </>
  );
};