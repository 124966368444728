import styled from "styled-components";
import { UtilCard } from "../../../utils";


export const CoverContainer = styled(UtilCard)`

    gap: 20px; 
    diable: {
        color: #9494e4 !important;
    }
`;
