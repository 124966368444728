import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const CREATE_PROJECT = createActionType("CREATE_PROJECT", "PROJECT");

export const createProject = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_PROJECT.START,
  meta: {
    ...options,
    payload,
  },
});
