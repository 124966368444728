import * as React from "react"

function Pen(props: any) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.12 2.88a2.154 2.154 0 00-3.046 0L3.755 11.2a1.856 1.856 0 00-.448.725L2.28 15.011a.562.562 0 00.711.712l3.086-1.03c.273-.09.52-.243.724-.447l8.32-8.318a2.152 2.152 0 000-3.046V2.88zm-2.25.796a1.028 1.028 0 111.454 1.455l-.824.825L12.045 4.5l.826-.825h-.001zm-1.62 1.62l1.453 1.455-6.698 6.698a.73.73 0 01-.285.177l-2.018.673.673-2.018a.73.73 0 01.175-.286l6.7-6.699z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Pen;
