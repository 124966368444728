import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_ALL_USER = createActionType("GET_ALL_USER", "USER");

export const getAllUsers = (options?: ActionOption) => ({
  type: GET_ALL_USER.START,
  meta: {
    ...options,
  },
});
