import { MainContainer, MainFooter, MainHeader } from '../../../components';
import { ReactNode } from 'react';
import { PmContainer } from './index.styled';
import { theme } from 'antd';
const { useToken } = theme;


interface PmLayoutProps {
  children: ReactNode | ReactNode[];
  renderClassName?: string;
  controlled?: boolean;
}

export const PmLayoutContainer = (props: PmLayoutProps) => {
  const { children, renderClassName = 'kdfj', controlled } = props;
  const { token } = useToken();

  return (
    <PmContainer token={token}>
      <MainHeader flashHeader={false} />
      <main className="w-full">{children}</main>
      <MainFooter />
    </PmContainer>
  );
};
