import styled from "styled-components";

export const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .disable{
        color: var(--disabled-color)!important;
    }  
    .muted{
        color: var(--accent2)!important;
        
    }
    .bg-success{
        background-color: var(--accent)!important;
        border-color: var(--accent)!important;
    }
    .grey-bg{
        background-color: var(--disabled-color-2)!important;
    }
    
    .ant-divider{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        border-color: var(--border)!important;
    }
    .title-text{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &-1{
            font-size: 18px;
        }
    } 
    
    .select-after, .ant-select-selector{
        /* background-color: var(--accent)!important; */
        border-radius: 4px!important;
        color: #fff!important;
    }

    ._ball{
        width: 8px!important;
        height: 8px!important;
        border-radius: 8px!important;
        display: flex;
    }
`;

export const WrapperPrimaryContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%!important;
    align-items: flex-start;
    justify-content: center;
    gap: 1.25%!important;
    .bolder{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
`;

export const WrapperSecondaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 44%!important;
    @media (max-width: 768px) {
        width: 100%!important;
    }
`;
