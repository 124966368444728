import { combineReducers } from "redux";

import ui from "./ui";
import app from "./app";
import project from "./project";
import user from "./user";
import whitelist from "./whitelist";
import contract from "./contract";
import generic from "./generic";

const appReducers = combineReducers({
  ui,
  app,
  project,
  user,
  whitelist,
  contract,
  generic,
});

export default appReducers;
