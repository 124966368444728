import { FaucetContainer } from "../../container";



const FaucetPage = () => {
  
    
  return <FaucetContainer/>
}

export default FaucetPage
FaucetPage.renderClassName = "__app_secondary";