import { createActionType } from "../../../../_shared/utils";

export const CALL_METHOD = createActionType("CALL_METHOD", "CONTRACT");
type optionType = {
  key?: string;
  callKey?: string;
  onFinish?: (d: any) => any;
  onError?: (e: any) => any;
};
export const callMethod = (
  contractName: string,
  functionName: string,
  args: Array<any>,
  options?: optionType
) => ({
  type: CALL_METHOD.START,
  meta: {
    contractName,
    functionName,
    args,
    options,
  },
});
