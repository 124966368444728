import styled from "styled-components";

export const NewProjectSuccessWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px !important;
    gap:8px;
    
    img.rocket{
        margin-top: 60px;
    }
    .main-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        margin-top: 30px;
    }

    .sub-text{
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        
        margin-bottom: 50px;
    }

    button.ant-btn{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        height: 48px;

        &.btn-visit{
            background-color: #22C55E!important;
            border-color: #22C55E!important;
        }

    }
    
`;
