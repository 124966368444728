import { MyProjects } from "../../components";
import { WalletConnectorContext } from "../../contexts";
import { useProject, useUIState, useUser } from "../../hooks";
import { useContext, useEffect, useState } from "react";
import { Container } from "./index.styled";
import { useNavigate } from "react-router-dom";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { debounce } from "lodash";

export const ViewMyProjectsContainer = () => {

  const MY_KEY = `@@view-my-project`;
  const {  handleGetPastProjects } = useUser({
    key: MY_KEY,
  });
  const { address, connect, loading } = useContext(WalletConnectorContext);
  const { uiLoaders, pagination } = useUIState();
  
  

  const [createdProjectList, setCreatedProjectList] = useState<
    ProjectNamespace.Project[]
  >([]);
  const [sacrificedProjectList, setSacrificedProjectList] = useState<
    ProjectNamespace.Project[]
  >([]);
  const [followProjectList, setFollowProjectList] = useState<
    ProjectNamespace.Project[]
  >([]);

  const [createdPageSize, setCreatedPageSize] = useState(10);
  const [sacrificedPageSize, setSacrificedPageSize] = useState(10);
  const [followPageSize, setFollowPageSize] = useState(10);

  const [createdCurrentPage, setCreatedCurrentPage] = useState(1);
  const [sacrificedCurrentPage, setSacrificedCurrentPage] = useState(1);
  const [followCurrentPage, setFollowCurrentPage] = useState(1);

  const [createdFilter, setCreatedFilter] = useState("");
  const [sacrificedFilter, setSacrificedFilter] = useState("");
  const [followFilter, setFollowFilter] = useState("");

  let $debounce: any;

  useEffect(() => {
    if (!address) return;
    handleGetPastProjects(address, "projects", {
      key: "projects",
      params: {
        title: createdFilter,
        page: createdCurrentPage,
        perPage: createdPageSize,
      },
      onFinish: (data: any) => {
        setCreatedProjectList(data);
      },
    });
  }, [address, createdPageSize, createdCurrentPage, createdFilter]);

  const handleCreatedProjectPagination = (page: number, pageSize: number) => {
    setCreatedCurrentPage(page);
    setCreatedPageSize(pageSize);
  };

  useEffect(() => {
    if (!address) return;
    handleGetPastProjects(address, "getDonorProjects", {
      key: "getDonorProjects",
      params: {
        title: sacrificedFilter,
        page: sacrificedCurrentPage,
        perPage: sacrificedPageSize,
      },
      onFinish: (data: any) => {
        setSacrificedProjectList(data);
      },
    });
  }, [address, sacrificedPageSize, sacrificedCurrentPage, sacrificedFilter]);

  const handleSacrificedProjectPagination = (
    page: number,
    pageSize: number
  ) => {
    setSacrificedCurrentPage(page);
    setSacrificedPageSize(pageSize);
  };

  useEffect(() => {
    if (!address) return;
    handleGetPastProjects(address, "following", {
      key: "following",
      params: {
        title: followFilter,
        page: followCurrentPage,
        perPage: followPageSize,
      },
      onFinish: (data: any) => {
        setFollowProjectList(data);
      },
    });
  }, [address, followPageSize, followCurrentPage, followFilter]);

  const handleFollowProjectPagination = (page: number, pageSize: number) => {
    setFollowCurrentPage(page);
    setFollowPageSize(pageSize);
  };


  
  const handleConnect = () => {
    connect?.();
  };
  const handleSearchChange = (data: any, key?: string) => {
    console.log({data, key});
    if ($debounce) {
      // console.count("cancel Debounce");
      $debounce.cancel();
      $debounce = null;
    }
    $debounce = debounce(() => {
      
      if(key == "projects"){
        setCreatedFilter(data);
      }
      if(key == "getDonorProjects"){
        setSacrificedFilter(data);
      }
      if(key == "following"){
        setFollowFilter(data);
      }

    }, 600);
    
    
    $debounce();
    
  };
  
  return (
    <Container>
      <MyProjects
        loadingData={uiLoaders}
        paginationData={pagination}
        projectData={{
          projects: createdProjectList,
          getDonorProjects: sacrificedProjectList,
          following: followProjectList,
        }}
        handleMyProjectPaginationFns={{
          projects: handleCreatedProjectPagination,
          getDonorProjects: handleSacrificedProjectPagination,
          following: handleFollowProjectPagination,
        }}
        address={address}
        loadingConnect={loading}
        handleConnect={handleConnect}
        handleSearchChange={handleSearchChange}
      />
    </Container>
  );
};
