import * as React from "react"

function Token(props: any) {
  return (
    <svg
      width={26}
      height={27}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 23.853l-9.75-5.416V7.603L13 2.187l9.75 5.416v10.834L13 23.853zM9.858 10.041a4.387 4.387 0 011.436-1.002A4.246 4.246 0 0113 8.687c.596 0 1.165.117 1.706.352.542.234 1.02.568 1.436 1.002l3.25-1.815L13 4.678 6.608 8.226l3.25 1.815zm2.059 10.725v-3.548c-.975-.253-1.76-.767-2.357-1.544a4.243 4.243 0 01-.893-2.654c0-.199.009-.384.027-.556a2.87 2.87 0 01.108-.527L5.417 10.04v7.123l6.5 3.602zM13 15.186c.596 0 1.106-.212 1.53-.636.425-.425.637-.935.637-1.53 0-.596-.213-1.106-.637-1.53a2.083 2.083 0 00-1.53-.637c-.596 0-1.106.213-1.53.637a2.083 2.083 0 00-.637 1.53c0 .596.213 1.106.637 1.53.425.425.935.637 1.53.637zm1.083 5.58l6.5-3.602V10.04l-3.385 1.896c.054.18.09.356.108.528.018.172.027.357.027.555 0 .993-.298 1.878-.893 2.654-.596.777-1.382 1.291-2.357 1.544v3.548z"
        fill="#7C7C7C"
      />
    </svg>
  )
}

export default Token
