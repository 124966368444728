import { GlobalToken } from 'antd';
import styled from 'styled-components';

export const MainContainer = styled.main<{ token: GlobalToken }>`
  .__app_main,
  .__app_secondary {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 552px;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 425px) {
      width: 100vw;
    }
  }

  .__app_secondary {
    width: 1328px;
    padding: 0px;
    // padding-top: 56px;
    @media (max-width: 1360px) {
      width: 95vw;
    }
    @media (max-width: 1024px) {
      width: 90vw;
    }
  }
  .error-space {
    justify-content: center;
    padding: 20px;
    width: 100%;
  }

  th {
    background-color: ${({ token }) => token.colorBgContainer}!important;
    white-space: nowrap;
  }
  th,
  td {
    border-bottom: 20px solid ${({ token }) => token.colorBgLayout}!important;
  }
  .ant-pagination-total-text {
    margin-right: auto !important;
  }
  .ant-table-content {
    overflow: auto;
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--landing-background-3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--landing-background);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--landing-background-2);
    }
  }

  
`;
