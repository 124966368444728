import { DeleteFilled , PlusOutlined} from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Typography } from "antd";
import { VolumeBonusWrapper } from "./index.styled";
import { ColumnsType } from "antd/es/table";
const { Text } = Typography;
interface ListDataItem {
  contributionAmount: string;
  percentage: string;
}
interface VolumeBonusComponentProps {
  listData: ListDataItem[];
  loading: boolean;
  handleNewVolumeBonus: (data?: any) => void;
  handleManageVolumeBonus: (data: any, type: string) => void;
}
export const VolumeBonusComponent = (props: VolumeBonusComponentProps) => {
  const { listData, loading, handleNewVolumeBonus, handleManageVolumeBonus } =
    props;
  

  const columns: ColumnsType<ListDataItem> = [
    {
      title: "USD Amount Donated",
      key: "contributionAmount",
      render: (_: any, data) => {
        return <Text className="number-font">{data.contributionAmount}</Text>;
      },
    },
    {
      title: "Bonus",
      key: "percentage",
      render: (_: any, data) => {
        return <Text className="number-font">{data.percentage} %</Text>;
      },
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "network",
      render: (_: any, data, key) => {
        return (
          <>
            <Button
              onClick={() => {
                handleManageVolumeBonus(
                  { ...data, index: key },
                  "set-selected"
                );
                handleNewVolumeBonus();
              }}
              
              type="primary"
              ghost
            >
              Edit
            </Button>
            <Button
              
              onClick={() => {
                handleManageVolumeBonus(
                  { ...data, index: key },
                  "remove-selected"
                );
              }}
              type="primary"
              ghost
              danger
              icon={<DeleteFilled />}
              
            />
          </>
        );
      },
    },
  ];

  return (
    <VolumeBonusWrapper className="pt-10">
      <div className="hint">
        <Text>
          Encourage higher donations by rewarding donors for sacrificing higher
          amounts
        </Text>
      </div>
      <Space className="top-bar mb-10" align="center">
        <Button
          loading={loading}
          onClick={() => {
            handleManageVolumeBonus(null, "set-selected");
            handleNewVolumeBonus();
          }}
          type="primary"
          className="new-group-btn"
          ghost
          icon={<PlusOutlined/>} 
        >
          New Volume Bonus
        </Button>
      </Space>
      <Table className="w-full" dataSource={listData} columns={columns}  loading={loading} pagination={false}/>
      
    </VolumeBonusWrapper>
  );
};
