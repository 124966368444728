import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  GlobalOutlined,
  LikeOutlined,
  MoreOutlined,
  ShareAltOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";

import { isMobile } from "react-device-detect";
import { Avatar, Button, Popconfirm, Spin, Tag, theme, Typography } from "antd";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import {
  NETWORKS_TYPE,
  PSAC_TOKEN_ADDRESS,
  SAC_ADMIN_ADDRESS,
  SOCIALS,
  moneyFormat,
  sacToken,
} from "../../../_shared";

import { toEther } from "../../../web3/libs/utilities";
import { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { WalletConnectorContext } from "../../../contexts";
import { sacAdminAbi } from "../../../_shared/ABIcode/sac-admin";
const { Text } = Typography;
const { useToken } = theme;
interface BannerSectionProps {
  project?: ProjectNamespace.Project;
  uiLoaders: Record<any, boolean>;
  handleShareButton: () => void;
  handleFollow: (action: string | undefined) => void;
  handleVote: (votingCost: string) => void;
  projectUtils?: ProjectNamespace.UtilsData;
  currentNetwork?: NETWORKS_TYPE;
}
export const BannerSection = (props: BannerSectionProps) => {
  const { token } = useToken();
  //   const [votingCost, setVotingCost] = useState("");
  const { provider, address, switchNetwork } = useContext(
    WalletConnectorContext
  );
  const [voteLoading, setVoteLoading] = useState(false);
  const {
    project,
    uiLoaders,
    projectUtils,
    currentNetwork,
    handleShareButton,
    handleFollow,
    handleVote,
  } = props;
  const socials = project?.socials ?? [];

  useEffect(() => {
    console.log({ currentNetwork, ci: currentNetwork?.chainId == "369" });
  }, [currentNetwork]);

  const renderSocials = socials.map((social, index: number) => {
    const imgUrl = SOCIALS.find((_s) => {
      return _s.key === social.name;
    });
    return (
      <a
        key={index}
        href={social.url}
        style={{ marginLeft: 5, marginRight: 5 }}
        target="_blank"
        rel="noreferrer"
      >
        <Avatar size="small" src={imgUrl?.logo} icon={<GlobalOutlined />} />
      </a>
    );
  });

  const handleConfirm = async (project_id: string) => {
    setVoteLoading(true);
    try {
      const chAllow = await checkContractAllowanceEnough();
      console.log({ chAllow });
      if (!chAllow) {
        const upAllow = await updateAllowance();
        console.log({ upAllow });
      }
      const vote = await performVote(project_id);
      console.log({ vote });
    } catch (error) {
      console.log({ hanldeUnlockErr: error });
      setVoteLoading(false);
    }
    setVoteLoading(false);
  };

  const updateAllowance: () => Promise<boolean> = async () => {
    if (!projectUtils?.votingCost) return false;
    console.log({ SAC_ADMIN_ADDRESS, address });

    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    // allowance // call
    try {
      const updateAllowance: any = await psacContract.methods["approve"](
        SAC_ADMIN_ADDRESS,
        projectUtils?.votingCost
      ).send({
        from: address,
      });
      console.log({ updateAllowance });
      return true;
    } catch (error) {
      return false;
    }
  };

  const checkContractAllowanceEnough: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    try {
      const amountInWei: any = await psacContract.methods["allowance"](
        address,
        SAC_ADMIN_ADDRESS
      ).call();
      console.log({ amountInWei });
      const _n = BigInt(`${projectUtils?.votingCost}`);
      return _n < amountInWei;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const performVote: (project_id: string) => Promise<boolean> = async (
    project_id
  ) => {
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );
    try {
      const vote = await contract.methods["vote"](project_id).send({
        from: address,
      });
      console.log({ vote });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  return (
    <>
      <div className="lg:flex w-full flex-col mb-[44px] hidden ">
        <div
          className={`flex justify-between p-[30px] h-[200px] bg-center bg-cover bg-no-repeat`}
          style={{
            backgroundImage: `url('${
              project?.banner ?? project?.spotlightImage
            }')`,
          }}
        >
          <div className="h-full flex relative">
            <Avatar
              className="absolute bottom-[-95px]"
              shape="square"
              size={112}
              style={{
                border: "3px solid #666",
              }}
              src={project?.logo}
            />
          </div>
          <div className="flex flex-col justify-between ">
            <div className="flex gap-2 pt-[36px]">
              <Button
                className=""
                // shape="circle"
                onClick={handleShareButton}
                icon={<ShareAltOutlined />}
              >
                
                Referral Link
              </Button>
              {/* <Button className="h-[46px] w-[46px]" shape="circle">
                <MoreOutlined />
              </Button> */}
            </div>
            {!isMobile && (
              <div
                className="flex p-3 rounded justify-center"
                style={{ background: "#00000054" }}
              >
                {renderSocials}
              </div>
            )}
          </div>
        </div>
        <div
          className="flex justify-between px-[50px] py-[25px] !border-t-0"
          style={{
            backgroundColor: token.colorBgContainer,
            border: "1px solid rgba(255, 255, 255, 0.05)",
          }}
        >
          <div className="flex items-center self-end flex-wrap lg:mt-[30px] xl:mt-0">
            {!Boolean(project?.id) && <Spin size="small" />}
            <Text className="text-[48px] font-semibold mr-[60px] gap-[22px] whitespace-nowrap !leading-[0px]">
              {project?.title}{" "}
              {!Boolean(project?.isKYCed) && (
                <img src="/verified.svg" className="h-10 mr-2" alt="...." />
              )}
            </Text>
            {/*
            <Tag
              color={Boolean(project?.isKYCed) ? "#1C9400" : token.colorError}
              className="text-[16px] justify-center rounded-[6px] items-center flex-shrink-0 flex w-[69px] h-[33px] p-[10px 12px 9px 11px]"
            >
              <Text>KYC</Text>
              {Boolean(project?.isKYCed) ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )}
            </Tag>} */}
            {/* <Tag color={token.colorError} className="text-[16px] p-3">
            <Text>Audit</Text>
            <CheckCircleOutlined />
          </Tag> */}
            <Tag
              color="#1C9400"
              className="justify-center rounded-[6px] items-center flex-shrink-0 flex min-w-[69px] h-[32px] p-[10px 12px 9px 11px]"
            >
              <Text  className="text-[10px]">{project?.category}</Text>
              <CheckCircleOutlined />
            </Tag>
            <Tag
              color="lime"
              className="justify-center rounded-[6px] items-center flex-shrink-0 flex min-w-[69px] h-[32px] p-[10px 12px 9px 11px]"
            >
              <Text  className="text-[10px]">{project?.subCategory}</Text>
              <CheckCircleOutlined />
            </Tag>
          </div>
          <div className="flex flex-col gap-[35px]">
            <div className="flex gap-2">
              <GlobalOutlined style={{ color: token.colorText }} />
              <Text className="text-[18px]">
                <a
                  className="underline"
                  target="_blank"
                  style={{ color: token.colorText }}
                  href={project?.website}
                  rel="noreferrer"
                >
                  <u>{project?.website}</u>
                </a>
              </Text>
            </div>
            <div className="flex gap-2">
              <div
                style={{ border: "1px solid #7C7C7C" }}
                className="rounded-[6px] h-[39px] flex gap-3 pl-[8px] items-center justify-between"
              >
                <Text disabled className={"number-font  font-semibold text-[12px] whitespace-nowrap	"}>
                  {project?.followersCount} Followers
                </Text>

                <Tag
                  color={project?.isFollowing ? "lime" : token.colorSuccess}
                  className="text-[12px] whitespace-nowrap p-[5px 10px] cursor-pointer w-[38px] h-[28px] flex items-center justify-center flex-shrink-0 rounded-[6px]"
                  onClick={() => {
                    handleFollow(project?.isFollowing ? "unfollow" : "follow");
                  }}
                >
                  {Boolean(uiLoaders["loadingFollow"]) ? (
                    <Spin size="small" />
                  ) : !project?.isFollowing ? (
                    <PlusSquareOutlined />
                  ) : (
                    <MinusSquareOutlined />
                  )}
                </Tag>
              </div>
              <div
                style={{ border: "1px solid #7C7C7C" }}
                className="rounded-[6px] h-[39px] flex gap-3 pl-[8px] items-center justify-between "
              >
                <Text disabled className={"number-font text-[12px]  font-semibold"}>
                  {projectUtils?.votes} Votes
                </Text>

                <Popconfirm
                  disabled={projectUtils?.userVoted}
                  title={`Voting would cost ${moneyFormat(
                    toEther(`${projectUtils?.votingCost}`, 2),
                    2
                  )} PSAC `}
                  description='Click "Yes" to conitue'
                  onConfirm={async () => {
                    // if (!votingCost) return false;
                    if (voteLoading || !project) return false;
                    console.log(
                      "currentNetwork?.chainId",
                      currentNetwork?.chainId
                    );
                    if (currentNetwork?.chainId != "369") {
                      switchNetwork?.(369).then(async (vv) => {
                        await handleConfirm(`${project?.id}`);
                        handleVote("");
                      }).catch(e=>{
                        console.log({cccc:e})
                      });
                    } else {
                      await handleConfirm(`${project?.id}`);
                      handleVote("");
                    }
                  }}
                  // onCancel={cancel}
                  okText="Yes"
                  // cancelText="No"
                >
                  <Tag
                    color={
                      projectUtils?.userVoted
                        ? token.colorTextDisabled
                        : token.colorSuccess
                    }
                    className="text-[16px] cursor-pointer p-[5px 20px] w-[38px] h-[28px] flex items-center justify-center flex-shrink-0 rounded-[6px]"
                  >
                    {voteLoading ? <Spin size="small" /> : <LikeOutlined />}
                  </Tag>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col mb-[44px] lg:hidden ">
        <div
          className="flex justify-between p-[20px] h-[200px] bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url('${
              project?.banner ?? project?.spotlightImage
            }')`,
          }}
        >
          <div className="h-full flex relative">
            <Avatar
              className="absolute bottom-[-50px]"
              shape="square"
              size={74}
              src={project?.logo}
            />
          </div>
          <div className="flex flex-col justify-between items-end">
            <div className="flex gap-2">
              <Button
                className="h-[46px] w-[46px]"
                shape="circle"
                onClick={handleShareButton}
                icon={<ShareAltOutlined />}
              >
                Referral Link
              </Button>
              {/* <Button className="h-[46px] w-[46px]" shape="circle">
                <MoreOutlined />
              </Button> */}
            </div>
            <div
              className="flex p-3 rounded"
              style={{ background: "#00000054" }}
            >
              {renderSocials}
            </div>
          </div>
        </div>
        <div
          className="flex justify-between p-[20px]  flex-col"
          style={{ backgroundColor: token.colorBgContainer }}
        >
          <div className="flex items-center self-end">
            <Tag
              color={
                Boolean(project?.isKYCed)
                  ? token.colorSuccess
                  : token.colorError
              }
              className="text-[16px] p-3"
            >
              <Text>KYC</Text>
              {Boolean(project?.isKYCed) ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )}
            </Tag>
          </div>
          <div className="flex items-center self-start mb-3">
            {!Boolean(project?.id) && <Spin size="small" />}
            <Text className="text-2xl font-semibold mr-[60px] gap-[22px]">
              {project?.title}{" "}
              {Boolean(project?.isKYCed) && (
                <img src="/verified.svg" alt="...." />
              )}
            </Text>
          </div>
          <div className="flex justify-start">{renderSocials}</div>
        </div>
      </div>
    </>
  );
};
