import { GlobalToken } from "antd";
import styled from "styled-components";

export const Container = styled.footer<{ token: GlobalToken }>`
  position: relative;
  /* width: 100%; */
  /* padding: 230px 120px 64px 120px; */
  /* padding: 54px; */
  background-color: ${({ token }) => token.colorBgContainer};
  font-size: 16px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .create-btn {
    span {
      /* background: linear-gradient(to right, #eee, #333); */
      background: ${({ token }) => {
        return `linear-gradient(to right, ${token.colorPrimary}, ${token.colorText}, ${token.colorPrimary}, ${token.colorText}, ${token.colorPrimary})`;
      }};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .__logo-text {
    font-family: "Urbanist", sans-serif;
    font-weight: 600 !important;
    font-size: 20px !important;
  }
  .main-container {
    width: 100%;
    justify-content: space-between;
  }

  .logo-container {
    .ant-typography {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
  }

  a {
    font-family: ${({ token }) => token.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: ${({ token }) => token.colorText};
  }

  .ant-input {
    width: 400px;
  }
  .sublist {
    /* border-top: 1px solid ${({ token }) =>
      `${token.colorBorder}!important`}; */
    a {
      font-size: 14px;
      color: ${({ token }) => `${token.colorTextSecondary}!important`};
    }
  }

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /*  */
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
    .ant-input {
      width: 100%;
    }
    .main-container,
    .link-container {
      align-items: flex-start !important;
      flex-direction: column;
      gap: 32px !important;
    }
    .main-container {
      gap: 32px !important;
    }
    .link-container {
      gap: 24px !important;
    }
  }
`;
