import {
  
  Typography,
  theme,
} from "antd";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { useMemo } from "react";
const { Text } = Typography;
const { useToken } = theme;

interface WatchVideoSectionProps {
  project?: ProjectNamespace.Project;
}
export const WatchVideoSection = (props: WatchVideoSectionProps) => {
  const { token } = useToken();
  const { project } = props;

  const embededUrl = useMemo(() => {
    if (!project?.introVideo) return "";
    const splitted = project.introVideo.split("youtube.com/watch?v=");
    const splittedShort = project.introVideo.split("youtube.com/shorts/");
    if (splittedShort.length > splitted.length) {
      return (
        "https://www.youtube.com/embed/" +
        splittedShort[splittedShort.length - 1]
      );
    }
    return "https://www.youtube.com/embed/" + splitted[splitted.length - 1];
  }, [project]);
  if(!project?.introVideo){
    return <></>
  }
  return (
    <div
      className="flex w-full flex-col relative sac-schedule "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout1 */}
      <div className="flex  mt-[35px] mb-[20px] mx-[40px]  justify-center">
        <Text className="text-xl font-semibold">
          Watch our Introductory Video
        </Text>
      </div>
      <div className="flex  mb-[20px] mx-[50px] justify-center gap-5">
        <Text className="" style={{ color: token.colorTextDisabled }}>
          To get started
        </Text>
      </div>

      {/* layout 2 */}
      <div className="flex mb-[20px] mx-[65px] justify-between border-red-600 border-1 border-solid">
        <iframe
          width="560"
          height="315"
          // className="w-full h-auto"
          src={embededUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {/* layout 3 */}

      {/* <div className="flex  mb-[20px] mx-[50px] justify-center gap-5">
        <Text className="">
          <b>{project?.title}</b> - {project?.description}
        </Text>
      </div> */}
    </div>
  );
};
