import { Space } from "antd";
import styled from "styled-components";

export const StepsWrapper = styled(Space)`
    
   circle{
    transition: 1s;;
    &.circle{
        fill: #363665;
    }
    &.active-circle{
        fill: #16A34A;
    }
   }
    
`;
