import {
  CheckCircleFilled,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Progress,
  Space,
  Tag,
  Timeline,
  Typography,
  theme,
} from "antd";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
ChartJS.register(ArcElement, Tooltip, Legend);

const { Text } = Typography;
const { useToken } = theme;

const options: any = {
  plugins: {
    legend: {
      position: "right",

      labels: {
        padding: 30,
        usePointStyle: true,
        pointStyle: "rect",
        font: {
          size: 18,
          lineHeight: 28,
          weight: 400,
        },
      },
    } as any,
  } as any,
};

interface DistributionSectionProps {
  project?: ProjectNamespace.Project;
}
export const DistributionSection = (props: DistributionSectionProps) => {
  const { token } = useToken();
  const { project } = props;
  const vestingSchedule = project?.vestingSchedule ?? [];
  const data: ChartData<"pie", number[], unknown> = {
    labels: vestingSchedule.map(
      (schedule) =>
        ` ${schedule.percentage}% ${new Date(
          schedule.timestamp * 1000
        ).toDateString()}`
    ),
    datasets: [
      {
        label: "# of Votes",
        data: vestingSchedule.map((schedule) => schedule.percentage),

        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      className="flex flex-col relative perks px-[40px] py-[33px] "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout 1 */}
      <div className="flex mb-[30px] justify-start">
        <Text className="text-lg">Distribution</Text>
      </div>

      {/* layout 2 */}
      <div className="flex justify-start flex-wrap mb-[24px] gap-[32px] lg:pr-[300px] ">
        <Pie
          className="desktop-chart"
          data={data}
          options={options}
          plugins={[
            {
              id: "legendDistance",
              // beforeInit(chart: any) {
              //   // Get reference to the original fit function
              //   const originalFit = chart.legend.fit;
              //   // Override the fit function
              //   chart.legend.fit = function fit() {
              //     // Call original function and bind scope in order to use `this` correctly inside it
              //     originalFit.bind(chart.legend)();
              //     // Specify what you want to change, whether the height or width
              //     this.width += 200;
              //   };
              // },
            },
          ]}
        />
      </div>
    </div>
  );
};
