import { GlobalToken } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ token: GlobalToken }>`
  /* background-color: var(--landing-background); */
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;

  margin-bottom: 140px;
  .mobile .ant-tabs-nav {
    &::before {
      border-bottom-width: 0;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${({ token }) => token.colorTextHeading};
    }
  }

  .desktop .ant-tabs-nav {
    display: block;
    .ant-tabs-nav-list {
      background-color: ${({ token }) => token.colorBgContainerDisabled};
    }

    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      border-top: 1px solid ${({ token }) => token.colorBorderSecondary};
      font-size: 20px;
      margin-top: 0 !important;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      color: ${({ token }) => token.colorTextDisabled};
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ token }) => token.colorTextHeading};
      }
      &:first-child {
        border-top-width: 0;
      }
      &.ant-tabs-tab-active {
      }
    }
  }
  .ant-tabs-content-holder {
    border-left-width: 0;
  }

  .ant-collapse-header {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /*  */
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
`;
