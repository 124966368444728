import { ShareAltOutlined, UserOutlined, CalendarFilled } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Skeleton, Typography } from "antd";
import { toEther } from "../../../../web3/libs/utilities";
import { computeEndDate, moneyFormat } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { SacrificeDetailContainer } from "./index.styled";

const { Text } = Typography;
interface SacrificeDetailProps {
  project?: ProjectNamespace.Project;
  sacrificePhaseDay?: string
  handleShareButton: (() => void)
  usdDecimals: number
}
export const SacrificeDetail = (props: SacrificeDetailProps ) => {
  const {project:_project,sacrificePhaseDay, usdDecimals, handleShareButton} = props;
  const project = _project??{} as ProjectNamespace.Project;
  return (
    
    <SacrificeDetailContainer>
      <Skeleton title={{
            style:{
              display:"inline-block",
            }
          }}
          
        loading={!Boolean(project.id)} active>
          <Row style={{width: '100%'}}>
            <Col span={24} md={{span: 6}} style={{textAlign:'center'}}>
            <Avatar shape="square" size={100} icon={<UserOutlined />} src={project.logo} />
            </Col>
            <Col md={{span: 16}}  >
            <div className="name-container"  style={{display:'block'}} >
              <Text className="name" style={{margin: 0, textAlign:'left'}}>{project.title}</Text>
              {Boolean(project.isKYCed) && <img src="/verified.svg" alt="...." />}
         
              </div>
              <div >
                <CalendarFilled style={{color: '#ffffff2f'}} /> <Text className="date">{(new Date(project.startDayTime * 1000)).toDateString()
                            } - {(new Date(computeEndDate(project.startDayTime, project.phases))).toDateString()
                            }</Text>
                            
                </div>
                <Row  className="total-value" justify="start">
                  <Col>
                    <Text className="date"><b>Total Raised Value:</b></Text>
                  </Col>
                  <Col>
                    <Text className="day" style={{marginLeft: 20}}><b>${moneyFormat(toEther(String(project.totalSacrificedValue??0), 2), 2)}</b></Text>
                  </Col>
                </Row>
            </Col>
            <Col span={24} sm={{span: 2}}> <Button className="share-button" size="large" onClick={handleShareButton} type="text"><ShareAltOutlined /></Button></Col>
          </Row>
          <Row>
            <Col> {(sacrificePhaseDay && sacrificePhaseDay!=='0' ) && <Typography.Title level={4} >Day {sacrificePhaseDay}</Typography.Title>}</Col>
          </Row>
      
       
       
       
      </Skeleton>
    </SacrificeDetailContainer>
    
  );
};
