import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_PROJECT = createActionType("GET_PROJECT", "PROJECT");

export const getProject = (id: string, options?: ActionOption) => ({
  type: GET_PROJECT.START,
  meta: {
    ...options,
    id,
  },
});
