
import {  Col,  Row, Space, Typography } from "antd";
import Countdown from "react-countdown";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { CoverContainer } from "./index.styled";
const { Text } = Typography;

const Completionist = () => <span>You are good to go!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }:any) => {
  const showS = (num:number)=>num>1?'s':'';
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    return <Space  size={24} align="center">
              {/* <Space> */}
                  <Space direction="vertical" align="center">
                    <Text className="title-text _count_text">{String(days).padStart(2, '0')}</Text>
                    <Text className="__upper">day{showS(days)}</Text>
                  </Space>
                  
                  <Space direction="vertical" align="center">
                    <Text className="title-text _count_text">{String(hours).padStart(2, '0')}</Text>
                    <Text className="__upper">hour{showS(hours)}</Text>
                  </Space>
                  
                  <Space direction="vertical" align="center">
                    <Text className="title-text _count_text">{String(minutes).padStart(2, '0')}</Text>
                    <Text className="__upper">minute{showS(minutes)}</Text>
                  </Space>
                  
                  <Space direction="vertical" align="center">
                    <Text className="title-text _count_text">{String(seconds).padStart(2, '0')}</Text>
                    <Text className="__upper">second{showS(seconds)}</Text>
                  </Space>
              {/* </Space> */}
        </Space>;
  }
};



interface CountDownProps {
  project?: ProjectNamespace.Project;
  onComplete: (() => void)
}
export const CountDown = (props: CountDownProps) => {
    const {project:_project, onComplete} = props;
    

    return (
          <CoverContainer id="details">
              
              <Space size={24} style={{justifyContent: 'center'}}>
                <Countdown
                  date={((_project?.startDayTime??1) * 1000)}
                  renderer={renderer}
                  onComplete={onComplete}
                />
              </Space>
              
              
                          
          </CoverContainer>
    );
};