import styled from "styled-components";

export const Container = styled.div`
  /* background-color: var(--landing-background); */
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;
  gap: 24px;

  .muted-title {
    color: var(--disabled-color) !important;
  }

  .graph-header {
    margin-top: 120px;
    width: 100%;
  }

  .logo-container {
    display: flex;
    gap: 16px;
    align-items: center;
    .ant-typography {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }
  }

  h2.ant-typography {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .m-point-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    .ant-typography {
      color: var(--disabled-color) !important;
    }
  }
  .chart-container {
    width: 100%;
    margin-top: 65px;
    margin-bottom: 65px;
    .chart-col {
      /* margin-left: auto; */
      margin-right: auto;
    }
  }
  .mobile-chart {
    display: none !important;
  }
  .ant-progress-inner {
    background-color: var(--landing-background-2) !important;
  }
  .box-message {
    width: 80%;
    align-self: center;
    padding: 24px;
    background-color: #92400e;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 100px;
    .ant-typography {
      color: #fff !important;
    }
  }
  .ant-tabs {
    width: 100% !important;
  }
  .available-tab {
    margin-top: 64px !important;
    margin-bottom: 122px !important;
    .ant-tabs-tab-btn {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center;
      padding: 4px 32px !important;
      gap: 8px !important;
      width: 221px !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 26px !important;
    }

    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        justify-content: start !important;
      }
      .ant-tabs-nav-list {
        background-color: var(--gray-sec-500);
      }

      ::before {
        border: 0 !important;
      }
    }

    .ant-tabs-tab-active {
      background: linear-gradient(
        102.33deg,
        #11998e -6.44%,
        var(--process-tail-color) 116.66%
      ) !important;
      .ant-tabs-tab-btn {
        color: var(--landing-background-inverse) !important;
      }
    }

    .ant-tabs-nav-list {
      background-color: var(--landing-background-2);
      overflow-x: auto;
      width: fit-content;

      ::-webkit-scrollbar {
        height: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: var(--landing-background-3);
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: var(--landing-background);
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: var(--landing-background-2);
      }
    }
  }

  .faqs-title-container {
    margin-top: 120px;
    width: 100%;
    h2.ant-typography {
      text-align: center;
    }
  }
  .faqs-tab {
    margin-top: 50px !important;
    .ant-tabs-tabpane {
      margin-top: 50px !important;
      margin-bottom: 315px !important;
    }
    .ant-tabs-tab-btn {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center;
      padding: 8px 16px !important;
      gap: 8px !important;
      width: 112px !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 26px !important;
      width: fit-content !important;
    }
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        height: 42px !important;
        background-color: transparent;
        color: var(--disabled-color) !important;
        justify-content: center;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;

        ::-webkit-scrollbar {
          height: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: var(--landing-background-3);
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: var(--landing-background);
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: var(--landing-background-2);
        }
      }

      .ant-tabs-tab-active {
        background-color: var(--landing-background-2);
        .ant-tabs-tab-btn {
          color: var(--text-color) !important;
        }
      }

      ::before {
        border: 0 !important;
      }
    }
  }

  .ant-collapse,
  .ant-collapse-content {
    background-color: var(--landing-background-3) !important;
    color: var(--text-color) !important;
  }

  .ant-collapse-header {
    padding: 8px 16px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    height: auto !important;
    align-items: center !important;
    color: var(--text-color) !important;
  }
  .mobile-available-collapse {
    display: none;
    margin-bottom: 24px !important;
    .ant-collapse-header {
      height: fit-content !important;
    }
  }

  .header-row {
    color: var(--disabled-color) !important;
    background-color: transparent !important;
    .ant-col {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .list-row-header {
    width: 100%;
    margin-top: 165px;
  }
  .list-row {
    margin-top: 24px;
    gap: 0px;
    background-color: var(--landing-background-3);
    padding: 24px;
    .ant-col {
      padding-left: 20px;
      flex-grow: 1;
    }
    .col-one {
      .ant-typography {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .col-two {
      .ant-typography {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /*  */
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
    .graph-header {
      margin-top: 80px;
      h2.ant-typography {
        font-size: 32px !important;
      }
      .ant-typography {
        font-size: 14px;
      }
    }
    .box-message {
      width: 100%;
      margin-bottom: 50px;
    }
    .list-row-header {
      margin-top: 82px;
      h2 {
        font-size: 32px !important;
      }
    }
    .available-tab {
      margin-top: 24px !important;
      margin-bottom: 80px !important;
      .header-row {
        display: none;
      }
    }
    .mobile-available-collapse {
      display: block;
    }
    .desktop-list-row {
      display: none !important;
    }
    .faqs-title-container {
      margin-top: 80px;
      h2.ant-typography {
        font-size: 32px !important;
      }
    }
    .faqs-tab {
      margin-top: 12px !important;
      .ant-tabs-tabpane {
        margin-top: 12px !important;
      }
    }
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
    .graph-header {
      margin-top: 80px;
      h2.ant-typography {
        font-size: 32px !important;
      }
      .ant-typography {
        font-size: 14px;
      }
    }
    .mobile-chart {
      display: block !important;
    }
    .desktop-chart {
      display: none !important;
    }
    .box-message {
      width: 100%;
      margin-bottom: 50px;
    }
    .list-row-header {
      margin-top: 82px;
      h2 {
        font-size: 32px !important;
      }
    }
    .available-tab {
      margin-top: 24px !important;
      margin-bottom: 80px !important;
      .header-row {
        display: none;
      }
    }
    .mobile-available-collapse {
      display: block;
    }
    .desktop-list-row {
      display: none !important;
    }
    .faqs-title-container {
      margin-top: 80px;
      h2.ant-typography {
        font-size: 32px !important;
      }
    }
    .faqs-tab {
      margin-top: 12px !important;
      .ant-tabs-tabpane {
        margin-top: 12px !important;
      }
    }
  }
`;
