import styled from "styled-components";

export const ViewProjectWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px !important;
    gap:8px;

    .muted{
        color: var(--disabled-color)!important;
    } 
    .header-row, .profile-row, .title-row, .detail-card, .ant-tabs{
        width: 100%;
    }

    .detail-card{
        gap: 32px;
        .ant-typography{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .header-row{
        .ant-typography{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .profile-row{
        .ant-space-item:nth-child(2){
            flex-grow: 1;
        }
        .title-row{

            .date{
                color: var(--disabled-color)!important;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                @media (max-width: 425px) {
                    line-height: 12px;
                    font-size: 14px;
                }
            }

            .name{
                font-weight: 500;
                font-size: 32px;
                line-height: 40px;
                @media (max-width: 425px) {
                    line-height: 20px;
                    font-size: 16px;
                }
            }
        }
        .extra-row{
            .ant-btn span{
                font-size: 20px;
            }
        }
    }

    .ant-tabs{
        .ant-tabs-tab {
            .ant-tabs-tab-btn{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--disabled-color);
            }
            
            &-active .ant-tabs-tab-btn{
                color: var(--text-color)!important;
            }
        }
        .ant-tabs-ink-bar{
            background: var(--success-color);
        }
        .anticon.anticon-right-circle{
            
            svg{
                fill: var(--success-color);
                background: #fff;
                border-radius: 200px;
            }
        }
    }

    

   
    
`;
