import {
  CheckCircleOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Space,
  Tag,
  Timeline,
  TimelineItemProps,
  Typography,
  theme,
} from "antd";
import { ShareAltOutlined, MoreOutlined } from "@ant-design/icons";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { computeEndDate, moneyFormat } from "../../../_shared";
const { Text } = Typography;
const { useToken } = theme;
const items: MenuProps["items"] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

interface SacScheduleSectionProps {
  project?: ProjectNamespace.Project;
  sacrificePhaseDay?: string;
}
export const SacScheduleSection = (props: SacScheduleSectionProps) => {
  const { token } = useToken();
  const { project, sacrificePhaseDay } = props;
  const phases = project?.phases ?? [];
  let lastDay = 0;

  const renderPhases: TimelineItemProps[] = phases.map(
    (phase, index: number) => {
      const startDay = lastDay + 1;
      const endDay = lastDay + phase.duration;
      const inRange =
        Number(sacrificePhaseDay) >= startDay &&
        Number(sacrificePhaseDay) <= endDay;
      lastDay = endDay;
      return {
        children: (
          <div className="flex gap-2 items-center justify-between">
            <Text disabled={!inRange} className="">
              Days {startDay} - {endDay}
            </Text>
            <Text disabled={!inRange} className="">
              {moneyFormat(phase.pointAmount)} points/USD
            </Text>
          </div>
        ),
        color: inRange ? token.colorPrimary : token.colorTextDisabled,
      };
    }
  );
  return (
    <div
      className="flex w-full flex-col relative sac-schedule "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout1 */}
      <div className="flex  mt-[35px] mb-[40px] mx-[40px]  justify-between">
        <Text className="text-lg font-semibold">Sacrifice Schedule</Text>
      </div>

      {/* layout 2 */}
      <div className="flex flex-col mb-[20px] mx-[50px] justify-between gap-5">
        <div className="flex gap-2 items-center justify-between">
          <Text className="" style={{ color: token.colorTextDisabled }}>
            Phases
          </Text>
          <Text className="" style={{ color: token.colorTextDisabled }}>
            Point Rate
          </Text>
        </div>
      </div>

      {/* layout3 */}
      <div className="flex flex-col mb-[0px] mx-[30px] justify-between gap-5">
        <Timeline items={renderPhases} />
      </div>

      {/* layout 4 */}
      <div className="flex flex-col mb-[20px] mx-[30px] justify-between gap-5">
        <Text className="text-sm" style={{ color: token.colorTextDisabled }}>
          Sacrifice Begins:{" "}
          {new Date((project?.startDayTime ?? 0) * 1000).toDateString()}
        </Text>
        <Text className="text-sm" style={{ color: token.colorTextDisabled }}>
          Sacrifice Ends:{" "}
          {new Date(
            computeEndDate(project?.startDayTime ?? 1, project?.phases ?? [])
          ).toDateString()}
        </Text>
      </div>
    </div>
  );
};
