import { ViewProjectContainer } from "../../../../container"




const ViewSacrificePage = () => {
  
    
  return <ViewProjectContainer/>
}

export default ViewSacrificePage
