import { Space, Typography } from "antd";
import { ProjectNamespace } from "../../../../../_shared/namespaces/project";
import { ModalWrapper } from "../index.styled";
import { SpaceWrapper } from "./index.styled";
const {Text} = Typography

interface ClaimPercentageModalProps{
    visible: boolean
    onClose?: () => void
    project: ProjectNamespace.Project;
}
export const ClaimPercentageModal = (props: ClaimPercentageModalProps) => {
    const {visible, onClose, project} = props;
    
    return (
          <ModalWrapper  
                title="Vesting Schedule" 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
            <SpaceWrapper direction="vertical" size={24}>
                <Space className="header-row">
                    <Text>Day</Text>
                    <Text>Percentage Claimable</Text>
                </Space>
                {(project.vestingSchedule??[]).map((schedule, key)=>{
                    return <Space key={key} className="list-item-row">
                                <Text>{(new Date(schedule.timestamp * 1000)).toDateString()}</Text>
                                <Text>{schedule.percentage}%</Text>
                            </Space>
                })}
            </SpaceWrapper>
            
          </ModalWrapper>
    );
};