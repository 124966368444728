import { Action } from "../../types";
import {
  CREATE_USER,
  GET_ALL_USER,
  GET_USER,
  GET_USER_PAST_PROJECT,
  UPDATE_USER,
} from "../../actions";
import { arrayToById } from "../../../_shared/utils";
import { UserNamespace } from "../../../_shared/namespaces/user";
import { ProjectNamespace } from "../../../_shared/namespaces/project";

export interface UserReducerState {
  allUsers: Record<string, UserNamespace.User[]>;
  usersById: Record<string, Record<string, UserNamespace.User>>;
  user: Record<string, UserNamespace.User>;
  user_past_project: Record<string, ProjectNamespace.Project[]>;
}

export const UserDefaultState: UserReducerState = {
  allUsers: {},
  usersById: {},
  user: {},
  user_past_project: {},
};

const UserReducer = (state = UserDefaultState, action: Action) => {
  switch (action.type) {
    case CREATE_USER.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          [action.key]: action.payload,
        },
      };

    case GET_USER.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          [action.key]: action.payload,
        },
      };

    case GET_USER_PAST_PROJECT.SUCCESS:
      return {
        ...state,
        user_past_project: {
          ...state.user_past_project,
          [action.key]: action.payload ?? [],
        },
      };

    case GET_ALL_USER.SUCCESS: {
      const byId = arrayToById(action?.payload ?? []);
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.key]: byId,
        },
        allUsers: {
          ...state.allUsers,
          [action.key]: action.payload ?? [],
        },
      };
    }

    case UPDATE_USER.SUCCESS: {
      const allUsers = state.allUsers[action.key] ?? [];

      const index = allUsers.findIndex((o) => o?._id === action.payload?._id);

      let currentArist = {};

      if (index !== -1) {
        currentArist = Object.assign({}, allUsers[index], action.payload);
        allUsers[index] = Object.assign({}, allUsers[index], action.payload);
      } else {
        currentArist = Object.assign({}, action.payload);
        allUsers.push(action.payload);
      }

      const byId = arrayToById(allUsers ?? []);

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.key]: byId,
        },
        allUsers: {
          ...state.allUsers,
          [action.key]: allUsers ?? [],
        },
        user: {
          ...state.user,
          [action.key]: currentArist,
        },
      };
    }

    default:
      return state;
  }
};

export default UserReducer;
