import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const DELETE_USER = createActionType("DELETE_USER", "USER");

export const deleteUser = (id: string, options?: ActionOption) => ({
  type: DELETE_USER.START,
  meta: {
    ...options,
    id,
  },
});
