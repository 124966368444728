export const SacContractAbiV21: any = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "acceptedTokens",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "acceptedTokensList",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "_users",
        "type": "address[]"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "addAdminBonus",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "points",
        "type": "uint256"
      }
    ],
    "name": "addTaskBonus",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "adminBonuses",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_aDay",
        "type": "uint256"
      }
    ],
    "name": "calculatePointBNB",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_aDay",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      }
    ],
    "name": "calculatePointToken",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "decimals",
        "type": "uint256"
      }
    ],
    "name": "calculatePointUSD",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "claimData",
    "outputs": [
      {
        "internalType": "address",
        "name": "projectAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "claimer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "claimId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "percentage",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "sacTotalPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "userPoints",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "claimPlan",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "percentage",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAcceptedTokenList",
    "outputs": [
      {
        "internalType": "address[]",
        "name": "",
        "type": "address[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAcceptedTokens",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "totalContributed",
            "type": "uint256"
          }
        ],
        "internalType": "struct TokenInfo[]",
        "name": "tokens",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getContributionHistory",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amountContributed",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pointCollected",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "timeStamp",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "referralAddress",
            "type": "address"
          }
        ],
        "internalType": "struct SacV2.Contribution[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getPointRate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "day",
        "type": "uint256"
      }
    ],
    "name": "getPointRateForDay",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSacPointData",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "startDay",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "endDay",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "points",
            "type": "uint256"
          }
        ],
        "internalType": "struct SacV2.SacPeriod[]",
        "name": "sacData",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSacrificePhaseDay",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      }
    ],
    "name": "getTransferFee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "fee",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenBalance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalTrns",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "id",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_version",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "_id",
        "type": "string"
      },
      {
        "internalType": "address",
        "name": "projectOwner",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "startDay",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_proxyAddress",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "_acceptedTokens",
        "type": "address[]"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "duration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pointAmount",
            "type": "uint256"
          }
        ],
        "internalType": "struct Phase[]",
        "name": "_phases",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "timestamp",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "percentage",
            "type": "uint256"
          }
        ],
        "internalType": "struct ClaimPlan[]",
        "name": "_claimPlan",
        "type": "tuple[]"
      },
      {
        "internalType": "uint256",
        "name": "_referralPercentX100",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_refereePercentX100",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "_memberOnlyUpline",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "_minSacAmount",
        "type": "uint256"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "memberOnlyUpline",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minimumSacAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "phases",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "duration",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "pointAmount",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "refereePercent",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "referralPercent",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "points",
        "type": "uint256"
      }
    ],
    "name": "removeTaskBonus",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sacEndDate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sacProjectInfo",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "totalContributed",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalVolumeBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalReferralBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalRefereeBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalWhitelistBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalTaskBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalAdminBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalUsers",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalPsacBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalStakeBonus",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sacProxy",
    "outputs": [
      {
        "internalType": "contract ISacProxy",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "sacrificer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "referralAddress",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "whitelistBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "volumeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "referralBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "refereeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "psacBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "stakeBonus",
            "type": "uint256"
          }
        ],
        "internalType": "struct BonusesV2",
        "name": "bonuses",
        "type": "tuple"
      }
    ],
    "name": "sacrificeBNB",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "sacrificer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "referralAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "whitelistBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "volumeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "referralBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "refereeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "psacBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "stakeBonus",
            "type": "uint256"
          }
        ],
        "internalType": "struct BonusesV2",
        "name": "bonuses",
        "type": "tuple"
      }
    ],
    "name": "sacrificeToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "sacrificer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "referralAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "whitelistBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "volumeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "referralBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "refereeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "psacBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "stakeBonus",
            "type": "uint256"
          }
        ],
        "internalType": "struct BonusesV2",
        "name": "bonuses",
        "type": "tuple"
      }
    ],
    "name": "sacrificeTokenWithBonus",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "_tokenAddresses",
        "type": "address[]"
      },
      {
        "internalType": "bool",
        "name": "_enabled",
        "type": "bool"
      }
    ],
    "name": "setAcceptedTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_minimumAmount",
        "type": "uint256"
      }
    ],
    "name": "setMinimumSacAmount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_startingDate",
        "type": "uint256"
      }
    ],
    "name": "setProjectStartDate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_referralPercentX100",
        "type": "uint256"
      }
    ],
    "name": "setReferralPercent",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "startingDate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      }
    ],
    "name": "takeTransferFee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "fee",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenBalance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalTrns",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "totalSacPerToken",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "totalTransfers",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_user",
        "type": "address"
      }
    ],
    "name": "userInfo",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "totalContributed",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalPoints",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "actualPoints",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "volumeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "referralBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "refereeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "whitelistBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "taskBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "lastSacPoints",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "upline",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "psacBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adminBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "stakeBonus",
            "type": "uint256"
          }
        ],
        "internalType": "struct SacV2.User",
        "name": "user",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "token",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "totalContributed",
            "type": "uint256"
          }
        ],
        "internalType": "struct TokenInfo[]",
        "name": "tokenContributions",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "totalContributed",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalPoints",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalVolumeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalReferralBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalRefereeBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalWhitelistBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalTaskBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalAdminBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalUsers",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalPsacBonus",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalStakeBonus",
            "type": "uint256"
          }
        ],
        "internalType": "struct SacV2.ProjectInfo",
        "name": "projectInfo",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "userSacPerToken",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "users",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "totalContributed",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "totalPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "actualPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "volumeBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "referralBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "refereeBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "whitelistBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "taskBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "lastSacPoints",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "upline",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "psacBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "adminBonus",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "stakeBonus",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "version",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      }
    ],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "withdrawn",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
];