import WalletConnectProvider from '@walletconnect/web3-provider'
import WalletLink from 'walletlink'
import { ethers, providers } from 'ethers'
import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import Web3Modal from "web3modal";
import { message, Spin } from 'antd';
import { CONNECTED_ADDRESS, ContractMetaData, SIGNED_ADDRESS_KEY, SIGNED_MESSAGE } from '../../_shared/constants';
import { resolveCurrentNetworkObject, Storage } from '../../_shared/utils';
import {useDispatch} from 'react-redux';
import Web3 from 'web3';
import { getContracts } from '../../redux/actions';
import { SignAddressButton } from '../../components';
interface WalletConnectorContextValues{
    signatureIsDirty: boolean
    loadingSigning: boolean
    loading: boolean
    address?: string
    signAddress: () => void
    connect?: () => Promise<void>
    disconnect?: () => Promise<void>
    switchNetwork?: (chainId:number) => Promise<void>
    provider: any
    web3Provider: any
    chainId?: number|null
    signedAddress?: {
        result?: string
      }
}
export const WalletConnectorContext = createContext<WalletConnectorContextValues>({  } as WalletConnectorContextValues);

const INFURA_ID = `${process.env.REACT_APP_INFURA_ID}`;
let connecting = false;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'Coinbase', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_:any, options:any) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName,
      })
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
      await provider.enable()
      return provider
    },
  },
}

let web3Modal:any
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })
}

type StateType = {
  loading: boolean
  provider?: any
  web3Provider?: any
  address?: string|null
  chainId?: number|null
  balance?: number|string|null
  signedAddress?: {
    result?: string
  }
}

type ActionType =
  | {
      type: 'SET_WEB3_PROVIDER'
      provider?: StateType['provider']
      web3Provider?: StateType['web3Provider']
      address?: StateType['address']
      chainId?: StateType['chainId']
      balance?: StateType['balance']
      loading: StateType['loading']
    }
  | {
      type: 'SET_ADDRESS'
      address?: StateType['address']
    }
| {
    type: 'SET_SIGNED_ADDRESS'
    signedAddress?: StateType['signedAddress']
    }
  | {
      type: 'SET_CHAIN_ID'
      chainId?: StateType['chainId']
    }
  | {
    type: 'SET_LOADING'
    loading: StateType['loading']
  }
  | {
      type: 'RESET_WEB3_PROVIDER'
    }

const initialState: StateType = {
  loading: false,
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
  
}

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      }
    case 'SET_SIGNED_ADDRESS':
      return {
          ...state,
          signedAddress: action.signedAddress,
      }
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      }
    case 'RESET_WEB3_PROVIDER':
      return initialState
    default:
      throw new Error()
  }
}


export const WalletConnectorProvider = (props: any) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch();
  const { provider, signedAddress } = state
  const [loadingSigning, setLoadingSigning] = useState(false);
  const [signatureIsDirty, setSignatureIsDirty] = useState(false);


  const getSignatureAddress = (signedData: string): string | null => {
    try {
      const data: any = JSON.parse(signedData);
      if(data.timestamp < (Math.floor(Date.now()/1000) - 3600)) {
        return null;
      }
      return data.user;
    } catch(e) {
      return null;
    }
  }


  const connect = useCallback(async function () {

    
    dispatch({
      type: 'SET_LOADING',
      loading: true
    })
    // This is the initial `provider` that is returned when
    // using web3Modal to connect. Can be MetaMask or WalletConnect.
    const provider = await web3Modal.connect()

    // We plug the initial `provider` into ethers.js and get back
    // a Web3Provider. This will add on methods from ethers.js and
    // event listeners such as `.on()` will be different.
    const web3Provider = new providers.Web3Provider(provider)

    const signer = web3Provider.getSigner()
    const address = await signer.getAddress()

    const caStorage = new Storage(CONNECTED_ADDRESS);
    caStorage.set({address});

    const network = await web3Provider.getNetwork()
    const timestamp = Math.floor(new Date().getTime()/10000);
    // const msgParams = [
    //     {
    //       type: 'string',      // Any valid solidity type
    //       name: 'message',     // Any string label you want
    //       value: SIGNED_MESSAGE  // The value to sign
    //    },
    //    {   
    //      type: 'uint256',
    //         name: 'timestamp',
    //         value: timestamp
    //     },
    //     { 
    //       type: 'address',
    //       name: 'user',
    //       value: address
    //      }
    // ]
    // console.count('tring to sign');
    const storage = new Storage(SIGNED_ADDRESS_KEY);
    const signedDataUser = getSignatureAddress(storage.get());
    if(!signedAddress || signedDataUser?.toLowerCase() !== address.toLowerCase()) {
        
        if(storage.get()){ 
            dispatch({
                type: 'SET_SIGNED_ADDRESS',
                signedAddress: storage.get()
              })
        }else{
         
            setSignatureIsDirty(true);
            storage.clear();
            
          
          //setSignatureIsDirty(true);
         // console.count('tring to sign')
          // if(!loadingSigning){
          //   setLoadingSigning(true);
          //   provider.sendAsync({
          //     method: 'eth_signTypedData',
          //     params: [msgParams, address],
          //     from: address,
          //   }, function (err:any, result:any) {
              
          //     if (err) {
  
          //         console.error(err)
          //     }
          //     if (result.error) {
          //         message.info(result.error.message)
          //        console.error(result.error.message)
          //     }
          //     console.info(result)
          //     const storage = new Storage(SIGNED_ADDRESS_KEY);
          //     storage.set({...result, address, timestamp, signedMessage:SIGNED_MESSAGE});
          //     setLoadingSigning(false);
          //   })
            
          // }
          
        }
        
    }; 
    const hexBalance = await web3Provider.getBalance(address);
    const balance = ethers.utils.formatEther(hexBalance)
    
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      provider,
      web3Provider,
      address,
      balance,
      chainId: network.chainId,
      loading: false
    })
  }, [])

  const signAddress = ()=>{
    const timestamp = Math.floor(new Date().getTime()/10000);
        const msgParams = [
            {
              type: 'string',      // Any valid solidity type
              name: 'message',     // Any string label you want
              value: SIGNED_MESSAGE  // The value to sign
          },
          {   
            type: 'uint256',
                name: 'timestamp',
                value: timestamp
            },
            { 
              type: 'address',
              name: 'user',
              value: state.address
            }
        ]
        setLoadingSigning(true);
        provider.sendAsync({
          method: 'eth_signTypedData',
          params: [msgParams, state.address],
          from: state.address,
        }, function (err:any, result:any) {
          
          if (err) {

              console.error(err);
              setLoadingSigning(false);
              return;
          }
          if (result.error) {
              message.info(result.error.message)
             console.error(result.error.message)
             setLoadingSigning(false);
             return;
          }
         //  console.info(result)
          const storage = new Storage(SIGNED_ADDRESS_KEY);
          storage.set({...result, address:state.address, timestamp, signedMessage:SIGNED_MESSAGE});
          setLoadingSigning(false);
          setSignatureIsDirty(false)
        })
  }

  const disconnect = useCallback(
    async function () {
      connecting = false;
      await web3Modal.clearCachedProvider();
      if(provider?.chainId) {
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      if(typeof window !== 'undefined'){
        const storage = new Storage(SIGNED_ADDRESS_KEY);
        storage.clear();
       
      }
    }
   console.log('disconnecting...')
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      provider: null,
      web3Provider: null,
      address: null,
      balance: 0,
      chainId: null,
      loading: false
    })
    const caStorage = new Storage(CONNECTED_ADDRESS);
    caStorage.remove();
    },
    [provider]
  )

  useEffect(()=>{
  }, [state])


  const switchNetwork = useCallback(
    async function (chainId: number) {
      
      if(provider ){
          const _network = resolveCurrentNetworkObject(`${chainId}`);
          if(!_network) return;
         
          
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: Web3.utils.toHex(chainId) }], // chainId
              // must be in hexadecimal numbers
          }).catch(async (e: any) => {
            
            if(e.code !== 4001 && !e.message.toLowerCase().includes('rejected')) {
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                chainId: Web3.utils.toHex(_network.chainId),
                chainName: _network.networkName,
                nativeCurrency: {
                    name: _network.networkName,
                    symbol: _network.nativeToken,
                    decimals: 18
                },
                rpcUrls: _network.rpcUrls,
                blockExplorerUrls: _network.blockExplorerUrls
                }]
              }).then(() => {
                switchNetwork(chainId);
              })
              .catch((e:any) => {
                throw e
              }) 
            }else{
              throw e
            }
          })
       
      }
    },
    [provider]
  )

  

  const initContextData = (provider: any) => {
    reduxDispatch(getContracts(provider, ContractMetaData));
  };

  useEffect(() => {
    if (provider) {
        initContextData(provider);
    }
}, [provider]);

  


  // Auto connect to the cached provider
  useEffect(() => {
    console.count('connecting');
    if (web3Modal.cachedProvider && !connecting) {
      connecting = true;
      connect()
    }
  }, [])


  

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts)
        const address = accounts[0];
        
        dispatch({
          type: 'SET_ADDRESS',
          address,
        })
        const storage = new Storage(SIGNED_ADDRESS_KEY);
        storage.clear();
        setSignatureIsDirty(true);
        
        // window.location.reload()
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        // window.location.reload()
        console.log('handleChainChanged', _hexChainId)
        dispatch({
          type: 'SET_CHAIN_ID',
          chainId: parseInt(_hexChainId, 16)
        })
       // connect()
        //disconnect().then(()=>connect()).catch(e=>console.log("--------",e))
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        // eslint-disable-next-line no-console
        connecting = false;
       console.log('Disconnected....')
        
        if(provider?.chainId) {
          if (provider?.disconnect && typeof provider.disconnect === 'function') {
            provider.disconnect().then()
          }
         
        }
      }
     

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnect])

  useEffect(()=> {
  }, [provider])
  const value = {
    ...state,
    disconnect,
    connect,
    switchNetwork,
    signAddress,
    loadingSigning,
    signatureIsDirty
  } as WalletConnectorContextValues;
  

  return (
    <WalletConnectorContext.Provider value={value}>
       
      {(provider||!web3Modal.cachedProvider)?
      <>
        { children}
      </>
      :<div>
      { children}
    </div>}
    </WalletConnectorContext.Provider>
  );
};