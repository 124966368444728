import { Middleware } from "redux";
import { RootState } from "../../../state";
import { serverRequest } from "../../../actions/app/api";
import { APP_URL, PATCH } from "../../../../_shared/constants";
import { UPDATE_USER } from "../../../actions";

export const updateUser: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_USER.START) {
      const { id, payload, onFinish, ...rest } = action.meta;
      dispatch(
        serverRequest({
          method: PATCH,
          url: `${APP_URL.user.update}/${id}`,
          onFinish,
          payload,
          onSuccess: UPDATE_USER.SUCCESS,
          ...rest,
        })
      );
    }
  };
