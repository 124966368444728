
import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    width: 100%;

    .ant-form-item{
        width: 100%;
    }
   
`;
