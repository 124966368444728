import { Action } from "../../types";
import {
  CREATE_PROJECT,
  GET_ALL_PROJECT,
  GET_PROJECT,
  UPDATE_PROJECT,
} from "../../actions";
import { arrayToById } from "../../../_shared/utils";
import { ProjectNamespace } from "../../../_shared/namespaces/project";

export interface ProjectReducerState {
  allProjects: Record<string, ProjectNamespace.Project[]>;
  projectsById: Record<string, Record<string, ProjectNamespace.Project>>;
  project: Record<string, ProjectNamespace.Project>;
}

export const ProjectDefaultState: ProjectReducerState = {
  allProjects: {},
  projectsById: {},
  project: {},
};

const ProjectReducer = (state = ProjectDefaultState, action: Action) => {
  switch (action.type) {
    case CREATE_PROJECT.SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          [action.key]: action.payload,
        },
      };

    case GET_PROJECT.SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          [action.key]: action.payload,
        },
      };

    case GET_ALL_PROJECT.SUCCESS: {
      const byId = arrayToById(action?.payload ?? []);
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.key]: byId,
        },
        allProjects: {
          ...state.allProjects,
          [action.key]: action.payload ?? [],
        },
      };
    }

    case UPDATE_PROJECT.SUCCESS: {
      const allProjects = state.allProjects[action.key] ?? [];

      const index = allProjects.findIndex(
        (o) => o?._id === action.payload?._id
      );

      let currentArist = {};

      if (index !== -1) {
        currentArist = Object.assign({}, allProjects[index], action.payload);
        allProjects[index] = Object.assign(
          {},
          allProjects[index],
          action.payload
        );
      } else {
        currentArist = Object.assign({}, action.payload);
        allProjects.push(action.payload);
      }

      const byId = arrayToById(allProjects ?? []);

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.key]: byId,
        },
        allProjects: {
          ...state.allProjects,
          [action.key]: allProjects ?? [],
        },
        project: {
          ...state.project,
          [action.key]: currentArist,
        },
      };
    }

    default:
      return state;
  }
};

export default ProjectReducer;
