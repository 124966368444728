import { theme, Typography } from "antd";
// import Link from "next/link";
import { Container } from "./index.styled";

const { useToken } = theme;
const { Text } = Typography;

interface WhySectionProps {}

export const WhySection = (props: WhySectionProps) => {
  const {} = props;

  const { token } = useToken();

  return (
    <Container
    id={'psac-token'}
      token={token}
      className={"pt-[100px] !mb-0 lg:!mb-[100px] lg:!pt-[156px] !mt-0"}
    >
      <Text className="text-3xl lg:text-6xl font-semibold whitespace-nowrap">
        Why HODL / Stake PSAC?
      </Text>

      <Text className="lg:max-w-[60%] mt-[20px] text-lg lg:text-xl lg:mt-[50px]">
        The PSAC Token is designed to appreciate in value through our
        platform-driven demand and burn mechanisms.
      </Text>

      <div className="grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 mt-[25px] lg:mt-[130px] gap-x-5 gap-y-5 lg:gap-[37px]">
        {[
          {
            name: "Dapp Utility",
            subTitle: "Projects need PSAC to unlock premium features ",
            icon: "dapp_utility",
          },
          {
            name: "Passive Allocation",
            subTitle:
              "Staking PSAC gives you passive allocation from all sacrifices on the Pulsesac platform",
            icon: "passive_allocation",
          },
          {
            name: "PSAC Whitelist Bonus",
            subTitle:
              "Top PSAC stakers will enjoy special whitelist bonus when participating in sacrifice of partner projects",
            icon: "whitelist_bonus",
          },
          {
            name: "PSAC Burn events",
            subTitle:
              "A portion of PSAC tokens used for payment on the platform is permanently burnt out of circulation",
            icon: "burn_events",
          },
        ].map((item) => {
          return (
            <div className="flex flex-col items-center lg:items-start gap-[6px] lg:gap-[40px] w-full">
              <img
                src={`/logos/${item.icon}.png`}
                alt=""
                className="w-[164px] lg:w-full"
              />
              <div
                className={"flex flex-col items-center lg:items-start gap-y-5"}
              >
                <Text className="text-lg lg:text-4xl  text-center lg:text-start font-medium">
                  {item.name}
                </Text>
                <Text className="text-base text-center lg:text-start  font-normal">
                  {item.subTitle}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
