import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const UPDATE_WHITELIST = createActionType(
  "UPDATE_WHITELIST",
  "WHITELIST"
);

export const updateWhitelist = (
  payload: Record<string, any>,
  id: string,
  options?: ActionOption
) => ({
  type: UPDATE_WHITELIST.START,
  meta: {
    ...options,
    id,
    payload,
  },
});
