import { ViewProjectComponent } from "../../components";
import { Container } from "./index.styled";
import { useGeneric, useProject, useSacProxy, useUIState, useWhitelist } from "../../hooks";
import { message, Modal, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { EMPTY_ADDRESS, NETWORKS_TYPE } from "../../_shared/constants";
import { WalletConnectorContext } from "../../contexts";
import { WhitelistNamespace } from "../../_shared/namespaces/whitelist";
import { resolveCurrentNetworkObject, shorternAddress } from "../../_shared/utils";
import { ExclamationCircleOutlined, DollarCircleFilled } from "@ant-design/icons";
import { toEther } from "../../web3/libs/utilities";
import { AdminNamespace } from "../../_shared/namespaces/admin";
const { confirm } = Modal;
const { Text } = Typography;


export const ViewProjectContainer = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const KEY = `@@view-a-project`;
  const KEY_WHITELIST = `@@view-a-project-whitelist`;


  const { uiLoaders } = useUIState();
  const [currentNetwork, setCurrentNetwork] = useState<NETWORKS_TYPE>();
  const [selectedGroup, setSelectedGroup] = useState<WhitelistNamespace.Whitelist>();
  const [selectedAdminBonus, setSelectedAdminBonus] = useState<AdminNamespace.AdminBonus>();
  const [selectedVolumeBonus, setSelectedVolumeBonus] = useState<{contributionAmount: string; points:string; index?:number}|undefined>(undefined);
  const {address, switchNetwork, chainId, provider} = useContext(WalletConnectorContext);
  const [showTokenScreen, setShowTokenScreen] = useState(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const [switchingNetwork, setSwitchingNetwork] = useState<NETWORKS_TYPE>();
  

  



  const handleBackArrow = ()=>{
    navigate("/projects")
  }
  const { project, handleGet, handleUpdate } = useProject({
    key: KEY,
  });
console.log('PROJECT', project)
  const { dataList: adminBonuses, handleGetAll } = useGeneric<AdminNamespace.AdminBonus>({
    key: KEY,
    urlSuffix: "/donations/adminBonus",
  });

  useEffect(() => {
    
    if(!id)return
    handleGetAll({
      params:{
        ProjectId:id
      },
      onFinish: (d)=>console.log('PRJOECTID3',d)
    })
  }, [id, toggle1]);
  
  useEffect(() => {
    console.log(`adminBonuses`, adminBonuses)
  }, [adminBonuses]);

  const { allWhitelists, 
          handleGetAll: handleGetAllWhitelist, 
          handleCreate: handleCreateWhitelist,
          handleUpdate: handleUpdateWhitelist
        } = useWhitelist({
    key: KEY_WHITELIST
  })
  const {getSacrificeContractAddress, resetStoreData, addBonus, getWithdraw, fees, totalSacUSD,  address: sacAddress,
        deploySacrifice,getSacrificeFee,
        getProjectOwners, getAcceptedTokens,setAcceptedTokens,
        getProjectInfo, cancelAdminBonus,
        projectOwners, acceptedTokens, getUsdDecimals, initialized: proxyInitialized, usdDecimals,
        loadingAddToken, loadingDeploy, loadingAddress, } = useSacProxy({
    key:KEY,
    address,
    provider,
    project
  });


  const loading = uiLoaders[KEY];
  const loadingWhitelist = uiLoaders[KEY_WHITELIST];

  useEffect(() => {
    if(address && project.user && `${address}`.toLowerCase() !== project.user){
      navigate("/projects")
    }
  }, [address, project.user]);

  useEffect(() => {
    console.log('PRJOECTID-->', project.id)
    if(currentNetwork && provider && project && proxyInitialized && project.signature){
     
      resetStoreData({
        onFinish: ()=>{
          // getSacrificeContractAddress( `${project.id}`, project.contractVersion);
           getProjectOwners(`${project.id}`);
          getSacrificeFee();
          getUsdDecimals();
        }
      })
    }
  }, [project.id,currentNetwork, provider, proxyInitialized]);

  useEffect(() => {
    if(id) handleGet(`${id}/signature`, {onFinish: (d)=>console.log('PRJOECTID5', d)});
  }, [id]);

  useEffect(() => {
    if(chainId && provider && proxyInitialized) {
      setCurrentNetwork(resolveCurrentNetworkObject(`${chainId}`));
      // getProjectOwners(`${project.id}`);
      // getSacrificeFee();
      // if(provider && project.id && project.projectAddress) {
      //   getUsdDecimals();
      // }
    }
   
  }, [chainId, proxyInitialized]);
  console.log('DECIMALS', chainId, usdDecimals)
  useEffect(() => {
   
    if(project.id && projectOwners && projectOwners!== EMPTY_ADDRESS && proxyInitialized){
      getAcceptedTokens(project.projectAddress);
      getProjectInfo();
    }
  }, [projectOwners, project, toggle2, proxyInitialized, chainId]);

  useEffect(() => {
   
    if(sacAddress?.length ){
      if(project?.projectAddress)getAcceptedTokens(project.projectAddress); 
      // getProjectInfo();
    }
  }, [sacAddress]);
  
  


  const handleNetworkSelect = (data:any)=>{
    if(project){
      handleUpdate({
        networks: [...(project.networks??[]), data.chainId].filter((value, index, self) =>{
          return self.indexOf(value) === index;
        })
      },`${id}`, {
        onFinish: ()=>{
          handleGet(`${id}`,{
            onFinish:()=>{
              message.success("updated successfully")
            }
          })
          
        }
      });
    }
  }

  const handleSelectAvailableNetwork = async (data:any)=>{
    setSwitchingNetwork(data)
    switchNetwork?.(data.chainId).then(()=>{
      // getProjectOwners(`${project.id}`);
      setToggle2(old=>!old)
      setSwitchingNetwork(undefined)
    }).catch(_=>setSwitchingNetwork(undefined))
    // resetStoreData({
    //   onFinish: ()=>{
    //     switchNetwork?.(data.chainId).then(()=>{
    //       // setCurrentNetwork(data);
    //     })
    //   }
    // })
    
    
  }

  const handleDeploy = (_:any)=>{
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <Text>
        Fees: {!fees?.currentFeeFlat ? '':toEther(fees?.currentFeeFlat??'')}  {currentNetwork?.nativeToken} + {(Number(fees?.currentFeePercentx100??'')/100).toFixed(2)}% per transaction
      </Text>,
      onOk() {
        if(project)deploySacrifice(project, {
          onFinish: ()=>{
           // getProjectOwners(`${project.id}`)
          }
        })
      },
      okText: "Deploy"
    });
    
    
  }


  const handleNewGroupSubmit = useCallback((data:any)=>{

    
    const addresses  = (data.addresses as string).replace(/\s/g,'').split(',')
    if(!project.id) return;
    handleCreateWhitelist({
      ...data,
      ProjectId: project.id,
      addresses
    }, {
      onFinish:()=>{
        message.success('Group Created Successfully');
        handleGetAllWhitelist({
          params:{
            projectId: project.id
          }
        });
      }
    })
    
  },  [project])

  const handleNewTokenSubmit = useCallback((data:any)=>{
    if(!sacAddress || !project.id || !proxyInitialized) return;
    console.log(`handleNewTokenSubmit`, data)
    setAcceptedTokens( (data['token_address']??'').split(','),true, {
      onFinish:()=>{
        if(project?.projectAddress)getAcceptedTokens(project.projectAddress); 
        
      }
    });
    
  },  [sacAddress, proxyInitialized, chainId])

  const handleRemoveTokenSubmit = useCallback((data:any)=>{
    if(!project.id) return;
    // console.log(`handleNewTokenSubmit`, address)
    setSelectedTokenAddress(data.address);
    Modal.confirm({
      title: 'Are you Sure',
      icon: <ExclamationCircleOutlined />,
      content: `Remove Token ${data.symbol===''?currentNetwork?.nativeToken:data.symbol}`,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: ()=>{
        setAcceptedTokens([data.address??''],false, {
          onFinish:()=>{
            if(project?.projectAddress)getAcceptedTokens(project.projectAddress); 
          }
        });
      }
    });
    
    
    
  },  [sacAddress])

  const handleWidthdrawToken = useCallback((data:any)=>{
    if(!project.id) return;
    // console.log(`handleNewTokenSubmit`, address)
    setSelectedTokenAddress(data.token);
    Modal.confirm({
      title: 'Are you Sure',
      icon: <DollarCircleFilled />,
      content: `Withdraw Token ${data.symbol===''?currentNetwork?.nativeToken:data.symbol}`,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: ()=>{
        getWithdraw(`${data.token}`, {
          onFinish:()=>{
            if(project?.projectAddress)getAcceptedTokens(project.projectAddress); 
          }
        });
      }
    });
    
    
    
  },  [sacAddress])

  const handleManageGroupSubmit = useCallback((data:any)=>{
    // console.log(`selectedGroup`, selectedGroup)
    if(!selectedGroup?.id) return;
    
    handleUpdateWhitelist( data,`${selectedGroup?.id}`, {
      onFinish:()=>{
        message.success('Group Updated Successfully');
        handleGetAllWhitelist({
          params:{
            projectId: project.id
          }
        });
      }
    })
    
  },  [project, selectedGroup])

  const initWhitelistComponent = useCallback(()=>{
    if(!project.id) return;
    handleGetAllWhitelist({
      params:{
        projectId: project.id
      }
    });
  },  [project])


  const handleNewVolumeBonus = useCallback((data:any)=>{
    if(!project.id) return;
    
    if(project){
      let newBonus = project.volumeBonus??[];
      if(!selectedVolumeBonus){
        newBonus = [...newBonus, data];
      }
      if(selectedVolumeBonus && selectedVolumeBonus?.index !== undefined){
        newBonus = newBonus.map((old:any, i:number)=>{
          if(selectedVolumeBonus?.index === i)return data;
          return old;
        });
      }
      handleUpdate({
        volumeBonus: newBonus
      },`${id}`, {
        onFinish: ()=>{
          handleGet(`${id}`,{
            onFinish:()=>{
              message.success("updated successfully")
            }
          })
          
        }
      });
    }
    
    
  },  [project, selectedVolumeBonus])



  const handleManageAdminBonus = useCallback((data:any, type:string)=>{
    
    
    if(type === 'create'){
      const addresList  = (data.addresses as string).replace(/\s/g,'').split(',')
      console.log(`handleManageAdminBonus`,addresList)
      addBonus(addresList, data.points, {
        onFinish: ()=>{
          setToggle1(old=>!old)
          // console.log('onFinish', 'addAdminBonus')
        }
      })
    }
    if(type === 'set-selected'){
      setSelectedAdminBonus(data)
    }
    if(type === 'remove-selected'){
      
      Modal.confirm({
        title: 'Are you Sure',
        icon: <ExclamationCircleOutlined />,
        content: `Remove Admin Bonus ${shorternAddress(data?.address??'')}`,
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: ()=>{
          console.log(`(data?.address+'', data?.bonusId+'' )`, data?.address+'', data?.bonusId+'' )
          cancelAdminBonus(data?.address+'', data?.bonusId+'', {
            onFinish:()=>{

              setSelectedAdminBonus(undefined)
              setToggle1(old=>!old)
            },
            onError:()=>{
              setSelectedAdminBonus(undefined)
            }
          } )
          

          
        }
      });
      
    }
    
  },  [project, selectedAdminBonus])


  const handleManageVolumeBonus = useCallback((data:any, type:string)=>{
    console.log(`handleManageVolumeBonus`, data, type)
    if(type === 'set-selected'){
      setSelectedVolumeBonus(data)
    }
    if(type === 'remove-selected'){
      
      Modal.confirm({
        title: 'Are you Sure',
        icon: <ExclamationCircleOutlined />,
        content: `Remove Token Volume Bonus`,
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: ()=>{
          let newBonus = project.volumeBonus??[];

          newBonus = newBonus.filter((_:any, i:number)=>(selectedVolumeBonus?.index !== i));
          handleUpdate({
            volumeBonus: newBonus
          },`${id}`);

          setSelectedVolumeBonus(undefined)
        }
      });
      
    }
    return;
    
    
  },  [project, selectedVolumeBonus])

  const handleUpdatePsac = useCallback((data:any)=>{
    if(!project.id) return;
    handleUpdate(data,`${id}`, {
      onFinish: ()=>{
        handleGet(`${id}`,{
          onFinish:()=>{
            message.success("Psac Saved successfully")
          }
        })
        
      }
    });
    
  },  [project])

  const loadings = {
    loading,
    loadingDeploy,
    loadingAddress,
    loadingWhitelist,
    loadingAddToken,
    uiLoaders
  }

  const handleShowTokenScreen = ()=>{
    setShowTokenScreen(old=>!old)
  }
  
  const fns = {
    handleBackArrow,
    handleNetworkSelect,
    handleSelectAvailableNetwork,
    handleDeploy,
    initWhitelistComponent,
    handleNewGroupSubmit,
    handleNewTokenSubmit,
    handleRemoveTokenSubmit,
    handleWidthdrawToken,
    handleManageGroupSubmit,
    handleManageVolumeBonus,
    handleManageAdminBonus,
    handleNewVolumeBonus,
    setSelectedGroup,
    handleShowTokenScreen,
    handleUpdatePsac
  }

  const dataStates = {
    project,
    currentNetwork,
    usdDecimals,
    fees,
    totalSacUSD,
    allWhitelists,
    selectedGroup,
    selectedVolumeBonus,
    selectedAdminBonus,
    sacAddress,
    projectOwners,
    acceptedTokens,
    showTokenScreen,
    selectedTokenAddress,
    adminBonuses,
    switchingNetwork,
    isOwner:  `${address}`.toLowerCase() === project.user 
  }
  

  return (
    <Container>
        <ViewProjectComponent
            dataStates={dataStates}
            loadings={loadings}
            fns={fns}
          />
    </Container>
  );
};
