import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const UPDATE_PROJECT = createActionType("UPDATE_PROJECT", "PROJECT");

export const updateProject = (
  payload: Record<string, any>,
  id: string,
  options?: ActionOption
) => ({
  type: UPDATE_PROJECT.START,
  meta: {
    ...options,
    id,
    payload,
  },
});
