import { NETWORKS_TYPE } from '../../../_shared';
import { ProjectNamespace } from '../../../_shared/namespaces/project';
import {
  AcceptedToken,
  GetBonusStat,
  UserDataStat,
} from '../../../_shared/utils/type';
import { DiscussionSection } from './discussion';
import { DistributionSection } from './distribution';
import { PerksSection } from './perks';
import { ProjectDescriptionSection } from './project_description';
import { SecLeadersBoard } from './sac_leadersboard';
import { SacScheduleSection } from './sac_schedule';
import { SacrificeSection } from './sacrifice';
import { VestingScheduleSection } from './vesting_schedule';
import { WatchVideoSection } from './watch_video';

interface MainLayoutProps {
  project?: ProjectNamespace.Project;
  sacAddress?: string;
  sacrificePhaseDay?: string;
  pointRate?: string;
  onComplete: () => void;
  handleSwitchNetwork: (any?: any) => void;
  userDataStats?: UserDataStat;
  currentNetwork?: NETWORKS_TYPE;
  loadingNetworkSwitch: boolean;
  deployToggle: boolean;
  loading: boolean;
  sacData: {
    amount?: string;
    uplineAddress?: string;
    tokenAddress?: string;
    dollarValue?: string;
    calcPoints?: number;
  };
  uiLoaders: Record<any, boolean>;
  handleChange: (
    address: string,
    amount: string,
    referalAddress?: string,
    delay?: number
  ) => void;
  handleOnFinish: (data?: any) => void;
  acceptedTokens?: AcceptedToken[];
  amountError?: string;
  referralError?: string;
  bonusData?: GetBonusStat;
  tokenBalance?: string;
  usdDecimals?: number;
  address?: string;
  projectUtils?: ProjectNamespace.UtilsData;
}
export const MainLayout = (props: MainLayoutProps) => {
  const {
    project,
    sacAddress,
    sacrificePhaseDay,
    pointRate,
    userDataStats,
    currentNetwork,
    loadingNetworkSwitch,
    onComplete,
    handleSwitchNetwork,
    handleChange,
    handleOnFinish,
    deployToggle,
    loading,
    sacData,
    uiLoaders,
    acceptedTokens,
    amountError,
    bonusData,
    referralError,
    tokenBalance,
    usdDecimals,
    address,
    projectUtils
  } = props;
  return (
    <>
      <div className="w-full justify-between mb-[44px] flex-wrap hidden lg:flex">
        <div className="w-[475px]">
          <div className="flex flex-col gap-5">
            <SacrificeSection
              project={project}
              sacrificePhaseDay={sacrificePhaseDay}
              pointRate={pointRate}
              onComplete={onComplete}
              handleSwitchNetwork={handleSwitchNetwork}
              userDataStats={userDataStats}
              currentNetwork={currentNetwork}
              loadingNetworkSwitch={loadingNetworkSwitch}
              deployToggle={deployToggle}
              handleChange={handleChange}
              handleOnFinish={handleOnFinish}
              loading={loading}
              sacData={sacData}
              uiLoaders={uiLoaders}
              acceptedTokens={acceptedTokens}
              amountError={amountError}
              bonusData={bonusData}
              referralError={referralError}
              tokenBalance={tokenBalance}
              usdDecimals={usdDecimals}
            />
            <SacScheduleSection
              project={project}
              sacrificePhaseDay={sacrificePhaseDay}
            />
            <VestingScheduleSection project={project} />
            <WatchVideoSection project={project} />
          </div>
        </div>
        <div className="w-[765px]">
          <div className="flex flex-col  gap-5">
            <ProjectDescriptionSection
              project={project}
              sacAddress={sacAddress}
            />
            <PerksSection  projectUtils={projectUtils}/>
            <DistributionSection project={project} />
            {project && <SecLeadersBoard project={project} />}
            {/* <DiscussionSection /> */}
            
          </div>
        </div>
      </div>
      <div className="w-full  justify-between mb-[44px] gap-4 flex-col flex lg:hidden">
        <SacrificeSection
          address={address}
          project={project}
          sacrificePhaseDay={sacrificePhaseDay}
          pointRate={pointRate}
          onComplete={onComplete}
          handleSwitchNetwork={handleSwitchNetwork}
          userDataStats={userDataStats}
          currentNetwork={currentNetwork}
          loadingNetworkSwitch={loadingNetworkSwitch}
          deployToggle={deployToggle}
          handleChange={handleChange}
          handleOnFinish={handleOnFinish}
          loading={loading}
          sacData={sacData}
          uiLoaders={uiLoaders}
          acceptedTokens={acceptedTokens}
          amountError={amountError}
          bonusData={bonusData}
          referralError={referralError}
          tokenBalance={tokenBalance}
          usdDecimals={usdDecimals}
        />
        <ProjectDescriptionSection project={project} sacAddress={sacAddress} />
        <DistributionSection project={project} />
        <PerksSection projectUtils={projectUtils} />
        {project && <SecLeadersBoard project={project} />}
        <SacScheduleSection
          project={project}
          sacrificePhaseDay={sacrificePhaseDay}
        />
        <VestingScheduleSection project={project} />
        <WatchVideoSection project={project} />
      </div>
    </>
  );
};
