import { Form } from "antd";
import styled from "styled-components";

export const ReferralBonusWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap:0px;

    form{
        width: 100%;
        margin-top: 40px!important;
    }
    .top-bar{
        margin-top: 40px;
        width: 100%;
        justify-content: space-between;
    }
    .ant-btn{
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
    .message-row{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

        margin-top: 80px;
        margin-bottom: 40px;
    }

    .sub-message-row{
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color)!important;
    }
    .eth-btn{
        background-color: #3C3CC7!important;
    }
    .deploy-btn{
        margin-top: 16px;
        margin-bottom: 120px;
        background-color: var(--success-color)!important;
        border-color: var(--success-color)!important;
    }
    /* var(--success-color) */

    .space-wrapper{
        margin-top: 56px;
        .ant-typography{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        &, .list-item-row{
            width: 100%;
        }
        .list-item-row{
            justify-content: space-between;
            .ant-space-item:first-child .ant-typography{
                color: var(--disabled-color);
            }
        }
    }
`;


export const FormWrapper = styled(Form)`
  
  .submit-btn{
    float: right;
    // border-color: var(--success-color)!important;
    // background-color: var(--success-color)!important;
  }

  .ant-form, .ant-form-item-control, .ant-input-textarea, .ant-picker, .ant-input-number{
        width: 100%!important; 
    } 
    .ant-form-item-control{
        input, select,.ant-select-selector{
            height: 56px!important;
            ::placeholder{
                color: var(--disabled-color);
            }
        }
        textarea{
            background-color: transparent;
        }
    }
`;

