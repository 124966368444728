import { createProject } from './create';
import { updateProject } from './update';
import { getProject } from './get';
import { deleteProject } from './delete';
import { fetchAllProjects } from './get-all';
const exportVar = [
  createProject,
  updateProject,
  getProject,
  deleteProject,
  fetchAllProjects,
];
export default exportVar;
