import { createUser } from "./create";
import { updateUser } from "./update";
import { getUser } from "./get";
import { getUserPastProjects } from "./get-past-projects";
import { deleteUser } from "./delete";
import { fetchAllUsers } from "./get-all";
const exportVar = [
  createUser,
  updateUser,
  getUser,
  getUserPastProjects,
  deleteUser,
  fetchAllUsers,
];
export default exportVar;
