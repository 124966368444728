import { useContext, useEffect, useState } from "react";
import {} from "../../redux/actions";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { useProject } from "../useProject";
// import { stringify } from "querystring";
import { GetBonusStat } from "../../_shared/utils/type";
import _, { debounce } from "lodash";
import { message } from "antd";
import { amountInWei, toEther } from "../../web3/libs/utilities";
import { EMPTY_ADDRESS, sacToken } from "../../_shared";
import { useSacProxy } from "../useSacProxy";
import Web3 from "web3";
import { WalletConnectorContext } from "../../contexts";
import { utils } from "ethers";

let $debounce: any;

type SacData = {
  amount?: string;
  uplineAddress?: string;
  tokenAddress?: string;
  dollarValue?: string;
  calcPoints?: number;
  dollarDecimals?: number;
  toggle: boolean;
};

interface IUseSacrifice {
  handleAmountNetworkChange: (
    address: string,
    amount: string,
    referalAddress?: string,
    delay?: number
  ) => void;
  onSacrifice: (data: any) => void;
  sacData: SacData;
  bonusData?: GetBonusStat;
  amountError?: string;
  referralError?: string;
  handleSacDataChange: (d: SacData) => void;
  loadingBonuses: boolean;
  tokenBalance: string;
}

interface UseSacrificeProps {
  key: string;
  sacAddress?: string;
  project: ProjectNamespace.Project;
  provider: any;
  usdDecimals: number;
  handleOnFinishSacrifice: (d?: any) => void;
}
export const useSacrifice = (parameter: UseSacrificeProps): IUseSacrifice => {
  const { chainId, address: walletAddress } = useContext(
    WalletConnectorContext
  );
  const {
    project,
    provider,
    sacAddress,
    usdDecimals,
    handleOnFinishSacrifice,
  } = parameter;

  const WEB3_KEY = `@@view-a-sac-web-3`;

  const {
    getAllowance,
    initTokenContract,
    sendApprove,
    getSacrificeWithBonus,
    getDollarAndPointsAmount,
  } = useSacProxy({
    key: WEB3_KEY,
    address: walletAddress,
    provider,
    project
  });

  const KEY_BONUS = `@@view-a-sac-bonus`;
  const [inputedAmountInWei, setInputedAmountInWei] = useState("");
  const [amountError, setAmountError] = useState("");
  const [referralError, setReferralError] = useState("");
  const [bonusData, setBonusData] = useState<GetBonusStat>();
  const [loadingBonuses, setLoadingBonuses] = useState(false);
  const [tokenBalance, setTokenBalance] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  // const [calculatedPointToken, setCalculatedPointToken] = useState<string|undefined>('');
  const web3 = new Web3(provider);
  const [sacData, setSacData] = useState<SacData>({
    amount: "",
    uplineAddress: "",
    tokenAddress: "",
    dollarValue: "",
    calcPoints: 0,
    dollarDecimals: 0,
    toggle: false,
  });

  // const { key, autoFetch = false, options: defaultOptions = {} } = parameter;

  const { handleGet: handleGetBonus } = useProject({
    key: KEY_BONUS,
  });

  const getBonuses = (
    callBack?: (d: any) => void,
    onError?: (e: any) => void
  ) => {
    handleGetBonus(`${project.id}/getBonuses`, {
      params: {
        amount: sacData.dollarValue,
        points: sacData.calcPoints,
        projectAddress: sacAddress,
        chainId,
        usdDecimals,
        uplineAddress: sacData.uplineAddress,
        user: walletAddress,
      },
      onFinish: (data: any) => {
        setBonusData(data);
        setLoadingBonuses(false);
        console.count("BOnus data: " + data.signature);
        if (callBack) callBack(data);
      },
    });
  };

  const handleSacDataChange = (data: SacData) => {
    setSacData((v) => ({ ...v, ...data }));
  };

  useEffect(() => {
    if (
      sacData?.tokenAddress &&
      sacData.amount &&
      sacData.tokenAddress &&
      sacAddress
    ) {
      getDollarAndPointsAmount(
        String(sacData?.tokenAddress),
        String(sacData?.amount),
        sacAddress,
        (data: any) => {
          setSacData((v) => {
            console.log("HJLJLKJLJK", v);
            v.dollarValue = data.dollarAmount;
            v.calcPoints = data.points;
            v.dollarDecimals = data.decimals;
            return { ...v, toggle: !v.toggle };
          });
        }
      );
    }
  }, [sacData.amount, sacData.tokenAddress]);

  useEffect(() => {
    console.log(
      "CALCPOINTS",
      sacData,
      sacData.dollarValue,
      sacData.tokenAddress,
      walletAddress,
      sacData.uplineAddress
    );
    if (
      project?.id &&
      sacData.tokenAddress &&
      sacData.calcPoints &&
      Number(sacData.calcPoints) > 0 &&
      sacData.dollarValue &&
      _.isEmpty(referralError) &&
      _.isEmpty(amountError)
    ) {
      if (
        Number(toEther(`${sacData.dollarValue}`, 2, usdDecimals)) <
        Number(`${project.minContributionAmount}`)
      ) {
        setSacData((v: any) => ({ ...v, amount: 0 }));
        setAmountError("Amount too low");
        return;
      } else {
        setLoadingBonuses(true);
        getBonuses();
      }
    }
  }, [
    sacData.dollarValue,
    sacData.calcPoints,
    sacData.tokenAddress,
    sacData.toggle,
    walletAddress,
    sacData.uplineAddress,
    referralError,
  ]);

  useEffect(() => {
    if (bonusData?.signature) setLoadingBonuses(false);
    console.count("BOnus sign: " + bonusData?.signature);
  }, [bonusData?.signature]);

  const validateBalance = (options: {
    onFinishLess: () => void;
    onFinish: (balance: string) => void;
    inputedAmountInWei: string;
    amountInWei: any;
  }) => {
    const { onFinishLess, onFinish, inputedAmountInWei, amountInWei } = options;
    if (!amountInWei || !inputedAmountInWei) return;
    if (Web3.utils.toBN(amountInWei).lt(Web3.utils.toBN(inputedAmountInWei))) {
      onFinishLess();
      return;
    } else {
      onFinish(amountInWei);
    }
  };

  const getBalance = (options: {
    onError?: (data: any) => void;
    onFinishLess: () => void;
    onFinish: (balance: string) => void;
    inputedAmountInWei?: string;
    tokenAddress?: string;
    validate?: boolean;
  }) => {
    const {
      onError,
      onFinishLess,
      onFinish,
      inputedAmountInWei,
      tokenAddress,
      validate = true,
    } = options;
    // setLoadingBonuses(false);
    setTokenBalance("");

    if (tokenAddress === EMPTY_ADDRESS) {
      web3.eth.getBalance(
        `${walletAddress}`,
        function (err: any, amountInWei: any) {
          if (!err) {
            setTokenBalance(amountInWei);
            validate &&
              validateBalance({
                onFinishLess,
                onFinish,
                inputedAmountInWei: String(inputedAmountInWei),
                amountInWei,
              });
          } else {
            onError && onError(err);
            console.log(`web3.eth.getBalance`, err);
          }
        }
      );
    } else {
      const contract = new web3.eth.Contract(sacToken as any, tokenAddress);
      contract.methods["balanceOf"](walletAddress)
        .call()
        .then((amountInWei: any) => {
          setTokenBalance(amountInWei);
          validateBalance({
            onFinishLess,
            onFinish,
            inputedAmountInWei: String(inputedAmountInWei),
            amountInWei,
          });
        });
    }
  };
  const handleAmountNetworkChange = (
    address: string,
    amount: string,
    referalAddress?: string,
    delay = 600
  ) => {
    setAmountError(``);
    setReferralError("");
    if (!address) {
      return;
    }

    const __amountInWei = _.isEmpty(amount)
      ? "0"
      : amountInWei(`${amount ?? "0"}`);

    if (
      !_.isEmpty(referalAddress) &&
      !utils.isAddress(String(referalAddress))
    ) {
      setReferralError("Invalid wallet address.");
      return;
    }

    if (
      referalAddress &&
      walletAddress &&
      referalAddress.toLowerCase() === walletAddress.toLowerCase()
    ) {
      setReferralError("You can not use your address as a referral address.");
      return;
    }

    setTokenAddress(address);
    setLoadingBonuses(true);
    getBalance({
      tokenAddress: address,
      inputedAmountInWei: __amountInWei,
      onError: (err) => {
        console.log(err);
        setLoadingBonuses(false);
      },
      onFinishLess: () => {
        setSacData((v: any) => ({ ...v, toggle: !v.toggle, amount: 0 }));
        setAmountError("Amount exceeds your balance");
        setLoadingBonuses(false);
      },
      onFinish: (balance) => {
        if (!amount || _.isEmpty(amount)) {
          setAmountError(`Input an Amount`);
          setSacData((v) => ({ ...v, calcPoints: 0 }));
          return;
        }
        if (Web3.utils.toBN(balance).lt(Web3.utils.toBN(__amountInWei))) {
          setSacData((v: any) => ({ ...v, amount: 0 }));
          setAmountError(`Amount exceeds your balance`);
          return;
        }

        if ($debounce) {
          // console.count("cancel Debounce");
          $debounce.cancel();
          $debounce = null;
        }

        $debounce = debounce(() => {
          setTimeout(()=>{
            setInputedAmountInWei(__amountInWei);
            setSacData((v) => {
              v.uplineAddress = referalAddress;
              v.tokenAddress = address;
              v.amount = __amountInWei;
              v.uplineAddress = referalAddress;
              return { ...v };
            });
          }, 1000)
          
        }, delay);
        $debounce();
      },
    });
  };

  const sacrificeETH = (sacDataBody: any, onFinish: () => void) => {
    getBalance({
      tokenAddress: `${sacData.tokenAddress}`,
      inputedAmountInWei,
      onError: (err) => {
        console.log(err);
      },
      onFinishLess: () => {
        setSacData((v: any) => ({ ...v, amount: 0 }));
        setAmountError(`Amount exceeds your balance`);
      },
      onFinish: () => {
        getSacrificeWithBonus(sacDataBody, {
          value: inputedAmountInWei,
          onFinish: (d) => {
            // setLoadingBonuses(false);
            handleOnFinishSacrifice(d);
            onFinish();
          },
          onError: (e) => {
            setLoadingBonuses(false);
            handleOnFinishSacrifice(e);
          },
        });
      },
    });
  };
  const sacrificeERC20 = (sacDataBody: any, onFinish: () => void) => {
    getAllowance({
      onFinish: (amountInWei) => {
        if (
          Web3.utils.toBN(amountInWei).lt(Web3.utils.toBN(inputedAmountInWei))
        ) {
          sendApprove(inputedAmountInWei, {
            onFinish: () => {
              getSacrificeWithBonus(sacDataBody, {
                onFinish: (d) => {
                  handleOnFinishSacrifice(d);
                  onFinish();
                },
                onError: (e) => {
                  setLoadingBonuses(false);
                  handleOnFinishSacrifice(e);
                },
              });
            },
          });
        } else {
          getSacrificeWithBonus(sacDataBody, {
            onFinish: (d) => {
              // setLoadingBonuses(false);
              handleOnFinishSacrifice(d);
            },
            onError: (e) => {
              setLoadingBonuses(false);
              handleOnFinishSacrifice(e);
            },
          });
        }
      },
    });
  };

  const onSacrifice = (onFinish: () => void) => {
    if (!sacAddress) return;
    if (_.isEmpty(sacData.uplineAddress)) sacData.uplineAddress = EMPTY_ADDRESS;
    if (
      sacData?.uplineAddress?.toLowerCase() === walletAddress?.toLowerCase()
    ) {
      message.info("You can not refer your address");
      return;
    }

    if (project.id && sacData.dollarValue && sacData.calcPoints) {
      // setLoadingBonuses(true);
      const sacDataBody = {
        _amount: inputedAmountInWei,
        _referalAddress: sacData.uplineAddress,
        _token: sacData.tokenAddress,
        _bonus: {
          points: bonusData?.points,
          projectAddress: bonusData?.projectAddress,
          donor: bonusData?.donor,
          amount: bonusData?.amount,
          nonce: String(bonusData?.nonce),
          bonuses: {
            whitelistBonus: bonusData?.whitelistBonus,
            volumeBonus: bonusData?.volumeBonus,
            referralBonus: bonusData?.referralBonus,
            refereeBonus: bonusData?.refereeBonus,
            psacBonus: bonusData?.psacBonus,
            stakeBonus: bonusData?.stakeBonus,
          },
        },
        _signature: bonusData?.signature,
      } as any;

      const onSacFinish = () => {
        getBalance({
          validate: false,
          onFinishLess: () => null,
          tokenAddress: sacData.tokenAddress,
          onFinish: () => null,
        });
        onFinish();
      };

      if (sacData.tokenAddress === EMPTY_ADDRESS) {
        sacrificeETH(sacDataBody, onSacFinish);
      } else {
        sacrificeERC20(sacDataBody, onSacFinish);
      }
    }
  };
  // useEffect(() => {
  //   if (sacData.dollarValue && project) {
  //     // console.log(
  //     //   "DollarVALLU",
  //     //   sacData.dollarValue,
  //     //   Web3.utils.toWei(`${project.minContributionAmount}`)
  //     // );
  //     if (
  //       Number(toEther(`${sacData.dollarValue}`, 2, usdDecimals))
  //         <
  //          Number(`${project.minContributionAmount}`)
  //     ) {
  //         setAmountError("Amount too low");
  //     }
  //   }
  // }, [sacData.dollarValue]);

  useEffect(() => {
    //Ovreride Provider
    if (tokenAddress || sacData.tokenAddress) {
      initTokenContract(tokenAddress ?? sacData.tokenAddress);
    }
  }, [sacData.tokenAddress, tokenAddress]);

  // useEffect(() => {
  // }, [provider]);

  return {
    handleAmountNetworkChange,
    onSacrifice,
    sacData,
    bonusData,
    amountError,
    handleSacDataChange,
    loadingBonuses,
    tokenBalance,
    referralError,
  };
};
