import { Form } from "antd";
import styled from "styled-components";

export const FormWrapper = styled(Form)`
  
  .submit-btn{
    float: right;
    border-color: var(--success-color)!important;
    background-color: var(--success-color)!important;
  }
  .ant-input-affix-wrapper{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }
  .ant-input-suffix, textarea::placeholder{
    color: var(--disabled-color)!important;
  }

  .ant-form, .ant-form-item-control, .ant-input-textarea, .ant-picker, .ant-input-number{
        width: 100%!important; 
    } 
    .ant-form-item-control{
        input, select,.ant-select-selector{
            height: 56px!important;
            ::placeholder{
                color: var(--disabled-color);
            }
        }
        textarea{
            background-color: transparent;
        }
    }

    .textarea-select{
        .ant-select-selector{
            min-height: 150px!important;
            height: auto!important;
            .ant-select-selection-overflow{
                align-self: flex-start;
            }
            .ant-select-selection-placeholder{
                top: 20%;
            }
        }
    }
`;
