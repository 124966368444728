import { Modal } from "antd";
import styled from "styled-components";

export const ModalWrapper = styled(Modal)`
  .ant-modal-content{
    padding: 40px;
  } 
  .ant-modal-close{
    margin: 40px;
    color: var(--text-color)!important;
  }
  .ant-modal-header, .ant-modal-body{
    border: 0px!important;
    padding-left: 0px!important;;
    padding-right: 0px!important;;
  }
  .ant-modal-title{
    font-weight: 500!important;
    font-size: 25px!important;
    line-height: 33px!important;
  }
`;
