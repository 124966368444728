import { Action } from "../../types";
import {
  CREATE_WHITELIST,
  GET_ALL_WHITELIST,
  GET_WHITELIST,
  UPDATE_WHITELIST,
} from "../../actions";
import { arrayToById } from "../../../_shared/utils";
import { WhitelistNamespace } from "../../../_shared/namespaces/whitelist";

export interface WhitelistReducerState {
  allWhitelists: Record<string, WhitelistNamespace.Whitelist[]>;
  whitelistsById: Record<string, Record<string, WhitelistNamespace.Whitelist>>;
  whitelist: Record<string, WhitelistNamespace.Whitelist>;
}

export const WhitelistDefaultState: WhitelistReducerState = {
  allWhitelists: {},
  whitelistsById: {},
  whitelist: {},
};

const WhitelistReducer = (state = WhitelistDefaultState, action: Action) => {
  switch (action.type) {
    case CREATE_WHITELIST.SUCCESS:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          [action.key]: action.payload,
        },
      };

    case GET_WHITELIST.SUCCESS:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          [action.key]: action.payload,
        },
      };

    case GET_ALL_WHITELIST.SUCCESS: {
      const byId = arrayToById(action?.payload ?? []);
      return {
        ...state,
        whitelistsById: {
          ...state.whitelistsById,
          [action.key]: byId,
        },
        allWhitelists: {
          ...state.allWhitelists,
          [action.key]: action.payload ?? [],
        },
      };
    }

    case UPDATE_WHITELIST.SUCCESS: {
      const allWhitelists = state.allWhitelists[action.key] ?? [];

      const index = allWhitelists.findIndex(
        (o) => o?._id === action.payload?._id
      );

      let currentArist = {};

      if (index !== -1) {
        currentArist = Object.assign({}, allWhitelists[index], action.payload);
        allWhitelists[index] = Object.assign(
          {},
          allWhitelists[index],
          action.payload
        );
      } else {
        currentArist = Object.assign({}, action.payload);
        allWhitelists.push(action.payload);
      }

      const byId = arrayToById(allWhitelists ?? []);

      return {
        ...state,
        whitelistsById: {
          ...state.whitelistsById,
          [action.key]: byId,
        },
        allWhitelists: {
          ...state.allWhitelists,
          [action.key]: allWhitelists ?? [],
        },
        whitelist: {
          ...state.whitelist,
          [action.key]: currentArist,
        },
      };
    }

    default:
      return state;
  }
};

export default WhitelistReducer;
