import {  Divider, Space, Spin, Typography } from "antd";
import { toEther } from "../../../../web3/libs/utilities";
import { moneyFormat } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { UserDataStat } from "../../../../_shared/utils/type";
import { MobileWrapper, DesktopWrapper } from "./index.styled";
import { PicCenterOutlined, BankFilled, AuditOutlined } from "@ant-design/icons";
const { Text } = Typography;
interface DesktopMenuDetailProps {
  
}

interface DesktopMenuDetailTwoProps {
  project?: ProjectNamespace.Project;
  userDataStats?: UserDataStat
  loading: boolean
}

interface MobileMenuDetailProps {
  project?: ProjectNamespace.Project;
  userDataStats?: UserDataStat
  loading: boolean
}
export const DesktopMenuDetail = (props: DesktopMenuDetailProps ) => {
  const { } = props;
  return (
    <DesktopWrapper>
      <Text className="title-text __section-title">GO TO SECTION</Text>
      <Space direction="vertical" size={24}>
        <a href={'#summary'}>
          <Space align="center" style={{fontSize:16, color: '#75ba8e'}}>
            <BankFilled/>
            <Text className="bolder" style={{ color: '#75ba8e'}}>Sacrifice</Text>
          </Space>
        </a>
        <a href={'#sacrifice'}>
          <Space align="center" style={{fontSize:16}}>
          <PicCenterOutlined size={58} style={{color: '#75ba8e'}}/>
            <Text className="bolder"  style={{ color: '#75ba8e'}}>Project Summary</Text>
          </Space>
        </a>
        <a href={'#details'}>
          <Space align="center" style={{fontSize:16}}>
            <AuditOutlined style={{color: '#75ba8e'}}/>
            <Text className="bolder"  style={{ color: '#75ba8e'}}>Project Details</Text>
          </Space>
        </a>
      </Space>

  </DesktopWrapper>
  );
};

export const DesktopMenuDetailTwo = (props: DesktopMenuDetailTwoProps ) => {
  const {userDataStats, loading} = props;
  const totals = [
    {  title: 'YOUR POINTS', amount: moneyFormat(userDataStats?.totalPoints, 0)},
    {title: 'YOUR CONTRIBUTION', amount: `$${moneyFormat(toEther(String(userDataStats?.totalContributed ?? 0)), 2)}`},
  ];
  const points = [
{class: 'disabled', title: 'Direct Points', amount: moneyFormat(userDataStats?.directPoints, 0)},
{class: 'disabled', title: 'Volume Bonus', amount: moneyFormat(userDataStats?.volumeBonus, 0)},
{class: 'disabled', title: 'Referral Bonus', amount: moneyFormat(userDataStats?.referralBonus, 0)}, 
{class: 'disabled', title: 'Referee Bonus', amount: moneyFormat(userDataStats?.refereeBonus, 0)},
{class: 'disabled', title: 'Whitelist Bonus', amount: moneyFormat(userDataStats?.whitelistBonus, 0)},
{class: 'disabled', title: 'Task Bonus', amount: moneyFormat(userDataStats?.taskBonus, 0)},
{class: 'disabled', title: 'Admin Bonus', amount: moneyFormat(userDataStats?.adminBonus, 0)}
  ];
  return (
    <DesktopWrapper>
      <Space className="sub-body grey-bg" direction="vertical">
        {totals.map(p=> {
         return <>
           <Text className="">{p.title}</Text>
          <Text className={`title-text`}>{p.amount}</Text>
          </>
        })}
      </Space>
      <Space className="sub-body2" direction="vertical">
        {points.filter(p=>p.amount && p.amount !== '0').map(p=> {
         return <>
           <Text className="small-title">{p.title}</Text>
          <Text className={`amount-text ${p.class ?? ''}`}><b>{p.amount}</b></Text>
          </>
        })}
      </Space>
      {loading && <span className="spin-wrapper"><Spin size="large"/></span>}
  </DesktopWrapper>
  );
};

export const MobileMenuDetail = (props: MobileMenuDetailProps ) => {
  const {userDataStats, loading} = props;
  return (
    <MobileWrapper>
      <Space align="center" className="__width-100" >
        <Space direction="vertical" align="center">
          <Text className="disabled">My Total Points</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.totalPoints, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Actual Points</Text>
          <Text className={`title-text `}>{moneyFormat(userDataStats?.directPoints, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Volume Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.volumeBonus, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Referral Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.referralBonus, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Referee Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.refereeBonus, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Total Contribution</Text>
          <Text className="title-text">{moneyFormat(toEther(String(userDataStats?.totalContributed ?? 0)), 2)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Whitelist Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.whitelistBonus, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Task Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.taskBonus, 0)}</Text>
        </Space>
        <Divider type="vertical" />
        <Space direction="vertical" align="center">
          <Text className="disabled">My Admin Bonus</Text>
          <Text className="title-text">{moneyFormat(userDataStats?.adminBonus, 0)}</Text>
        </Space>
        
      </Space>
      {loading && <span className="spin-wrapper"><Spin size="large"/></span>}
  </MobileWrapper>
  );
};
