import { Middleware } from "redux";
import { RootState } from "../../../state";
import {
  RESET_METHOD,
  setUIError,
  startUILoading,
  stopUILoading,
} from "../../../actions";

export const resetMethod: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === RESET_METHOD.START) {
      const { functionName, contractName, args, options } = action.meta;
      const { key, onFinish, onError } = options ?? {};
      const Key = key ?? functionName;
      try {
        dispatch(startUILoading(Key));

        const data: any = {};
        dispatch({
          type: RESET_METHOD.SUCCESS,
          payload: { contractName, data },
        });
        onFinish?.();
        dispatch(stopUILoading(Key));
      } catch (e) {
        console.log(`OUTPUT ${contractName} - ${functionName} ::::`, args, e);
        dispatch(stopUILoading(Key));
        dispatch(setUIError(Key, e as string));
        onError?.(e);
        dispatch({ type: RESET_METHOD.ERROR, e });
      }
    }
  };
