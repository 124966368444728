import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Divider, Form, Input, message, Select, Space } from "antd";
import ImgCrop from "antd-img-crop";
import { useForm } from "antd/lib/form/Form";
import Upload, { RcFile } from "antd/lib/upload";
import { useEffect, useMemo, useState } from "react";
import {
  CATEGORIES,
  PRODUCTSTAGES,
  REACT_APP_BASEURL,
  SOCIALS,
} from "../../../../../_shared";
import { ProjectNamespace } from "../../../../../_shared/namespaces/project";
import { FormWrapper } from "../index.styled";
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

interface FormOneComponentProps {
  project: ProjectNamespace.Project;
  loading: boolean;
  onFinish?: ((values: any) => void) | undefined;
}

export const FormOneComponent = (props: FormOneComponentProps) => {
  const { onFinish, project, loading } = props;
  const [loadingImg, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState<string>();
  const [form] = useForm();
  const introVideo = Form.useWatch<string>("introVideo", form);

  const [catOptions, setCatOptions] = useState<{ value: string }[]>(
    CATEGORIES.map((catValue) => ({
      value: catValue,
    }))
  );
  const [altCatOptions, setAltCatOptions] = useState<{ value: string }[]>(
    CATEGORIES.map((catValue) => ({
      value: catValue,
    }))
  );

  const embededUrl = useMemo(() => {
    if (!introVideo) return "";
    const splitted = introVideo.split("youtube.com/watch?v=");
    const splittedShort = introVideo.split("youtube.com/shorts/");
    if (splittedShort.length > splitted.length) {
      return (
        "https://www.youtube.com/embed/" +
        splittedShort[splittedShort.length - 1]
      );
    }
    return "https://www.youtube.com/embed/" + splitted[splitted.length - 1];
  }, [introVideo]);

  const initialValue = {
    title: project.title,
    productStage: project.productStage,
    category: project.category,
    subCategory: project.subCategory,
    website: project.website,
    logo: project.logo,
    banner: project.banner,
    spotlightImage: project.spotlightImage,
    description: project.description,
    socials: project.socials,
    introVideo: project.introVideo,
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setImageUrl("");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      setImageUrl("");
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeBannerUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setBannerImageUrl("");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      setBannerImageUrl("");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    form.setFieldsValue(initialValue);
    setImageUrl(project.logo);
    setBannerImageUrl(project.banner);
  }, [project]);
  return (
    <FormWrapper>
      <Form
        name="form_one"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValue}
        requiredMark={false}
      >
        <Form.Item
          label="Project Title"
          name="title"
          rules={[
            { required: true, message: "Please input your project title!" },
          ]}
        >
          <Input placeholder="e.g GameBoy" />
        </Form.Item>

        <Form.Item
          label="Website URL"
          name="website"
          rules={[
            {
              required: true,
              type: "url",
              message: "Please input your project website!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Product Stage"
          name="productStage"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please select a project stage!" },
          ]}
        >
          <Select placeholder="Select a project stage" allowClear>
            {PRODUCTSTAGES.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <AutoComplete
            options={catOptions}
            // onSelect={onSelect}
            onSearch={(text) => {
              if (text) {
                setCatOptions(
                  CATEGORIES.filter((fV) =>
                    fV.toLowerCase().includes(text.toLowerCase())
                  ).map((catValue) => ({
                    value: catValue,
                  }))
                );
              } else {
                setCatOptions(
                  CATEGORIES.map((catValue) => ({
                    value: catValue,
                  }))
                );
              }
            }}
            placeholder="Select a category"
          />
        </Form.Item>

        <Form.Item
          label="Alt Category"
          name="subCategory"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please select an alternate category!" },
          ]}
        >
          <AutoComplete
            options={altCatOptions}
            // onSelect={onSelect}
            onSearch={(text) => {
              if (text) {
                setAltCatOptions(
                  CATEGORIES.filter((fV) =>
                    fV.toLowerCase().includes(text.toLowerCase())
                  ).map((catValue) => ({
                    value: catValue,
                  }))
                );
              } else {
                setAltCatOptions(
                  CATEGORIES.map((catValue) => ({
                    value: catValue,
                  }))
                );
              }
            }}
            placeholder="Select a category"
          />
        </Form.Item>

        <Form.Item
          name="logo"
          label="Logo"
          rules={[
            {
              required: true,
              type: "url",
              message: "Please upload your project logo!",
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              name="file"
              disabled={loading}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_BASEURL}/media`}
              beforeUpload={beforeUpload}
              onChange={(info) => {
                console.log({ info });
                if (info?.file?.status === "done") {
                  // Get this url from response in real world.
                  getBase64(info?.file?.originFileObj as RcFile, (url) => {
                    setLoading(false);
                    setImageUrl(url);
                  });
                }
                if (info?.file?.status === "uploading") {
                  setLoading(true);
                }
                const url =
                  info?.file?.response?.data?.url ??
                  info?.file?.response?.data?.file?.url;
                form.setFieldsValue({ logo: url });
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ height: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          name="banner"
          label="Banner"
          validateStatus="validating"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              type: "url",
              message: "Please upload your project Banner!",
            },
          ]}
        >
          <ImgCrop rotationSlider aspect={4 / 1}>
            <Upload
              name="file"
              //disabled={loading}
              listType="picture-card"
              className="avatar-uploader banner"
              showUploadList={false}
              action={`${REACT_APP_BASEURL}/media`}
              beforeUpload={beforeBannerUpload}
              onChange={(info) => {
                if (info?.file?.status === "done") {
                  // Get this url from response in real world.
                  getBase64(info?.file?.originFileObj as RcFile, (url) => {
                    setLoadingBanner(false);
                    setBannerImageUrl(url);
                  });
                }
                if (info?.file?.status === "uploading") {
                  setLoadingBanner(true);
                }
                const url =
                  info?.file?.response?.data?.url ??
                  info?.file?.response?.data?.file?.url;
                form.setFieldsValue({ banner: url });
              }}
            >
              {bannerImageUrl ? (
                <img
                  src={bannerImageUrl}
                  alt="avatar"
                  style={{ height: "100%" }}
                />
              ) : (
                <div>
                  {loadingBanner ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload Banner</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input Description" }]}
        >
          <Input.TextArea rows={4} showCount maxLength={80} />
        </Form.Item>

        <Form.Item>
          <Space>
            <span className="ant-form-text">Social Media</span>
          </Space>
        </Form.Item>

        <Form.List
          name="socials"
          rules={[
            {
              validator: async (_, links) => {
                if (!links || links.length < 1) {
                  return Promise.reject(new Error("At least 1 Social Media"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={field.key + index}
                  className="social-media-items"
                  align="center"
                >
                  <Form.Item
                    {...field}
                    label="Name"
                    key={"name" + index}
                    name={[field.name, "name"]}
                    rules={[
                      { required: true, message: "Missing Social Media" },
                    ]}
                  >
                    <Select placeholder="Select a social media" allowClear>
                      {SOCIALS.map((social, index) => {
                        return (
                          <Option key={index} value={social.key}>
                            {social.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Url"
                    key={"url" + index}
                    name={[field.name, "url"]}
                    rules={[
                      { required: true, type: "url", message: "Missing Url" },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="text"
                  onClick={() => add()}
                  style={{ marginLeft: "auto" }}
                  icon={<PlusOutlined />}
                >
                  Add Another Social
                </Button>
              </Form.Item>
              <div>
                <Form.ErrorList errors={errors} />
              </div>
            </>
          )}
        </Form.List>

        <Form.Item
          label="Intro video"
          name="introVideo"
          // requiredMark={'optional'}
          rules={[
            {
              required: false,
              message: "Please input your project intro video!",
            },
          ]}
        >
          <Input placeholder="e.g https://www.video.com/tags/movie.mp4" />
        </Form.Item>

        {introVideo && (
          <div className="flex mb-[20px]  w-1/2 justify-between border-none">
            <iframe
              width="560"
              height="315"
              src={embededUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}

        <Divider />

        <Form.Item>
          <Button
            loading={loading}
            shape="round"
            className="next-btn"
            type="primary"
            htmlType="submit"
          >
            Save Project Settings
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};
