
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { WhitelistNamespace } from "../../../../../_shared/namespaces/whitelist";
import { ModalWrapper } from "../index.styled";
import { FormWrapper } from "./index.styled";

interface ManageWhitelistModalProps{
    visible: boolean
    onFinish?: ((values: any) => void) | undefined
    onClose?: () => void
    selectedGroup?: WhitelistNamespace.Whitelist;
}

export const ManageWhitelistModal = (props: ManageWhitelistModalProps) => {
    const {visible, onFinish, onClose, selectedGroup} = props;


    
console.log('REPLACEESSSA', selectedGroup)
    
    const [form] = useForm()
    useEffect(() => {
        form.resetFields()
    }, []);
    useEffect(() => {
        form.setFieldsValue({...selectedGroup, addresses: selectedGroup?.addresses})
    }, [selectedGroup]);
    return (
          <ModalWrapper  
                title="Manage Whitelist Group" 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
            
            <FormWrapper
                name="manage_group"
                layout="vertical"
                form={form}
               
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={'optional'}
                >
                

                    <Form.Item
                        label="Group Name"
                        name="name"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input name!' }]}
                    >
                        <Input placeholder="Enter Group Name"  />
                    </Form.Item>

                    <Form.Item
                        label="Bonus Percentage"
                        name="percentage"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input percentage!', }]}
                    >
                        <Input type="number"  placeholder="e.g 10" suffix={"%"}/>
                    </Form.Item>

                  

                    <Form.Item
                        label="Addresses"
                        name="addresses"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input addresses!' }]}
                    >
                        <TextArea placeholder="Seperate addresses with a comma" rows={5} />
                    </Form.Item>
                    {/* <Form.Item
                        label="Addresses"
                        name="addresses"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input percentage!' }]}
                    >
                        <Select
                            className="textarea-select"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={options}
                            onKeyDown={handleKeyStroke}
                            />
                    </Form.Item> */}

                <Form.Item>
                    <Button size="large" className="submit-btn" type="primary" htmlType="submit">
                        Save Changes
                    </Button>
                </Form.Item>
                </FormWrapper>
          </ModalWrapper>
    );
};