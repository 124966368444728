import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_USER = createActionType("GET_USER", "USER");

export const getUser = (id: string, options?: ActionOption) => ({
  type: GET_USER.START,
  meta: {
    ...options,
    id,
  },
});
