
import { Button, Space, Typography } from "antd";
import { NewProjectSuccessWrapper } from "./index.styled";
const { Text } = Typography;

interface NewProjectSuccessComponentProps {
  handleBackToProjects : (data?:any)=>void
  handleVisitProjects : (data?:any)=>void
}

export const NewProjectSuccessComponent = (props: NewProjectSuccessComponentProps) => {
  const {handleBackToProjects, handleVisitProjects} = props;
  return (
        <NewProjectSuccessWrapper>
          <img className="rocket" src="/rocket.svg" alt="..." />
          <Text className="main-text">Sacrifice Project Created</Text>
          <Text disabled className="sub-text">You have successfully created a new sacrifice project</Text>
          <Space>
            <Button onClick={handleBackToProjects} className="btn-back" shape="round">Back to Projects</Button>
            <Button onClick={handleVisitProjects} className="btn-visit" type="primary" shape="round">Visit Project</Button>
          </Space>
        </NewProjectSuccessWrapper>
  );
};