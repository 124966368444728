import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .ant-breadcrumb {
    margin-bottom: 10px !important;
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator {
      color: var(--accent) !important;
    }
  }

  .ant-table-content {
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--landing-background-3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--landing-background);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--landing-background-2);
    }
  }
`;
