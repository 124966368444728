import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Table, Typography } from "antd";
import { useEffect } from "react";
import { WhitelistNamespace } from "../../../../_shared/namespaces/whitelist";
import { WhitelistWrapper } from "./index.styled";
import { ColumnType, ColumnsType } from "antd/es/table";
const { Text } = Typography;
interface WhitelistComponentProps {
  allWhitelists: WhitelistNamespace.Whitelist[];
  loading: boolean;
  handleNewGroup: () => void;
  handleManageGroup: (data?: any) => void;
  initComponent: () => void;
}
export const WhitelistComponent = (props: WhitelistComponentProps) => {
  const {
    handleNewGroup,
    handleManageGroup,
    initComponent,
    allWhitelists,
    loading,
  } = props;

  useEffect(() => {
    initComponent();
  }, []);

  const renderListData = allWhitelists.map((data, key) => (
    <>
      <Row key={key} className="row-list-item">
        <Col span={8}>
          <Text className="">{data.name}</Text>
        </Col>
        <Col span={8}>
          <Text className="">{data.percentage}%</Text>
        </Col>
        <Col span={8}>
          <Space className="btn-container">
            <Button
              onClick={() => handleManageGroup(data)}
              shape="round"
              className="withdraw-btn"
            >
              Manage
            </Button>
            <Button
              shape="round"
              type="text"
              icon={<DeleteFilled />}
              className="delete-btn"
            />
          </Space>
        </Col>
      </Row>
    </>
  ));

  const columns: ColumnsType<WhitelistNamespace.Whitelist> = [
    {
      title: "Group Name",
      key: "name",
      render: (_: any, data) => {
        return <Text className="">{data.name}</Text>;
      },
    },
    {
      title: "Bonus",
      key: "percentage",
      render: (_: any, data) => {
        return <Text className="number-font">{data.percentage} %</Text>;
      },
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "network",
      render: (_: any, data) => {
        return (
          <div className="flex">
            <Button
              onClick={() => handleManageGroup(data)}
              shape="round"
              className="withdraw-btn"
            >
              Manage
            </Button>
            <Button
              onClick={() => handleManageGroup({...data, "action":"delete-item"})}
              shape="round"
              type="text"
              icon={<DeleteFilled />}
              className="delete-btn"
            />
          </div>
        );
      },
    },
  ];

  return (
    <WhitelistWrapper className="pt-10">
      <div className="hint">
        <Text>Issue a percentage bonuse to specific addresses</Text>
      </div>
      <Space className="top-bar mb-10" align="center">
        <Button
          loading={loading}
          onClick={handleNewGroup}
          type="primary"
          ghost
          className="new-group-btn"
        >
          New Whitelist Group
        </Button>
      </Space>
      <Table className="w-full" dataSource={allWhitelists} columns={columns}  loading={loading} pagination={false}/>
    </WhitelistWrapper>
  );
};
