import { StakeContainer } from "../../container";



const StakePage = () => {
  
    
  return <StakeContainer/>
}

export default StakePage
StakePage.renderClassName = "__app_secondary";