import { createActionType } from "../../../../_shared/utils";

export const GET_CONTRACTS = createActionType("GET_CONTRACTS", "CONTRACTS");

export type ContractType = {
  title: string;
  abi: any[];
  address: string;
};

export const getContracts = (
  provider: any,
  contracts: ContractType | ContractType[],
  key?: string
) => ({
  type: GET_CONTRACTS.START,
  meta: {
    provider,
    contracts,
    key,
  },
});
