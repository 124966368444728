import { theme } from "antd";
import { MainContainer, MainFooter, MainHeader } from "../../../components";
import { ReactNode } from "react";
const { useToken } = theme;

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
  renderClassName?: string;
  controlled?: boolean;
}

export const MainLayoutContainer = (props: MainLayoutProps) => {
  const {
    children,
    renderClassName = "__app_secondary !pt-0",
    controlled,
  } = props;
  const { token } = useToken();

  return (
    <MainContainer token={token}>
      <MainHeader />
      <main className={controlled ? "" : renderClassName}>{children}</main>
      <MainFooter />
    </MainContainer>
  );
};
