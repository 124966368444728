

import { Button, Space} from "antd";

import { SignButtonWrapper} from "../index.styled";

interface SignAddressButtonProps {
  handleClick: ()=>void
  loading: boolean
  address?: string
}

export const SignAddressButton = (props: SignAddressButtonProps) => {
  const {handleClick, loading, address} = props;
  return (
    <SignButtonWrapper >
      <div>
      <Space direction="vertical" size="large" >
      <img width={50} src="/pulsesac_logo.svg" alt="...." /> 
      </Space>
      </div>
      <Space direction="vertical" size="large" >
      {/* <h2 className="mt-2 text-gray-500" style={{fontFamily: 'Antipasto Pro'}}>Welcome to PulseSac</h2> */}
      </Space>
      <div>
      {/* <h2 className="text-white">{address}</h2> */}
      Everyone should be given the freedom to impact the world positively.
      <br/>
     Everyone should have the freedom to support any creator or invention they consider impactful
      <br/>without fear or prejudice.
      <p><b>We believe in the freedom to create!</b></p>
      <p>Click the continue button to keep using PulseSac if you agree<br/>to this and our <a href='/terms' style={{color: 'yellow'}}>terms of service</a>.</p>
      </div>

      {/* <div>
        Continuing implies you have read and agree to our <a href='/terms' target={'_blank'}>terms of service</a>
      </div> */}
      
        <Button size="large" loading={loading} onClick={handleClick} type="primary"  >Click to Continue</Button>
    </SignButtonWrapper>
  );
};