import { ManageProjectContainer } from "../../../container/manage-project"




const ManageProjectPage = () => {
  
    
  return <ManageProjectContainer/>
}

export default ManageProjectPage
