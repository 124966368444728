import { theme, Typography } from "antd";
// import Link from "next/link";
import { Container } from "./index.styled";

const { useToken } = theme;
const { Text } = Typography;

interface WhyPulsacSectionProps {}

export const WhyPulsacSection = (props: WhyPulsacSectionProps) => {
  // const {} = props;

  const { token } = useToken();

  return (
    <Container token={token} className={"!mb-0"} id="features">
      <Text className="text-3xl lg:text-6xl sm:text-3xl w-full lg:w-[60%] leading-[118%]  font-semibold mx-auto text-center">
        Why Use the PulseSac DAPP for your Sac Project?
      </Text>

      <div className="grid grid-cols-2 lg:grid-cols-3 mt-[25px] lg:mt-[100px] gap-[22px] gap-x-6 lg:gap-[25px]">
        {[
          {
            name: "Easy To Use",
            subTitle: "Robust, Customisable and easy to use",
            icon: "easy",
          },
          {
            name: "Decentralized",
            subTitle: "Completely decentralized and immutable",
            icon: "network",
          },
          {
            name: "Exposure",
            subTitle: "Exposure to our great community of Sacrificers",
            icon: "exposure",
          },
          {
            name: "Automated",
            subTitle:
              "Automatically distribute your tokens at the end of the sacrifice period",
            icon: "auto",
          },
          {
            name: "Add on Features",
            subTitle: "Tons of add-on features",
            icon: "add",
          },
          {
            name: "Add on Features",
            subTitle: "Tons of add-on features",
            icon: "add",
          },
        ].map((item) => {
          return (
            <div className="flex flex-col gap-[6px] lg:gap-[23px] py-[20px] px-[10px] lg:py-[60px] lg:px-[40px] bg-gray-sec-500">
              <img
                src={`/logos/${item.icon}.svg`}
                alt=""
                className="w-[40px] h-[40px]"
              />
              <Text className="text-[16px] lg:text-[20px] font-semibold">
                {item.name}
              </Text>
              <Text
                className="text-[14px] lg:text-[20px] font-medium "
                style={{ color: token.colorTextDisabled }}
              >
                {item.subTitle}
              </Text>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
