import { ViewSacrificeContainer } from "../../../container";



const SacPage = () => {
  
    
  return <ViewSacrificeContainer/>
}

export default SacPage
SacPage.renderClassName = "__app_secondary";