import { createWhitelist } from './create';
import { updateWhitelist } from './update';
import { getWhitelist } from './get';
import { deleteWhitelist } from './delete';
import { fetchAllWhitelists } from './get-all';
const exportVar = [
  createWhitelist,
  updateWhitelist,
  getWhitelist,
  deleteWhitelist,
  fetchAllWhitelists,
];
export default exportVar;
