
import { DeleteOutlined, PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Form, Typography,   InputNumber, Space , Select, Tooltip} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormWrapper } from "../index.styled";


import { NETWORKS } from "../../../../../_shared";
const { Option } = Select;
const { Text } = Typography;

interface FormThreeComponentProps  {
    // length: number;
    // currentIndex: number;
    onFinish?: ((values: any) => void) | undefined
    handlePrevious?: (() => void)
    loading: boolean
    totalPhaseDays:number
}

export const FormThreeComponent = (props: FormThreeComponentProps) => {
    const {onFinish, handlePrevious, loading, totalPhaseDays} = props;

    const [form] = Form.useForm();
    const vestingSchedule = Form.useWatch('vestingSchedule', form);

    const [vestingScheduleData, setVestingScheduleData] = useState([]);
    const [totalPercentage, setTotalPercentage] = useState(0);

    const handleLocalOnFinish = (data:any)=>{
        
        onFinish?.(data)
    }
    
    useEffect(() => {
        const totalValue = (vestingSchedule as []??[]).reduce((prev:number, obj:any)=>{
            
            if(obj)return prev + (obj?.percentage??0)??0;
            return prev
        }, 0)
        
        if(totalValue>100){
            form.setFieldsValue({ vestingSchedule: vestingScheduleData })
        }else{
            setTotalPercentage(totalValue);
            setVestingScheduleData(vestingSchedule)
        }
    }, [vestingSchedule]);

    const allDateIsValid = (vestingSchedule as []??[]).reduce((prev:boolean, obj:any)=>{
            
        if(obj)return prev  && dayjs().add(totalPhaseDays, "days").startOf('day').isBefore(obj.timestamp);
        return prev
    }, true)
    const disableSubmitButton =  totalPercentage!==100 || !(vestingSchedule as Array<any>).find(d=>!!d.timestamp) || !allDateIsValid;
    return (
            <FormWrapper>
                <Form
                    form={form}
                    name="form_three"
                    layout="vertical"
                    onFinish={handleLocalOnFinish}
                    autoComplete="off"
                    requiredMark={'optional'}
                    initialValues={{ vestingSchedule: [""] }}
                    >

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Token Distribution Chain</Text>
                            <Tooltip title="This is the primary chain you plan to create and distribute your tokens">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="tokenDistributionChainId"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input your project chain!' }]}
                    >
                        <Select placeholder="Select Distribution Chain">
                            {NETWORKS.map((network, index)=>(
                                <Option value={network.chainId} key={index}>{network.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    
                    <Form.Item>
                        <Space>
                            <span className="ant-form-text">Vesting Schedule ({totalPercentage??0}%)</span>
                           

                        </Space>
                        <Tooltip title="This is the schedule on how donors will claim their tokens after the end of the sacrifice period.">
                                <InfoCircleOutlined />
                            </Tooltip>
                        
                    </Form.Item>

                    <Form.List
                        name="vestingSchedule"
                        rules={[
                        {
                            validator: async (_, links) => {
                            if (!links || links.length < 1) {
                                return Promise.reject(new Error('At least 1 Vesting Schedule'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            
                        <>
                            {fields.map((field, index) => {
                                let startMoment = dayjs().add(totalPhaseDays, "days").startOf('day');
                                if(index >0){
                                    if(vestingSchedule[index-1].timestamp) startMoment = vestingSchedule[index-1].timestamp.endOf('day');
                                }
                                return  <Space key={field.key +index} className="social-media-items" align="center" >
                                            <Form.Item
                                                {...field}
                                                key={'timestamp' +index}
                                                name={[field.name, 'timestamp']}
                                                label="Day and Time"
                                                // requiredMark={'optional'}
                                                rules={[{ required: true, message: 'Please select day!' }]}
                                            >
                                                <DatePicker 
                                                showNow={false}
                                                showTime 
                                                disabledDate={current => {
                                                    return current && current < startMoment;
                                                }} />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Percentage Claimable"
                                                key={'percentage' +index}
                                                name={[field.name, 'percentage']}
                                                // requiredMark={'optional'}
                                                rules={[{ required: true, message: 'Please input a number!' }]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    parser={(value => {
                                                        if(!value) return '';
                                                        return Math.floor(Number(value));
                                                    })}
                                                placeholder="Enter Percentage" />
                                            </Form.Item>
                                            
                            
                                            {fields.length > 1 ? (
                                                <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                                ) : null}
                                        </Space>
                                })}
                            <Form.Item>
                                <Button
                                    type="text"
                                    onClick={() => add()}
                                    style={{ marginLeft: 'auto' }}
                                    icon={<PlusOutlined />}
                                    disabled={totalPercentage >= 100}
                                >
                                    Add another day
                                </Button>
                            </Form.Item>
                            <div>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                        )}
                    </Form.List>
                    <Form.Item  style={{display: totalPercentage>0 && totalPercentage<100?'flex':'none'}}>
                        <Space style={{marginLeft: 'auto'}}>
                            <span className="ant-form-item-explain-error">Total Vesting Percentage must be 100%</span>
                        </Space>
                    </Form.Item>
                    <Divider style={{marginTop: 40}} />

                    
                                        
                    <Form.Item>
                        <Button onClick={handlePrevious} size="large" className="prev-btn" htmlType="button">
                            Previous
                        </Button>
                        <Button disabled={disableSubmitButton} loading={loading} size="large" className="finish-btn" type="primary" htmlType="submit">
                            Complete Project
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
    );
  };    