import { PlayCircleFilled } from "@ant-design/icons";
import { Typography, Button, Row, Col, Space, Tabs } from "antd";
// import Link from "next/link";
import { Container } from "./index.styled";
const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface TabContentProps {
  title: string;
  data: string[];
}
interface SectionOneProps {
  text?: string;
  tabContent: TabContentProps[];
}
export const SectionOne = (props: SectionOneProps) => {
  const { tabContent } = props;

  const renderTabs = tabContent.map((tabDetails, index) => {
    const renderTabItem = tabDetails.data.map((detail, j) => {
      return (
        <Col key={`${index}-${j}`} className="gutter-row" xs={12} lg={8}>
          <div>{detail}</div>
        </Col>
      );
    });

    return (
      <TabPane tab={tabDetails.title} key={index}>
        <Row className="project-owner-row" gutter={[24, 24]}>
          {renderTabItem}
        </Row>
      </TabPane>
    );
  });

  return (
    <Container>
      <Title level={1} style={{ marginTop: "108px" }}>
        The ultimate sacrifice Dapp coming to the Pulse community
      </Title>
      <Space align="center" style={{ width: "100%" }}>
        <Col className="m-bullet">●</Col>
        <Col className="m-point-text">
          PulseSac is a decentralized Dapp that makes it hassle free for project
          owners to organize a sacrifice in minutes without writing any code.
        </Col>
      </Space>
      <Space align="center" style={{ width: "100%" }}>
        <Col className="m-bullet">●</Col>
        <Col className="m-point-text">
          {`"Sacrifice" - the concept of donating funds towards a course without expectation of profiting from the work of others.`}{" "}
        </Col>
      </Space>
      <Space
        align="center"
        className="get-started-container"
        size={16}
        style={{ marginTop: "40px" }}
      >
        <Button className="linear-bg">Join Sacrifice</Button>
        <Button type="text" icon={<PlayCircleFilled />}>
          Watch Video
        </Button>
      </Space>
      <Space className="side-social" align="center" size={32}>
        <a  rel="noreferrer" href={"https://t.me/PulseSac"} target="_blank">
          <Text>Telegram</Text>
        </a>
        <a rel="noreferrer" href={"https://twitter.com/pulsesac_dapp"} target="_blank">
          <Text>Twitter</Text>
        </a>
        <a rel="noreferrer" href={"https://www.reddit.com/r/pulsesac/"} target="_blank">
          <Text>Reddit</Text>
        </a>
      </Space>

      <Title className="what-is-it" id="what-is-it" level={2}>
        Who is it for?
      </Title>

      <Tabs className="project-tabs" defaultActiveKey="0" centered>
        {renderTabs}
      </Tabs>
    </Container>
  );
};
