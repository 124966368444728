import { Container } from "./index.styled";
import {
  useGeneric,
  useProject,
  useSacProxy,
  useUIState,
  useWhitelist,
} from "../../hooks";
import { message, Modal, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { EMPTY_ADDRESS, NETWORKS_TYPE } from "../../_shared/constants";
import { WalletConnectorContext } from "../../contexts";
import { WhitelistNamespace } from "../../_shared/namespaces/whitelist";
import {
  resolveCurrentNetworkObject,
  shorternAddress,
} from "../../_shared/utils";
import {
  ExclamationCircleOutlined,
  DollarCircleFilled,
} from "@ant-design/icons";
import { toEther } from "../../web3/libs/utilities";
import { AdminNamespace } from "../../_shared/namespaces/admin";
import ManageProject from "../../components/project/manage-project";
import { ProjectNamespace } from "../../_shared/namespaces/project";
const { confirm } = Modal;
const { Text } = Typography;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export const ManageProjectContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const KEY = `@@view-a-project`;
  const KEY_WHITELIST = `@@view-a-project-whitelist`;

  const { uiLoaders } = useUIState();
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const { address, switchNetwork, chainId, provider } = useContext(
    WalletConnectorContext
  );

  const { project, handleGet, handleGetAll, handleUpdate } = useProject({
    key: KEY,
  });

  useEffect(() => {
    if (!id) return;
    handleGetAll({
      params: {
        id: id,
      },
      onFinish: (d) => console.log("PRJOECTID3", d),
    });
  }, [id, toggle1]);

  useEffect(() => {
    if (id)
      handleGet(`${id}/signature`, {
        onFinish: (d) => console.log("PRJOECTID5", d),
      });
  }, [id]);
  if (!project) return null;
  return (
    <ManageProjectContainerState
      dataState={{
        address,
        switchNetwork,
        chainId,
        provider,
        toggle2,
        toggle1,
        setToggle2,
        setToggle1,
        handleUpdate,
        handleGet,
      }}
      project={project}
      uiLoaders={uiLoaders}
    />
  );
};

export const ManageProjectContainerState = (props: any) => {
  const navigate = useNavigate();

  const query = useQuery();
  const KEY = `@@view-a-project`;
  const KEY_WHITELIST = `@@view-a-project-whitelist`;

  const {
    uiLoaders,
    project,

    dataState: {
      address,
      switchNetwork,
      chainId,
      provider,
      toggle1,
      toggle2,
      setToggle2,
      setToggle1,
      handleUpdate,
      handleGet,
    },
  } = props;
  const id = project?.id;
  const [currentNetwork, setCurrentNetwork] = useState<NETWORKS_TYPE>();
  const [selectedGroup, setSelectedGroup] =
    useState<WhitelistNamespace.Whitelist>();
  const [selectedAdminBonus, setSelectedAdminBonus] =
    useState<AdminNamespace.AdminBonus>();
  const [selectedVolumeBonus, setSelectedVolumeBonus] = useState<
    { contributionAmount: string; points: string; index?: number } | undefined
  >(undefined);

  const [showTokenScreen, setShowTokenScreen] = useState(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
  const [projectUtils, setProjectUtils] =
    useState<ProjectNamespace.UtilsData>();

  const [switchingNetwork, setSwitchingNetwork] = useState<NETWORKS_TYPE>();

  const handleBackArrow = () => {
    navigate("/projects");
  };

  const { dataList: adminBonuses, handleGetAll } =
    useGeneric<AdminNamespace.AdminBonus>({
      key: KEY,
      urlSuffix: "/admin-bonus",
    });

  const {
    allWhitelists,
    handleGetAll: handleGetAllWhitelist,
    handleCreate: handleCreateWhitelist,
    handleUpdate: handleUpdateWhitelist,
    handleDelete: handleDeleteWhitelist,
  } = useWhitelist({
    key: KEY_WHITELIST,
  });

  console.log("PROJECTTTTT", project);
  const {
    resetStoreData,
    addBonus,
    getWithdraw,
    fees,
    totalSacUSD,
    address: sacAddress,
    deploySacrifice,
    getSacrificeFee,
    getProjectOwners,
    getAcceptedTokens,
    setAcceptedTokens,
    getProjectInfo,
    cancelAdminBonus,
    projectOwners,
    acceptedTokens,
    getUsdDecimals,
    initialized: proxyInitialized,
    usdDecimals,
    loadingAddToken,
    loadingDeploy,
    loadingAddress,
    currentSacProxy,
  } = useSacProxy({
    key: KEY,
    address,
    provider,
    project,
  });
  useEffect(() => {
    console.log(`acceptedTokens`, {
      acceptedTokens,
      projectOwners,
      sacAddress,
      showTokenScreen,
      project,
    });
  }, [acceptedTokens, projectOwners, sacAddress, showTokenScreen, project]);

  useEffect(() => {
    if(!id) return;
    handleGetAll({
      params: {
        ProjectId: id,
      },
      
      onFinish: (d) => console.log("State Bonuses", d),
    });
  }, [toggle1, project]);

  useEffect(() => {
    if (project.id) getProjectOwners(`${project.id}`);
  }, [project]);

  useEffect(() => {
    if (showTokenScreen) {
      if (project?.projectAddress) getAcceptedTokens(project.projectAddress);
      // getProjectInfo();
    }
  }, [showTokenScreen]);

  const loading = uiLoaders[KEY];
  const loadingWhitelist = uiLoaders[KEY_WHITELIST];

  // useEffect(() => {

  //   if(project && address && project.user && `${address}`.toLowerCase() !== project.user){
  //     console.log('PPPP', project.user, address)
  //     navigate("/projects")
  //   }
  // }, [address, project.user]);

  useEffect(() => {
    if (!id) return;
    loadUtils(id);
  }, [id, address]);

  useEffect(() => {
    if (
      currentNetwork &&
      currentSacProxy &&
      provider &&
      project &&
      proxyInitialized &&
      project.signature
    ) {
      resetStoreData({
        onFinish: () => {
          //  console.log('CURRENTET', [project.id, currentNetwork, provider, proxyInitialized, currentSacProxy]);
          //  getSacrificeContractAddress( `${project.id}`, project.contractVersion);
          getProjectOwners(`${project.id}`);
          getSacrificeFee();
          getUsdDecimals();
        },
      });
    }
  }, [project.id, currentSacProxy, toggle2]);

  useEffect(() => {
    if (chainId && provider && proxyInitialized) {
      setCurrentNetwork(resolveCurrentNetworkObject(`${chainId}`));
      // getProjectOwners(`${project.id}`);
      // getSacrificeFee();
      // // if(provider && project.id && project.projectAddress && projectOwners) {
      // //   getUsdDecimals();
      // // }
    }
  }, [chainId, proxyInitialized]);
  console.log("DECIMALS", chainId, usdDecimals);
  useEffect(() => {
    if (
      project.id &&
      projectOwners &&
      projectOwners !== EMPTY_ADDRESS &&
      proxyInitialized
    ) {
      // getAcceptedTokens();
      getProjectInfo();
      //  getSacrificeContractAddress( `${project.id}`, project.contractVersion);
      // getUsdDecimals();
    }
  }, [toggle2, projectOwners, project]);

  const handleNetworkSelect = (data: any) => {
    if (project) {
      handleUpdate(
        {
          networks: [...(project.networks ?? []), data.chainId].filter(
            (value, index, self) => {
              return self.indexOf(value) === index;
            }
          ),
        },
        `${id}`,
        {
          onFinish: () => {
            handleGet(`${id}`, {
              onFinish: () => {
                message.success("updated successfully");
              },
            });
          },
        }
      );
    }
  };

  const handleSelectAvailableNetwork = async (data: any) => {
    setSwitchingNetwork(data);
    switchNetwork?.(data.chainId)
      .then(() => {
        // getProjectOwners(`${project.id}`);
        setToggle2((old: any) => !old);
        setSwitchingNetwork(undefined);
      })
      .catch((_: any) => setSwitchingNetwork(undefined));
    // resetStoreData({
    //   onFinish: ()=>{
    //     switchNetwork?.(data.chainId).then(()=>{
    //       // setCurrentNetwork(data);
    //     })
    //   }
    // })
  };

  const handleDeploy = (_: any) => {
    if (project)
      deploySacrifice(project, {
        onFinish: () => {
          getProjectOwners(`${project.id}`);
        },
      });

    return;
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <Text>
          Fees:{" "}
          {!fees?.currentFeeFlat ? "" : toEther(fees?.currentFeeFlat ?? "")}{" "}
          {currentNetwork?.nativeToken} +{" "}
          {(Number(fees?.currentFeePercentx100 ?? "") / 100).toFixed(2)}% per
          transaction
        </Text>
      ),
      onOk() {
        if (project)
          deploySacrifice(project, {
            onFinish: () => {
              getProjectOwners(`${project.id}`);
            },
          });
      },
      okText: "Deploy",
    });
  };

  const handleNewGroupSubmit = useCallback(
    (data: any) => {
      const addresses = (data.addresses as string)
        .replace(/\s/g, "")
        .split(",");
      if (!project.id) return;
      handleCreateWhitelist(
        {
          ...data,
          ProjectId: project.id,
          addresses,
        },
        {
          onFinish: () => {
            message.success("Group Created Successfully");
            handleGetAllWhitelist({
              params: {
                projectId: project.id,
              },
            });
          },
        }
      );
    },
    [project]
  );

  console.log("ACCEPTEDTTTT", acceptedTokens);

  const handleNewTokenSubmit = useCallback(
    (data: any) => {
      console.log(`handleNewTokenSubmit`, project.id, proxyInitialized);
      if (!project.id || !proxyInitialized) return;

      setAcceptedTokens((data["token_address"] ?? "").split(","), true, {
        onFinish: () => {
          if (project.projectAddress) {
            setTimeout(() => {
              getAcceptedTokens(project.projectAddress);
            }, 2000);
          }
        },
      });
    },
    [sacAddress, proxyInitialized, chainId, project?.id]
  );

  const handleRemoveTokenSubmit = useCallback(
    (data: any) => {
      if (!project.id) return;
      // console.log(`handleNewTokenSubmit`, address)
      setSelectedTokenAddress(data.address);
      Modal.confirm({
        title: "Are you Sure",
        icon: <ExclamationCircleOutlined />,
        content: `Remove Token ${
          data.symbol === "" ? currentNetwork?.nativeToken : data.symbol
        }`,
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          setAcceptedTokens([data.address ?? ""], false, {
            onFinish: () => {
              if (project?.projectAddress)
                getAcceptedTokens(project.projectAddress);
            },
          });
        },
      });
    },
    [sacAddress]
  );

  const handleWidthdrawToken = useCallback(
    (data: any) => {
      if (!project.id) return;
      console.log({ data, currentNetwork });
      setSelectedTokenAddress(data.token);
      Modal.confirm({
        title: "Are you Sure",
        icon: <DollarCircleFilled />,
        content: `Withdraw Token ${
          data.symbol === "" ? currentNetwork?.nativeToken : data.symbol
        }`,
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          getWithdraw(`${data.token}`, {
            onFinish: () => {
              if (project?.projectAddress) {
                setTimeout(() => {
                  getAcceptedTokens(project.projectAddress);
                }, 3000);
              }
            },
          });
        },
      });
    },
    [sacAddress, project]
  );

  const handleManageGroupSubmit = useCallback(
    (data: any) => {
      console.log(`selectedGroup`, selectedGroup, data);
      if (!selectedGroup?.id) return;

      if ((selectedGroup as any).action) {
        Modal.confirm({
          title: "Are you Sure",
          icon: <ExclamationCircleOutlined />,
          content: `Remove Whitelist Bonus ${selectedGroup.name}`,
          okText: "Continue",
          cancelText: "Cancel",
          onOk: () => {
            console.log(`handleDeleteWhitelist`, selectedGroup?.id);
            handleDeleteWhitelist(`${selectedGroup?.id}`, {
              onFinish: () => {
                message.success("Group Delete Successfully");
                handleGetAllWhitelist({
                  params: {
                    projectId: project.id,
                  },
                });
              },
            });
          },
        });

        return;
      }
      handleUpdateWhitelist(data, `${selectedGroup?.id}`, {
        onFinish: () => {
          message.success("Group Updated Successfully");
          handleGetAllWhitelist({
            params: {
              projectId: project.id,
            },
          });
        },
      });
    },
    [project, selectedGroup]
  );

  const initWhitelistComponent = useCallback(() => {
    if (!project.id) return;
    handleGetAllWhitelist({
      params: {
        projectId: project.id,
      },
    });
  }, [project]);

  const handleNewVolumeBonus = useCallback(
    (data: any) => {
      if (!project.id) return;

      if (project) {
        let newBonus = project.volumeBonus ?? [];
        if (!selectedVolumeBonus) {
          newBonus = [...newBonus, data];
        }
        if (selectedVolumeBonus && selectedVolumeBonus?.index !== undefined) {
          newBonus = newBonus.map((old: any, i: number) => {
            if (selectedVolumeBonus?.index === i) return data;
            return old;
          });
        }
        handleUpdate(
          {
            volumeBonus: newBonus,
          },
          `${id}`,
          {
            onFinish: () => {
              handleGet(`${id}`, {
                onFinish: () => {
                  message.success("updated successfully");
                },
              });
            },
          }
        );
      }
    },
    [project, selectedVolumeBonus]
  );

  const loadUtils = useCallback(
    (id: string) => {
      handleGet(`${id}/chainData/${address ?? EMPTY_ADDRESS}`, {
        key: "@@ProjectUtils",
        onFinish: (data: any) => {
          setProjectUtils(data);
        },
      });
    },
    [address, handleGet]
  );

  const handleManageAdminBonus = useCallback(
    (data: any, type: string) => {
      if (type === "create") {
        const addresList = (data.addresses as string)
          .replace(/\s/g, "")
          .split(",");
        console.log(`handleManageAdminBonus`, addresList);
        addBonus(addresList, data.points, {
          onFinish: () => {
            setToggle1((old: any) => !old);
            // console.log('onFinish', 'addAdminBonus')
          },
        });
      }
      if (type === "set-selected") {
        setSelectedAdminBonus(data);
      }
      if (type === "remove-selected") {
        Modal.confirm({
          title: "Are you Sure",
          icon: <ExclamationCircleOutlined />,
          content: `Remove Admin Bonus ${shorternAddress(data?.address ?? "")}`,
          okText: "Continue",
          cancelText: "Cancel",
          onOk: () => {
            console.log(
              `(data?.address+'', data?.bonusId+'' )`,
              data?.address + "",
              data?.bonusId + ""
            );
            cancelAdminBonus(data?.address + "", data?.bonusId + "", {
              onFinish: () => {
                setSelectedAdminBonus(undefined);
                setToggle1((old: any) => !old);
              },
              onError: () => {
                setSelectedAdminBonus(undefined);
              },
            });
          },
        });
      }
    },
    [project, selectedAdminBonus]
  );

  const handleManageVolumeBonus = useCallback(
    (data: any, type: string) => {
      console.log(`handleManageVolumeBonus`, data, type);
      if (type === "set-selected") {
        setSelectedVolumeBonus(data);
      }
      if (type === "remove-selected") {
        Modal.confirm({
          title: "Are you Sure",
          icon: <ExclamationCircleOutlined />,
          content: `Remove Token Volume Bonus`,
          okText: "Continue",
          cancelText: "Cancel",
          onOk: () => {
            let newBonus: any[] = project.volumeBonus ?? [];

            newBonus = newBonus.filter(
              (_: any, i: number) => data?.index !== i
            );
            console.log({ newBonus, data });
            handleUpdate(
              {
                volumeBonus: newBonus.length ? newBonus : null,
              },
              `${id}`
            );

            setSelectedVolumeBonus(undefined);
          },
        });
      }
      return;
    },
    [project, selectedVolumeBonus]
  );

  const handleUpdatePsac = useCallback(
    (data: any) => {
      if (!project.id) return;
      handleUpdate(data, `${id}`, {
        onFinish: () => {
          handleGet(`${id}`, {
            onFinish: () => {
              message.success("Psac Saved successfully");
            },
          });
        },
      });
    },
    [project]
  );

  const loadings = {
    loading,
    loadingDeploy,
    loadingAddress,
    loadingWhitelist,
    loadingAddToken,
    uiLoaders,
  };

  const handleShowTokenScreen = () => {
    setShowTokenScreen((old) => !old);
  };

  const fns = {
    handleGet,
    handleBackArrow,
    handleNetworkSelect,
    handleSelectAvailableNetwork,
    handleDeploy,
    initWhitelistComponent,
    handleNewGroupSubmit,
    handleNewTokenSubmit,
    handleRemoveTokenSubmit,
    handleWidthdrawToken,
    handleManageGroupSubmit,
    handleManageVolumeBonus,
    handleManageAdminBonus,
    handleNewVolumeBonus,
    setSelectedGroup,
    handleShowTokenScreen,
    handleUpdatePsac,
    handleUpdate: (data:any) => {
      if (project) {
        handleUpdate(
          data,
          `${project.id}`,
          {
            onFinish: () => {
              handleGet(`${id}`, {
                onFinish: () => {
                  message.success("updated successfully");
                },
              });
            },
          }
        );
      }
    },
    loadUtils,
  };

  const dataStates = {
    project,
    currentNetwork,
    usdDecimals,
    fees,
    totalSacUSD,
    allWhitelists,
    selectedGroup,
    selectedVolumeBonus,
    selectedAdminBonus,
    projectOwners,
    acceptedTokens,
    showTokenScreen,
    selectedTokenAddress,
    adminBonuses,
    switchingNetwork,
    projectUtils,
    isOwner: `${address}`.toLowerCase() === project.user,
  };

  return (
    <Container>
      <ManageProject
        dataStates={dataStates}
        loadings={loadings}
        fns={fns}
        form={query.get("p") ?? "network"}
      />
    </Container>
  );
};
