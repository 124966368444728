import styled from "styled-components";

export const SacrificeDetailContainer = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px !important;
    gap:8px;
    width: 100%!important;
    @media (max-width: 768px) {
        width: 100%!important;
    }
    .date, .day{
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
    .date{
        color: var(--disabled-color)!important;
    }
    .total-value{
        width: 100%;
    }

    .name-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
    }
    .name{
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        margin-left: auto;
    }
    .share-button{
        margin-left: auto;
    }
    .ant-skeleton-content{
        text-align: center!important;
    }
`;
