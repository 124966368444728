import app from "./app";
import project from "./project";
import whitelist from "./whitelist";
import contract from "./contract";
import user from "./user";
import generic from "./generic";

const middlewares = [
  ...app,
  ...project,
  ...user,
  ...whitelist,
  ...contract,
  ...generic,
];

export default middlewares;
