import { GlobalToken } from "antd";
import styled from "styled-components";

export const CoverContainer = styled.div<{ token: GlobalToken }>`
  width: 100%;

  gap: 20px;
  .ant-tabs-nav {
    margin-bottom: 67px;
  }
  .ant-tabs-nav-wrap,
  .ant-tabs-nav {
    width: fit-content;
  }

  .ant-tabs-tab{
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .ant-table{
    background-color: ${({ token }) => token.colorBgContainerDisabled}!important;
  } */
  
`;

export const ConnectButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .ant-btn {
    margin-top: 20vh;
  }
`;
