import {
  Button,
  Divider,
  Form,
  InputNumber,
  List,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { StakeWrapper } from "./index.styled";
import { moneyFormat } from "../../_shared";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { toEther } from "../../web3/libs/utilities";

const { Text } = Typography;
const { Option } = Select;

interface StakeComponentProps {
  tableData?: ProjectNamespace.TableData;
  penaltyFee: string;
  loading: boolean;
  loadingContract: boolean;
  isLoadingUnstake: boolean;
  onFinish?: (values: any) => void;
  onFinishStakeMore?: (values: any) => void;
  onFinishAddDuration?: (values: any) => void;
  handleUnstake?: () => void;
}

export const StakeComponent = (props: StakeComponentProps) => {
  const {
    tableData,
    loading,
    loadingContract,
    isLoadingUnstake,
    penaltyFee,
    onFinish,
    onFinishStakeMore,
    onFinishAddDuration,
    handleUnstake,
  } = props;
  let dataSource: any[] = [];

  const hasStaked = tableData && tableData.user.amount != "0";

  if (tableData?.levels) {
    try {
      const newLevels = tableData?.levels.slice(1);
      let _counter = 0;
      while (_counter < newLevels.length) {
        let obj = {
          key: `${dataSource.length + 1}`,
          amount: newLevels[_counter].minAmount,
        };
        let columnsObj: Record<string, any> = {};
        while (
          newLevels[_counter] &&
          !columnsObj[`${newLevels[_counter].duration}`]
        ) {
          columnsObj = {
            ...columnsObj,
            [`${newLevels[_counter].duration}`]:
              Number(newLevels[_counter++].bonusPercentX100) / 100,
          };
        }
        obj = { ...obj, ...columnsObj };
        dataSource.push(obj);
        columnsObj = {};
      }
    } catch (error) {
      console.log({ error });
    }
  }

  if (!dataSource.length) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin />
      </div>
    );
  }
  var dataSourceKeys = Object.keys(dataSource[0]).filter(item=> !(item == "amount" || item == "key") );
  const maxDurationSelected = Number(tableData?.levels[Number(tableData.user.level)].duration) >= Number(dataSourceKeys[dataSourceKeys.length-1]);
  
  const columns = [
    {
      title: "Minimum Stake Amount",
      dataIndex: "amount",
      key: "amount",
      render: (item: string) => `${moneyFormat(toEther(item))} PSAC`,
    },
    ...dataSourceKeys.map((item) => {
      return {
        title: `${item} Days`,
        key: `_${item}`,
        render: (i: any) => `${i[item]} %`,
      };
    }),
  ];

  

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Button type="text" className="mt-2 w-fit mx-auto" size="large">
          Stake more PSAC
        </Button>
      ),
      children: (
        <Form
          layout="vertical"
          onFinish={onFinishStakeMore}
          autoComplete="off"
          requiredMark={"optional"}
          className="w-full"
        >
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input an amount!" }]}
          >
            <InputNumber
              placeholder="e.g Amount"
              min={1}
            />
          </Form.Item>

          <Divider style={{ marginTop: 40 }} />

          <Form.Item>
            <Button
              type="primary"
              className="mt-2  w-fit float-right"
              size="large"
              htmlType="submit"
              loading={loadingContract}
            >
              {" "}
              Stake More PSAC
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: "2",
      label: (
        <Button type="text" className="mt-2 w-fit mx-auto" size="large" disabled={maxDurationSelected}>
          Increase Stake Duration
        </Button>
      ),
      children: (
        <Form
          layout="vertical"
          onFinish={onFinishAddDuration}
          autoComplete="off"
          requiredMark={"optional"}
          className="w-full"
        >

          <Form.Item
            label={`Duration ${maxDurationSelected? "(Maximum Duration selected)":"" } `}
            name="duration"
            // requiredMark={'optional'}
            validateTrigger="onBlur"
            rules={[{ required: true, message: "Please select a duration!" }]}
          >
            <Select placeholder="Select a duration" allowClear >
              {dataSourceKeys
                .filter((item) => {
                  return  Number(item) > Number(tableData?.levels[Number(tableData.user.level)].duration);
                })
                .map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item} days
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Divider style={{ marginTop: 40 }} />

          <Form.Item>
            <Button
              type="primary"
              className="mt-2  w-fit float-right"
              size="large"
              htmlType="submit"
              loading={loadingContract}
            >
              {" "}
              Increase Stake Duration
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  const unstakePenalty = Number(tableData && penaltyFee ? ((Number(toEther(penaltyFee)) / Number(toEther(tableData?.user.amount ))) * 100).toFixed(2) : 0);
  return (
    <StakeWrapper className="max-w-[700px] mx-auto">
      <Text className="my-10 text-xl">
        Stake PSAC to earn additional bonus on all Sacrifices
      </Text>
      <Table
        className="description"
        dataSource={dataSource}
        columns={columns as any}
        pagination={false}
        loading={loading}
      />

      <Text className="my-2 text-xl">Your Stake</Text>
      {!hasStaked && (
        <>
          <Text className="my-2">Your have not staked any PSAC</Text>

          <Form
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={"optional"}
            className="w-full lg:w-1/2"
          >
            <Form.Item
              label={`Amount`}
              name="amount"
              rules={[{ required: true, message: "Please input an amount!"},
             ]}
            >
              <InputNumber
                placeholder={`Minimum of ${toEther(dataSource[0]["amount"])}`}
               min={toEther(dataSource[0]["amount"])}
              />
            </Form.Item>

            <Form.Item
              label="Duration"
              name="duration"
              // requiredMark={'optional'}
              validateTrigger="onBlur"
              rules={[{ required: true, message: "Please select a duration!" }]}
            >
              <Select placeholder="Select a duration" allowClear>
                {dataSourceKeys
                  .filter((item) => {
                    return !(item == "amount" || item == "key");
                  })
                  .map((item, index) => {
                    return (
                      <Option key={index} value={item}>
                        {item} days
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Divider style={{ marginTop: 40 }} />

            <Form.Item>
              <Button
                type="primary"
                className="mt-2 mx-auto flex mb-10"
                size="large"
                htmlType="submit"
                loading={loadingContract}
              >
                {" "}
                Stake PSAC
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      {hasStaked && (
        <>
          <List
            className="my-4"
            bordered
            dataSource={[
              {
                key: "Amount",
                value: `${moneyFormat(toEther(tableData.user.amount))} PSAC`,
              },
              {
                key: "Duration",
                value: `${
                  tableData.levels[Number(tableData.user.level)].duration
                } days`,
              },
              {
                key: "Bonus",
                value: `${
                  Number(tableData.levels[Number(tableData.user.level)].bonusPercentX100)/100
                }%`,
              },
              {
                key: "Start Date",
                value: new Date(
                  Number(tableData.user.startDate) * 1000
                ).toLocaleString(),
              },
              {
                key: "Maturity Date",
                value: new Date(
                  Number(tableData.user.endDate) * 1000
                ).toLocaleString(),
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <div className="flex justify-between w-full gap-10">
                  <Text>{item.key}</Text>
                  <Text strong className="number-font">{item.value}</Text>
                </div>
              </List.Item>
            )}
          />
          <Tabs defaultActiveKey="1" items={tabItems} />
          

          <Popconfirm
            title="Unstake"
            description={`Are you sure you want to unstake ${unstakePenalty>0 ? `with a ${unstakePenalty.toFixed(2)}% Penalty`: ''}?`}
            onConfirm={handleUnstake}
            okText="Yes"
          >
            <Button
              type="primary"
              className="my-10 w-fit mx-auto"
              size="large"
              danger
              loading={isLoadingUnstake}
            >
              {" "}
              Unstake
              {" "}
              {unstakePenalty>0 && `(${unstakePenalty.toFixed(2)}% Penalty)`}
            </Button>
          </Popconfirm>
        </>
      )}
    </StakeWrapper>
  );
};
