import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { MenuProps, Typography, theme } from "antd";
import { ShareAltOutlined, MoreOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
const { Text } = Typography;
const { useToken } = theme;
const items: MenuProps["items"] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

interface PerksSectionProps {
  projectUtils?: ProjectNamespace.UtilsData;
}
export const PerksSection = (props: PerksSectionProps) => {
  const { token } = useToken();
  const { projectUtils } = props;
  
  return (
    <div
      className="flex flex-col relative perks px-[40px] py-[33px] "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout 1 */}
      <div className="flex mb-[30px] justify-start">
        <Text className="text-lg font-semibold">Perks</Text>
      </div>

      {/* layout 2 */}
      <div className="flex justify-start flex-wrap mb-[24px] gap-4 lg:gap-[32px] ">
        {(projectUtils?.features ?? []).map((item) => (
          <div
            className="flex max-w-[156px] h-[50px] px-1 lg:px-[25px] gap-2 items-center text-center"
            style={{ backgroundColor: token.colorBgContainerDisabled }}
          >
            {item.enabled?<CheckCircleFilled style={{ color: token.colorPrimary }} />: <CloseCircleOutlined style={{ color: token.colorError }}  />} 
            <Text className="text-xs lg:text-sm">{item.label}</Text>
          </div>
        ))}
        {/* <div
          className="flex max-w-[156px] h-[50px] px-1 lg:px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">Volume Bonus</Text>
        </div>
        <div
          className="flex max-w-[156px] h-[50px] px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">Time Bonus</Text>
        </div>
        <div
          className="flex max-w-[156px] h-[50px] px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">Referral Bonus 0/10%</Text>
        </div>
        <div
          className="flex max-w-[156px] h-[50px] px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">HODL Bonus</Text>
        </div>
        <div
          className="flex max-w-[156px] h-[50px] px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">Leaderboard Bonus</Text>
        </div>
        <div
          className="flex max-w-[156px] h-[50px] px-[25px] gap-2 items-center text-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <CheckCircleFilled style={{ color: token.colorPrimary }} />
          <Text className="text-xs lg:text-sm">Time Machine</Text>
        </div> */}
      </div>
    </div>
  );
};
