import { GlobalToken } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ token: GlobalToken }>`
  display: flex !important;

  margin-bottom: 200px;
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;
  gap: 24px;
  position: relative;
  height: 504px;

  /* background-size: contain;
  background-repeat: no-repeat; */

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /* .m-point-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    } */
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
`;
