import { Col, Collapse, Row, Tabs, theme, Typography } from "antd";
import { Container } from "./index.styled";

const { Text } = Typography;
const { Panel } = Collapse;

const { TabPane } = Tabs;
const { useToken } = theme;
interface FaqTabContentProps {
  title: string;
  data: {
    question: string;
    answer: string;
  }[];
}

const faqTabContent : FaqTabContentProps[]= [
  {
    title: "GENERAL",
    data: [
      {
        question: "What does Sacrifice mean?",
        answer:
          "In simple terms it means donating to a cause without expectation of profit from the work of others.",
      },
      {
        question:
          "What is the advantage of sacrifice over other forms of fundraising models?",
        answer:
          "With sacrifice there is no expectation of value transfer in return for your donation and as such any gift received as appreciation cannot be labeled a security and is not taxable by law.",
      },
      {
        question: "What is the Howey Test?",
        answer:
          'The "Howey Test" is a test created by the Supreme Court for determining whether certain transactions qualify as "investment contracts." Following the Howey Test, sacrifices don’t qualify as securities',
      },
    ],
  },
  {
    title: "PULSESAC BASICS",
    data: [
      {
        question: "What is PulseSac?",
        answer:
          "PulseSac is the world-first comprehensive sacrifice Dapp made available to project managers and the crypto community. It helps any project raise funds from donations and equally enables crypto enthusiasts to back their favorite projects and build a healthier ecosystem.",
      },
      {
        question: "Why PulseSac?",
        answer:
          "PulseSac was created to solve the problem of each project having to first build a sacrifice dapp for fundraising. Instead our flexible dapp enables them to set up sacrifice within minutes giving them more time to concentrate on their unique ideas.",
      },
      {
        question: "Who needs PulseSac?",
        answer:
          "Developers and Project managers looking to raise funds, Crypto enthusiasts looking to support quality projects and anyone who just loves the name PulseSac.",
      },
    ],
  },
  {
    title: "PULSESAC TOKENOMICS",
    data: [
      {
        question: "What is the PSAC Token?",
        answer:
          "PSAC is a PRC20 token on pulsechain designed to power the PulseSac platform. It doubles as a utility and governance token.",
      },
      {
        question: "What is the total supply of PSAC?",
        answer:
          "There will be a total of 10,000,000 PSAC tokens only produced at genesis. This number can only be reduced in the future through burning.",
      },
      {
        question: "What is the Utility of PSAC?",
        answer: `PSAC will be used throughout the PulseSac platform. 
          Staking PSAC tokens will pay rewards from the share of platform revenue.
          Staking PSAC will be a requirement from developers looking to utilize the PulseSac Dapp.
          Top PSAC stakers will belong to the PSAC whitelist.
          PSAC will also confer voting rights on key decisions pertaining to PulseSac.
          `,
      },
      {
        question: "How do I get PSAC tokens?",
        answer:
          "You can get PSAC tokens as appreciation for donating towards development during the sacrifice phase. Thereafter PSAC will acquire value and will be listed by the community on an exchange for trading.",
      },
      {
        question: "Is there a buy/sell (transactional) tax?",
        answer: "There is NO transactional tax for swapping PSAC.",
      },
    ],
  },
  {
    title: "DEVELOPERS/PROJECT MANAGERS",
    data: [
      {
        question: "Why should I use PulseSac?",
        answer: `You can begin a sacrifice campaign in minutes with zero code.
          Multichain support with effective community exposure.
          Flexible and customizable options with Time Bonus, Volume Bonus, Referral Bonus, Whitelist Bonus and Community Task rewards.
          Comprehensive and detailed management dashboard and analytic tools.
          Affordable and flexible fee system.
          `,
      },
      {
        question: "How do I get started?",
        answer: "Click here to get started.",
      },
      {
        question:
          "How do I get better community participation in my sacrifice?",
        answer: `First you need to have a good product. Next you should be able to communicate your vision effectively. In addition, effective customization of bonuses and rewards through community tasks (all available on the PulseSac Dapp) will also help engage and motivate your audience.`,
      },
    ],
  },
  {
    title: "PSAC STAKING",
    data: [
      {
        question: "What are the benefits of staking PSAC?",
        answer: `Staking PSAC tokens will pay rewards from the share of platform revenue.
          Staking PSAC will be a requirement from developers looking to utilize the PulseSac Dapp.
          Top PSAC stakers will belong to the PSAC whitelist.
          Staking benefits everyone by reducing sell pressure and allowing for price appreciation.
          `,
      },
      {
        question: "What is the PSAC whitelist?",
        answer:
          "The PSAC whitelist is a list of Top stakers of PSAC. Addresses in the PSAC whitelist will qualify for a special whitelist bonus offered by top Projects using the PulseSac Dapp.",
      },
      {
        question: "What is the minimum and maximum stake duration?",
        answer: `There is a minimum Stake duration of 14 days and a maximum of 52 weeks. The longer the stake period, the greater the stake power.`,
      },
      {
        question: "What is Stake Power?",
        answer: `Stake power is the weighting applied to a stake based on the stake duration of the stake. Longer stakes have more stake power.`,
      },
      {
        question: "What is Stake Value?",
        answer: `Stake value is a product of the amount of PSAC tokens in a stake, the stake power and the decay. With every week that elapses in a stake period, the Stake value decreases. To maintain 100% stake value, stake period will need to be extended to the max.`,
      },
      {
        question: "What is the Unstake Penalty?",
        answer: `The unstake penalty is applied to stakes that are terminated before their due date. This penalty varies depending on when in the stake period the stake was ended.`,
      },
      {
        question: "What is the cool down period?",
        answer: `There is a 24 hour waiting period for unstaking when a stake is ended before its due date.`,
      },
    ],
  },
];
interface FaqsSectionProps {
  text?: string;
}

export const FaqsSection = (props: FaqsSectionProps) => {
  const {} = props;
  const { token } = useToken();

  const renderFaqsItems = faqTabContent.map((tabContent, key) => {
    const renderItemList = tabContent.data.map((item, k) => {
      return (
        <Col key={`${key}-${k}`} xs={24} md={24}>
          <Collapse
            defaultActiveKey={k === 0 ? ["0"] : ""}
            bordered={false}
            className="bg-transparent"
            expandIconPosition={"end"}
            style={{ width: "100%" }}
          >
            <Panel
              header={<div className="font-semibold">{item.question}</div>}
              key={key}
            >
              <p
                style={{ color: token.colorTextSecondary }}
                className="text-lg"
              >
                {item.answer}
              </p>
            </Panel>
          </Collapse>
        </Col>
      );
    });
    return (
      <TabPane
        tab={
          <div className="font-semibold text-base px-[35px]">
            {tabContent.title}
          </div>
        }
        key={key}
        // className={"ml-[70px]"}
      >
        <Row className={"pl-0 lg:pl-[70px]"} gutter={[0, 0]}>
          {renderItemList}
        </Row>
      </TabPane>
    );
  });

  return (
    <Container
      id="faq"
      token={token}
      className="gap-[32px] !mt-0 lg:!mt-[100px] lg:gap-[90px]"
    >
      <Text className="text-3xl lg:text-[60px] font-semibold whitespace-nowrap">
        Frequently Asked Questions
      </Text>
      <Tabs
        className="hidden lg:flex desktop w-full"
        defaultActiveKey="0"
        tabPosition={"left"}
      >
        {renderFaqsItems}
      </Tabs>
      <Tabs
        className="flex lg:hidden mobile w-full"
        defaultActiveKey="0"
        tabPosition={"top"}
      >
        {renderFaqsItems}
      </Tabs>
    </Container>
  );
};
