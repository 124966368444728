import {  DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox,  DatePicker, Divider, Form, Input, InputNumber, Space, Tooltip, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
// import moment from "moment";
import dayjs from 'dayjs';

import { useEffect } from "react";
import { ProjectNamespace } from "../../../../../_shared/namespaces/project";
import { FormWrapper } from "../index.styled";
const { Text } = Typography;


interface FormTwoComponentProps  {
    project: ProjectNamespace.Project
    loading: boolean
    onFinish?: ((values: any) => void) | undefined
    handlePrevious?: (() => void)
}

export const FormTwoComponent = (props: FormTwoComponentProps) => {
    const {loading, project, onFinish, handlePrevious} = props;

    const offset = dayjs().utcOffset();
    const utc = `${offset>-1?'+':''}${offset/60}`

    const [form] = useForm();
    const initialValue = {
        
        phases: project.phases,
        startDayTime: project.startDayTime?dayjs(project.startDayTime*1000):dayjs(),
        referralBonusPercent: project.referralBonusPercent,
        refereeBonusPercent: project.refereeBonusPercent,
        uplineMustBeSacrificer: project.uplineMustBeSacrificer,
        minContributionAmount: project.minContributionAmount,
    }
    
    useEffect(() => {
        form.setFieldsValue(initialValue)
    }, [project]);

    
    return (
            <FormWrapper>
                <Form
                    name="form_two"
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={initialValue}
                    requiredMark={'optional'}
                    >
                    <Form.Item>
                        <Space>
                            <span className="ant-form-text">Phases</span>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                        
                    </Form.Item>
                    <Form.List
                        name="phases"
                        rules={[
                        {
                            validator: async (_, links) => {
                            if (!links || links.length < 1) {
                                return Promise.reject(new Error('At least 1 Phase'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            
                        <>
                            {fields.map((field, index) => (
                                <Space key={field.key +index} className="social-media-items" align="center" >
                                    <Form.Item
                                        {...field}
                                        label="Points per USD Contributed"
                                        key={'pointAmount' +index}
                                        name={[field.name, 'pointAmount']}
                                        // requiredMark={'optional'}
                                        rules={[{ required: true, message: 'Please input rate' }]}
                                    >
                                        <InputNumber min={0} 
                                            parser={(value => {
                                                if(!value) return 0;
                                                return Math.floor(Number(value));
                                            })}

                                         placeholder="e.g 200" />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        label="Duration (Days)"
                                        key={'duration' +index}
                                        name={[field.name, 'duration']}
                                        // requiredMark={'optional'}
                                        rules={[{ required: true, message: 'Please input duration!' }]}
                                    >
                                        <InputNumber 
                                            min={0}
                                            parser={(value => {
                                                if(!value) return 0;
                                                return Math.floor(Number(value));
                                            })}
                                            placeholder="Enter No of Days" />
                                    </Form.Item>
                                    
                    
                                    {fields.length > 1 ? (
                                        <DeleteOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                        ) : null}
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    
                                    type="text"
                                    onClick={() => add()}
                                    style={{ marginRight: 'auto' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add another phase
                                </Button>
                            </Form.Item>
                            <div>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                        )}
                    </Form.List>
                    
                    <Form.Item
                        label={`Sacrifice Start Date/Time (UTC ${utc})`}
                        name="startDayTime"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please select date!' }]}
                    >
                        <DatePicker 
                            showNow={false}
                            disabledDate={current => {
                                // Can not select days before today and today
                                return current && current < dayjs().startOf('day');
                              }}
                            showTime/>
                    </Form.Item>

                    

                    {/* <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Upline Bonus</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="referralBonusPercent"
                        
                        rules={[
                                { required: false, message: 'Please input percentage!', },
                                {
                                    message: 'Value must be 0 - 100!',
                                    validator: async (_, value) => {
                                        if (!value || value < 0  || value > 100) {
                                            return Promise.reject(new Error('Value must be 0 - 100'));
                                        }
                                    },
                                }
                            ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Referee Bonus</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>
                            
                        </Space>}
                        
                        name="refereeBonusPercent"
                        rules={[
                            { required: false, message: 'Please input percentage!', },
                            {
                                message: 'Value must be 0 - 100!',
                                validator: async (_, value) => {
                                    if (!value || value < 0  || value > 100) {
                                        return Promise.reject(new Error('Value must be 0 - 100'));
                                    }
                                },
                            }
                        ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        name="uplineMustBeSacrificer"
                        // requiredMark={'optional'}
                        valuePropName="checked"
                    >
                        <Checkbox defaultChecked={false}>Upline must be a previous sacrificer</Checkbox>
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Minimum Contribution Amount</Text>
                            <Tooltip title="Extra information">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="minContributionAmount"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input amount!',  },
                                {
                                    validator: async (_, value) => {
                                        console.log(`Please input amount must be 0 or more!`, value)
                                        if (!value || value < 0) {
                                            return Promise.reject(new Error('Please input amount must be 0 or more'));
                                        }
                                    },
                                }]}
                        
                    >
                        <Input type={"number"} min="0" placeholder="Enter Amount" suffix="USD" />
                    </Form.Item> */}

                    <Divider style={{marginTop: 40}} />

                    

                    <Form.Item>
                        <Button onClick={handlePrevious} shape="round" className="prev-btn" htmlType="button">
                            Previous
                        </Button>
                        <Button loading={loading} shape="round" className="finish-btn" type="primary" htmlType="submit">
                            Save Sacrifice Settings
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
    );
  };    