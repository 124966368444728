import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const DELETE_WHITELIST = createActionType(
  "DELETE_WHITELIST",
  "WHITELIST"
);

export const deleteWhitelist = (id: string, options?: ActionOption) => ({
  type: DELETE_WHITELIST.START,
  meta: {
    ...options,
    id,
  },
});
