
import { Button} from "antd";
import { ConnectButtonWrapper} from "../index.styled";

interface ConnectButtonProps {
  handleConnect: ()=>void
  loading: boolean
}

export const ConnectButton = (props: ConnectButtonProps) => {
  const {handleConnect, loading} = props;
  return (
    <ConnectButtonWrapper >
        <Button loading={loading} onClick={handleConnect} type="primary"  shape="round">Connect Wallet</Button>
    </ConnectButtonWrapper>
  );
};