import { GlobalToken } from "antd";
import styled, { keyframes } from "styled-components";

const flashAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
`;

export const FlashHeader = styled.header<{ token: GlobalToken }>`
  background-color: ${({ token }) => {
    return `${token.colorBorderBg}!important`;
  }};
  overflow: hidden;
  .flex-content {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: visible;
    gap: 80px;

    transform: translateX(-100%);
    animation: ${flashAnimation} 100s linear infinite;
    animation-duration: 100000ms;
    padding: 16px;
    .ant-typography {
      white-space: nowrap;
      background: linear-gradient(to right, #eee, #333);
      background: ${({ token }) => {
        return `linear-gradient(to right, ${token.colorPrimary}, ${token.colorErrorActive})`;
      }};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const StyledHeader = styled.header<{ token: GlobalToken }>`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px !important;
  flex-wrap: wrap;
  /* padding-left: 80px !important;
  padding-right: 80px !important; */
  /* box-shadow: 0px 1px 0px var(--border); */

  box-shadow: ${({ token }) => {
    
    return `0px 1px 0px ${token.colorBorderBg}`;
  }};
  .ant-input {
    width: 20vw;
  }
  .ant-form-item{
    margin-bottom: 0px;
  }
  .create-btn{
    span {
      
      /* background: linear-gradient(to right, #eee, #333); */
      background: ${({ token }) => {
        return `linear-gradient(to right, ${token.colorPrimary}, ${token.colorText}, ${token.colorPrimary})`;
      }};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .disconnect-wallet {
    color: ${({ token }) => {
      
      return `${token.colorText}!important`;
    }};
  }
  /* overflow-x: scroll; */

  .__logo-text {
    font-family: "Urbanist", sans-serif;
    font-weight: 600 !important;
    font-size: 20px !important;
  }

  .logo-container {
    display: flex;
    gap: 8px;
    align-items: center;
    .menu-button {
      display: none;
    }
    .ant-typography {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      white-space: nowrap;
    }
  }

  .ant-switch-checked {
    background: var(--accent) !important;
  }
  a {
    font-family: ${({ token }) => token.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: ${({ token }) => token.colorText};
  }

  .connect-container {
    display: flex;
    align-items: center;
    gap: 24px;
    // span {
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 24px;
    // }
  }

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /*  */
  }
  @media (max-width: 768px) {
    /*  */
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
    /* .connect-container .connect-wallet, .disconnect-wallet, .__logo-text{
            display: none !important;
        } */
    .connect-container .create-project,
    .__logo-text {
      display: none !important;
    }
    .logo-container .menu-button {
      display: block;
    }
  }
`;
