import { Action } from "../../types";
import { GENERIC } from "../../actions";
import { arrayToById } from "../../../_shared/utils";

export interface GenericReducerState {
  allGenerics: Record<string, unknown[]>;
  genericsById: Record<string, Record<string, unknown>>;
  generic: Record<string, unknown>;
}

export const GenericDefaultState: GenericReducerState = {
  allGenerics: {},
  genericsById: {},
  generic: {},
};

const GenericReducer = (state = GenericDefaultState, action: Action) => {
  if (action.type === GENERIC.SUCCESS) {
    if (action.update) {
      const allGenerics = state.allGenerics[action.key] ?? [];

      const index = allGenerics.findIndex(
        (o: any) => o?._id === action.payload?._id
      );

      let currentArist = {};

      if (index !== -1) {
        currentArist = Object.assign({}, allGenerics[index], action.payload);
        allGenerics[index] = Object.assign(
          {},
          allGenerics[index],
          action.payload
        );
      } else {
        currentArist = Object.assign({}, action.payload);
        allGenerics.push(action.payload);
      }

      const byId = arrayToById(allGenerics ?? []);

      return {
        ...state,
        genericsById: {
          ...state.genericsById,
          [action.key]: byId,
        },
        allGenerics: {
          ...state.allGenerics,
          [action.key]: allGenerics ?? [],
        },
        generic: {
          ...state.generic,
          [action.key]: currentArist,
        },
      };
    }

    if (!action.list) {
      return {
        ...state,
        generic: {
          ...state.generic,
          [action.key]: action.payload,
        },
      };
    }
    if (action.list) {
      const byId = arrayToById(action?.payload ?? []);
      return {
        ...state,
        genericsById: {
          ...state.genericsById,
          [action.key]: byId,
        },
        allGenerics: {
          ...state.allGenerics,
          [action.key]: action.payload ?? [],
        },
      };
    }
  }
  return state;
};

export default GenericReducer;
