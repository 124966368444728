import {
  CheckCircleOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Progress,
  Space,
  Tag,
  Timeline,
  Typography,
  theme,
} from "antd";
import { ShareAltOutlined, MoreOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { toEther } from "../../../web3/libs/utilities";
import {
  computeCurrentPhaseEndDate,
  computeEndDate,
  moneyFormat,
} from "../../../_shared";
const { Text } = Typography;
const { useToken } = theme;
const items: MenuProps["items"] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

interface ProjectDescriptionSectionProps {
  project?: ProjectNamespace.Project;
  sacAddress?: string;
}
export const ProjectDescriptionSection = (
  props: ProjectDescriptionSectionProps
) => {
  const { token } = useToken();
  const { project, sacAddress } = props;
  const phases = project?.phases ?? [];
  const currentPhase = computeCurrentPhaseEndDate(
    project?.startDayTime ?? 0,
    phases
  );
  const index = currentPhase[1] + 1;
  const percent = (index / phases.length) * 100;

  return (
    <div
      className="flex flex-col relative project-description px-[40px] py-[33px] "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout 1 */}
      <div className="flex mb-[12px] justify-start">
        <Text className="text-lg font-semibold">Project Description</Text>
      </div>

      {/* layout 2 */}
      <div className="flex justify-start mb-[24px]">
        <Text className="text-sm" disabled>
          {project?.description}
        </Text>
      </div>

      {/* layout3 */}
      <div className="flex flex-col mb-[40px] justify-between gap-[2px]">
        <div
          className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  "
          style={{ borderColor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Text className="" disabled>
            Sacrifice Address
          </Text>
          <Paragraph copyable style={{ color: token.colorPrimary }}>
            {project?.projectAddress}
          </Paragraph>
        </div>

        <div
          className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  "
          style={{ borderColor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Text className="" disabled>
            Total Raised Value
          </Text>
          <Text className="number-font">
            $
            {moneyFormat(
              toEther(String(project?.totalSacrificedValue ?? 0), 2),
              2
            )}
          </Text>
        </div>

        {/* <div className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  " style={{ borderColor: token.colorTextDisabled }}>
          <Text className="" disabled>Contributors</Text>
          <Text className="" >$9,906.33</Text>
        </div> */}

        <div
          className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  "
          style={{ borderColor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Text className="" disabled>
            Sacrifice Progress
          </Text>
          <Progress
            className=""
            steps={phases.length}
            percent={percent}
            strokeColor={token.colorPrimary}
            showInfo={false}
          />
        </div>

        <div
          className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  "
          style={{ borderColor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Text className="" disabled>
            Sacrifice Start Date
          </Text>
          <Text className="">
            {new Date((project?.startDayTime ?? 1) * 1000).toDateString()}
          </Text>
        </div>
        <div
          className="flex gap-2 py-4 items-center justify-between  border-solid border-0 border-b-[1px]  "
          style={{ borderColor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Text className="" disabled>
            Sacrifice End Date
          </Text>
          <Text className="">
            {new Date(
              computeEndDate(project?.startDayTime ?? 1, project?.phases ?? [])
            ).toDateString()}
          </Text>
        </div>
      </div>
    </div>
  );
};
