import * as React from "react"

function Bonus(props: any) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.48 14.71a2.31 2.31 0 00-3.09-1L15 15.38A2.5 2.5 0 0012.5 13H8a.938.938 0 00-.37.07l-5 2A1 1 0 002 16v5a1 1 0 001 1h10a1 1 0 00.51-.14l7.34-4.34a.7.7 0 00.19-.15 2.3 2.3 0 00.44-2.66zm-1.8 1.18L12.73 20H4v-3.32L8.19 15h4.31a.5.5 0 010 1H10a1 1 0 100 2h4a1 1 0 00.45-.11l4.84-2.41a.29.29 0 01.428.271.31.31 0 01-.038.139zM9.09 7.82l-.35 2a1 1 0 00.4 1 1 1 0 001 .08l1.81-1 1.81 1c.144.07.3.107.46.11a1 1 0 00.936-.632 1 1 0 00.054-.558l-.35-2 1.47-1.43a1 1 0 00-.56-1.71l-2-.29-.9-1.83a1 1 0 00-1.8 0l-.9 1.83-2 .29a1 1 0 00-.56 1.71l1.48 1.43zM11 6.29a1 1 0 00.75-.54l.25-.49.24.49a1 1 0 00.75.54l.54.08-.39.38a1 1 0 00-.29.89l.1.53-.48-.25a1 1 0 00-.94 0l-.48.25.1-.53a1.001 1.001 0 00-.29-.89l-.39-.38.53-.08z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Bonus;
