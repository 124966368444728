import { theme, Typography } from "antd";
// import Link from "next/link";
import { Container } from "./index.styled";

const { useToken } = theme;
const { Text } = Typography;

interface CommunitySectionProps {}

export const CommunitySection = (props: CommunitySectionProps) => {
  const {} = props;

  const { token } = useToken();

  return (
    <Container token={token}>
      <div className="community-bg  bg-contain bg-no-repeat bg-center h-full w-full flex flex-col justify-center items-center gap-[50px] bg-[#312e48] rounded-[18px] px-8">
        <Text className="text-[20px] w-[70%] lg:text-[3.5em] font-semibold text-center">
          Stay Updated. Join Our Online Community Today!
        </Text>
        <Text className="text-center hidden text-base font-medium md:text-lg w-[60%]">
          Lorem ipsum dolor sit amet consectetur. Sit condimentum nisl nec sit.
          Viverra adipiscing urna lorem purus quis massa odio. Facilisi etiam
          viverra commodo.
        </Text>
        <div className="flex gap-6">
        <a
              rel="noreferrer"
              href="https://twitter.com/pulsesac_dapp"
              target="_blank"
            >
              <img src="/logos/fa_twitter.svg" width={40} alt="" />
            </a>
            <a
              rel="noreferrer"
              href="https://t.me/PulseSac"
              target="_blank"
            >
              <img src="/logos/fa_telegram.svg" width={40} alt="" />
            </a>
            <a
              rel="noreferrer"
              href="https://www.reddit.com/r/pulsesac/"
              target="_blank"
            >
              <img src="/logos/fa_reddit.svg" width={40} alt="" />
            </a>
            <a
              rel="noreferrer"
              href="https://medium.com/@pulsesac"
              target="_blank"
            >
              <img src="/logos/fa_mail.svg" width={40} alt="" />
            </a>
        </div>
      </div>
    </Container>
  );
};
