import { ActionOption } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/state";
import { useEffect } from "react";
import { getGeneric } from "../../redux/actions";
import { GET, PUT } from "../../_shared";

interface UseTransaction<T> {
  data: T;
  dataList: T[];
  handleUpdate: (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => void;
  handleGetAll: (options?: ActionOption) => void;
  handleGet: (
    id: string,
    options?: ActionOption,
    payload?: Record<string, any>
  ) => void;
}

interface UseGenericProps {
  key: string;
  urlSuffix: string;
  autoFetch?: boolean;
  options?: ActionOption;
}

export const useGeneric = <T>(
  parameter: UseGenericProps
): UseTransaction<T> => {
  const {
    key,
    urlSuffix,
    autoFetch = false,
    options: defaultOptions = {},
  } = parameter;
  const dispatch = useDispatch();

  const { data, dataList } = useSelector((state: RootState) => {
    return {
      data: (state.generic.generic[key] ?? {}) as T,
      dataList: (state.generic.allGenerics[key] ?? []) as T[],
    };
  });

  const handleUpdate = (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => {
    dispatch(
      getGeneric(
        {
          key: key,
          urlSuffix,
          method: PUT,
          suffix: `/${id}${options?.suffix}`,
          ...defaultOptions,
          ...options,
        },
        payload
      )
    );
  };

  const handleGetAll = (options?: ActionOption) => {
    dispatch(
      getGeneric({
        key: key,
        urlSuffix,
        method: GET,
        list: true,
        ...defaultOptions,
        ...options,
      })
    );
  };

  const handleGet = (
    id: string,
    options?: ActionOption,
    payload?: Record<string, any>
  ) => {
    dispatch(
      getGeneric(
        {
          key: key,
          urlSuffix,
          method: GET,
          list: false,
          suffix: `/${id}${options?.suffix ?? ""}`,
          ...defaultOptions,
          ...options,
        },
        payload
      )
    );
  };

  useEffect(() => {
    if (autoFetch) {
      handleGetAll({
        key: key,
      });
    }
  }, [autoFetch]);

  return {
    data,
    dataList,

    handleUpdate,
    handleGetAll,
    handleGet,
  };
};
