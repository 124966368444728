import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, theme, Typography } from "antd";
import { Container } from "./index.styled";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { useToken } = theme;

interface MainFooterProps {
  text?: string;
}
export const MainFooter = (props: MainFooterProps) => {
  const {} = props;
  const { token } = useToken();
  const navigate = useNavigate();
  return (
    <Container token={token} className={"!bg-gray-sec-500"}>
      {/* <JoinSection /> */}
      <div className="flex flex-col ">
        <div className="flex justify-between flex-wrap  p-[54px]">
          <div className="flex flex-col gap-2">
            <div className="flex gap-4 items-center">
              <img src="/pulsesac_logo.svg" alt="...." className="w-[60px]" />
              <Text className="__logo-text">PulseSac</Text>
            </div>
            <div className="flex gap-6 flex-col md:flex-row">
              {/* <Button
                ghost
                className="create-btn !border-white"
                onClick={() => {
                  navigate("https://pulsesac.com/");
                }}
              >
                Sacrifice Now
              </Button> */}
              <a rel="noreferrer" href="/">
                Projects
              </a>
              <a rel="noreferrer" href="/#features">
                Features
              </a>
              <a rel="noreferrer" href="/#pricing">
                Pricing
              </a>
              <a rel="noreferrer" href="/#psac-token">
                PSAC Token
              </a>
              <a rel="noreferrer" href="/#faq">
                FAQs
              </a>
              <a rel="noreferrer" href="/faucet">
                Faucet
              </a>
              <a rel="noreferrer" href="/stake">
                Stake
              </a>
            </div>
          </div>

          <div className="flex flex-col  items-end max-md:hidden  gap-10">
            <Text>
              Got questions? Connect with us through our social media handles
            </Text>
            <Form
              name="form_one"
              layout="vertical"
              // form={form}
              // onFinish={onFinish}
              autoComplete="off"
              requiredMark={"optional"}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <div className="flex gap-4">
                  <a
                    rel="noreferrer"
                    href="https://twitter.com/pulsesac_dapp"
                    target="_blank"
                  >
                    <img src="/logos/fa_twitter.svg" width={30} alt="" />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://t.me/PulseSac"
                    target="_blank"
                  >
                    <img src="/logos/fa_telegram.svg" width={30} alt="" />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://www.reddit.com/r/pulsesac/"
                    target="_blank"
                  >
                    <img src="/logos/fa_reddit.svg" width={30} alt="" />
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://medium.com/@pulsesac"
                    target="_blank"
                  >
                    <img src="/logos/fa_mail.svg" width={30} alt="" />
                  </a>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Divider />
        <div className="flex justify-between !text-[14px] sublist py-[20px] px-[54px] flex-wrap">
          <div className="flex gap-0  max-md:hidden items-center">
            <a
              rel="noreferrer"
              href="/projects"
              
            >
              PSAC Sacrifice
            </a>
            <Divider type="vertical" />
            <a rel="noreferrer" href="/#features">
              Features
            </a>
            <Divider type="vertical" />
            <a
              rel="noreferrer"
              href="/#pricing"
            >
              Pricing
            </a>
            <Divider type="vertical" />
            <a
              rel="noreferrer"
              href="/#psac-token"
            >
              PSAC Token
            </a>
            <Divider type="vertical" />
            <a
              rel="noreferrer"
              href="/#faq"
            >
              FAQs
            </a>
            {/* <Divider type="vertical" />
            <a
              rel="noreferrer"
              href="https://medium.com/@pulsesac"
              target="_blank"
            >
              Info
            </a> */}
          </div>
          <Text style={{ color: token.colorTextSecondary }} className="">
            Copyright © 2023 Pulsesac. All rights reserved.
          </Text>
        </div>
      </div>
    </Container>
  );
};
