import { Middleware } from "redux";
import { RootState } from "../../../state";
import { serverRequest } from "../../../actions/app/api";
import { APP_URL, PATCH, PUT } from "../../../../_shared/constants";
import { UPDATE_WHITELIST } from "../../../actions";

export const updateWhitelist: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_WHITELIST.START) {
      const { id, payload, onFinish, ...rest } = action.meta;
      dispatch(
        serverRequest({
          method: PATCH,
          url: `${APP_URL.whitelist.update}/${id}`,
          onFinish,
          payload,
          onSuccess: UPDATE_WHITELIST.SUCCESS,
          ...rest,
        })
      );
    }
  };
