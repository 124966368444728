import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-height: 330px;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: -200px;
    padding-left: 120px ;
    padding-right: 120px ;
    z-index: 1;
    
    

    .main-join-container{
        background-color: var(--landing-background-4);
        max-height: 400px; 
        overflow: hidden;

        .left{
            padding: 70px 0px 70px 85px ;
            width: 100%;
        }

        .right{
            width: 50%;
            min-height: 100%;
            overflow: hidden;
            img{
                width: 100% ;
            }
            /* background-image: url("./stripe.svg");  */
            /* Used if the image is unavailable */
            /* background-position: center; 
            background-repeat: no-repeat;
            background-size: cover; */
        }
        
        
    }

    
    
    .title-container{
        max-width: 393px;
        button{
            background: linear-gradient(102.33deg, #11998E -6.44%, #38EF7D 116.66%);
            border: 0px;
        }
    }
    h1.ant-typography{
        color: var(--text-color-inverse)!important;
    }
    
    
    @media (max-width: 1439px) {
        .main-join-container{
            .right{
                img{
                    width: 130% ;
                }
            }
        }
    }

    
    @media (max-width: 968px) {
        /*  */
        .main-join-container{
            .right{
                display: none!important;
            }
        }
    }
    @media (max-width: 768px) {
        /*  */
        .main-join-container{
            .right{
                display: none!important;
            }
        }
    }
    @media (max-width: 425px) {
        padding-left: 16px ;
        padding-right: 16px ;
        .main-join-container{
            .left{
                padding: 40px 16px;
                .ant-typography{
                    text-align: center;
                    /* font-size: 24px; */
                }
                .title-container .ant-space-item{
                    display: flex ;
                }
                button{
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .right{
                display: none!important;
            }
        }
       
    }
    
`;