import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { App as _App } from "antd";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { ThemeContextProvider, WalletConnectorProvider } from "./contexts";
import { ConfigProvider, theme } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeContextProvider>
        <WalletConnectorProvider>
          <ConfigProvider
            theme={{
              algorithm: theme.darkAlgorithm,
              token: {
                colorPrimary: "#9008D7",
                colorSuccess: "#1C9400",
                colorError: "#FF2222",
                colorBgBase: "#161C24",
                colorText: "#FFFFFF",
                fontFamily: "'Mazzard Light', sans-serif",
                borderRadius: 6,
                colorBgContainerDisabled: "#2E323C",
                colorBorder: "rgba(255, 255, 255, 0.05)",
                colorBorderSecondary: "rgba(255, 255, 255, 0.05)",
                colorSplit: "rgba(255, 255, 255, 0.05)",
                colorBgContainer: "#22272f",
              },
            }}
          >
            <_App>
              <App />
            </_App>
          </ConfigProvider>
        </WalletConnectorProvider>
      </ThemeContextProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
