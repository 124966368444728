import styled from "styled-components";

export const StakeWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 4px !important;
  gap: 8px;
  width: 100%;
  flex-direction: column;

  .description {
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
  }

  .ant-tabs-tab {
    background: transparent !important;
    border: 0 !important;
  }
`;
