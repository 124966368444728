import { ShareModal } from "../view-project/libs/modal/share";
import { WrapperContainer } from "./index.styled";
import { theme } from "antd";
import { BannerSection } from "./libs/banner";
import { MainLayout } from "./libs/layout";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { NETWORKS_TYPE } from "../../_shared";
import { AcceptedToken, UserDataStat } from "../../_shared/utils/type";
import { useSacrifice } from "../../hooks/useSacrifice";

const { useToken } = theme;
interface SacrificeComponentV2Props {
  project?: ProjectNamespace.Project | any;
  sacrificePhaseDay?: string;
  pointRate?: string;
  sacAddress?: string;
  donationPoints?: string;
  currentNetwork?: NETWORKS_TYPE;
  onComplete: () => void;
  handleShareButton: () => void;
  handleFollow: (action: string | undefined) => void;
  handleVote: (votingCost: string) => void;
  address?: string;

  handleSwitchNetwork: (any?: any) => void;

  provider: any;
  usdDecimals: number;
  // handleAmountNetworkChange: (chaindId: string,amount: string, referalAddress?:string)=>void
  handleOnFinishSacrifice: (data?: any) => void;

  shareData: {
    visible: boolean;
    onClose?: () => void;
    onCopied?: (data?: any) => void;
    text?: string;
  };
  acceptedTokens?: AcceptedToken[];
  userDataStats?: UserDataStat;
  // bonusData?: GetBonusStat
  loading: boolean;
  loadingNetworkSwitch: boolean;
  deployToggle: boolean;
  uiLoaders: Record<any, boolean>;
  projectUtils?: ProjectNamespace.UtilsData
}
export const SacrificeComponentV2 = (props: SacrificeComponentV2Props) => {
  const { token } = useToken();
  const {
    project,
    sacAddress,
    sacrificePhaseDay,
    pointRate,
    handleShareButton,
    handleFollow,
    handleVote,
    onComplete,
    handleSwitchNetwork,
    userDataStats,
    currentNetwork,
    loadingNetworkSwitch,
    deployToggle,
    acceptedTokens,
    uiLoaders,
    usdDecimals,
    handleOnFinishSacrifice,
    provider,
    address,
    projectUtils
  } = props;
  const {
    sacData,
    onSacrifice,
    referralError,
    tokenBalance,
    amountError,
    bonusData,
    loadingBonuses,
    handleAmountNetworkChange,
  } = useSacrifice({
    key: "@@KEY",
    sacAddress,
    project,
    handleOnFinishSacrifice,
    provider,
    usdDecimals,
  });
  return (
    <WrapperContainer token={token}>
      <BannerSection
        project={project}
        uiLoaders={uiLoaders}
        handleShareButton={handleShareButton}
        handleFollow={handleFollow}
        handleVote={handleVote}
        projectUtils={projectUtils}
        currentNetwork={currentNetwork}
      />
      <MainLayout
        project={project}
        sacAddress={sacAddress}
        address ={address}
        sacrificePhaseDay={sacrificePhaseDay}
        pointRate={pointRate}
        onComplete={onComplete}
        handleSwitchNetwork={handleSwitchNetwork}
        userDataStats={userDataStats}
        currentNetwork={currentNetwork}
        loadingNetworkSwitch={loadingNetworkSwitch}
        deployToggle={deployToggle}
        handleChange={handleAmountNetworkChange}
        handleOnFinish={onSacrifice}
        loading={loadingBonuses}
        sacData={sacData}
        acceptedTokens={acceptedTokens} 
        uiLoaders={uiLoaders}
        amountError={amountError}
        bonusData={bonusData}
        referralError={referralError}
        tokenBalance={tokenBalance}
        usdDecimals={usdDecimals}
        projectUtils={projectUtils}
        />
    </WrapperContainer>
  );
};
