import { Middleware } from "redux";
import { RootState } from "../../../state";
import { serverRequest } from "../../../actions/app/api";
import { APP_URL, DELETE } from "../../../../_shared/constants";
import { DELETE_WHITELIST } from "../../../actions";

export const deleteWhitelist: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === DELETE_WHITELIST.START) {
      const { id, payload, onFinish, ...rest } = action.meta;
      dispatch(
        serverRequest({
          method: DELETE,
          url: `${APP_URL.whitelist.delete}/${id}`,
          onFinish,
          payload,
          onSuccess: DELETE_WHITELIST.SUCCESS,
          ...rest,
        })
      );
    }
  };
