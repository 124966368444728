import {
  Avatar,
  Button,
  Form,
  Input,
  List,
  Pagination,
  Space,
  Table,
  Typography,
} from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";

import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { ProjectItem } from "../../utils";
import { ColumnsType } from "antd/es/table";
import { computeEndDate, moneyFormat } from "../../../_shared";
import { toEther } from "../../../web3/libs/utilities";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

interface ProjectTabProps {
  projectList: ProjectNamespace.Project[];
  loading: boolean;
  pagination: {
    totalCount: number;
    perPage: number;
    current: number;
    next?: number;
  };
  useDonation?: boolean;
  showManage?: boolean;
  showStartDate?: boolean;
  showEndDate?: boolean;
  showAmountRaised?: boolean;
  handleSearchChange: (data: any, key?: any) => void;
  onPaginationChange: (page: number, pageSize: number) => void;
}

export const ProjectTab = (props: ProjectTabProps) => {
  const {
    projectList,
    loading,
    pagination,
    useDonation = false,
    showManage = false,
    showStartDate = true,
    showEndDate = true,
    showAmountRaised = true,
    handleSearchChange,
    onPaginationChange,
  } = props;
  const navigate = useNavigate();

  const columns: ColumnsType<ProjectNamespace.Project> = [
    {
      title: "Project Neme",
      dataIndex: "name",
      key: "name",
      render: (_, project) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => navigate(`/sac/${project.id}`)}
          >
            <List.Item.Meta
              className="flex gap-4 items-center"
              avatar={<Avatar src={project.logo} shape="square" size={38} />}
              title={<Text>{project.title}</Text>}
            />
          </div>
        );
      },
    },
    ...(showStartDate
      ? [
          {
            title: "Start Date",

            key: "start_date",
            render: (_: any, project: any) => {
              return (
                <Text className="disable">
                  {new Date(project.startDayTime * 1000).toDateString()}
                </Text>
              );
            },
          },
        ]
      : []),

    ...(showEndDate
      ? [
          {
            title: "End Date",

            key: "end_date",
            render: (_: any, project: any) => {
              return (
                <Text className="disable">
                  {new Date(project.endDayTime * 1000).toDateString()}
                </Text>
              );
            },
          },
        ]
      : []),

    ...(showAmountRaised
      ? [
        {
          title: "Amount Raised",
    
          key: "amount_raised",
          render: (_:any, project:any) => {
            let totalSacrificedValue = "0";
            try {
              totalSacrificedValue = moneyFormat(
                toEther(project.totalSacrificedValue ?? "0")
              );
            } catch (error) {
              // console.log(`totalSacrificedValue`, totalSacrificedValue, item)
            }
            return <Text>${totalSacrificedValue}</Text>;
          },
        },
    
        ]
      : []),

    
    {
      title: "Amount Sacrified",

      key: "amount_raised",
      render: (_, project) => {
        let totalSacrificedValue = "0";
        try {
          totalSacrificedValue = moneyFormat(
            toEther(project.donation?.totalContributed ?? "0")
          );
        } catch (error) {
          // console.log(`totalSacrificedValue`, totalSacrificedValue, item)
        }
        return <Text>${totalSacrificedValue}</Text>;
      },
    },
    ...(useDonation
      ? [
          {
            title: "Points Earned",

            key: "amount_raised",
            render: (_: any, project: any) => {
              let totalPoints = "0";
              try {
                totalPoints = moneyFormat(project.donation?.totalPoints ?? 0);
              } catch (error) {
                // console.log(`totalSacrificedValue`, totalSacrificedValue, item)
              }
              return <Text>{totalPoints}</Text>;
            },
          },
        ]
      : []),
    {
      title: " ",

      key: "action",
      render: (_, project) => {
        if (!showManage) return <></>;
        return (
          <Space>
            <Button
              onClick={() => navigate(`/projects/${project.id}/manage`)}
              type="primary"
              ghost
              icon={<EditOutlined />}
            >
              Manage Project
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Form
        name="horizontal_form"
        layout="inline"
        className="mb-[67px]"
        onFinish={(data) => {
          console.log({ data });
          handleSearchChange(data.username);
        }}
      >
        <Form.Item className="search-form-item w-[494px]" name="username">
          <Input
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
            placeholder="Search Project Name"
            prefix={<SearchOutlined className="mr-[18px]" />}
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="h-[56px] w-[156px]"
          disabled={loading}
        >
          Search
        </Button>
      </Form>
      <Table
        loading={loading}
        dataSource={projectList}
        columns={columns}
        pagination={{
          showTotal: (total, range) => (
            <Text>
              Showing {range[0]}-{range[1]} of {total} projects
            </Text>
          ),
          showSizeChanger: true,
          pageSizeOptions: [1, 2, 10, 50, 100],
          defaultPageSize: 20,
          defaultCurrent: 1,
          disabled: loading,
          current: pagination?.current ?? 0,
          pageSize: pagination?.perPage ?? 0,
          total: pagination?.totalCount ?? 0,
          onChange: onPaginationChange,
        }}
      />
    </>
  );
};
