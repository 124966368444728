import { GlobalToken } from "antd";
import styled from "styled-components";

export const Container = styled.div<{ token: GlobalToken }>`
  /* background: var(--landing-background); */
  display: flex !important;
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: space-between; */
  margin-top: 100px;
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;
  gap: 24px;
  position: relative;
  margin-bottom: 200px;

  .ant-pagination {
    display: flex;
    .ant-pagination-total-text {
      margin-right: auto;
    }
  }
  .ant-progress-steps-item {
    border-radius: 6px;
  }
  .ant-table-content {
    overflow: auto;
  }

  table {
    border: 1px inset ${({ token }) => token.colorPrimary}!important;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 0px!important;
  }
  thead, tbody,
  tr {
    border-radius: 0px!important;
    
    td, th {
      border-top: 0px solid ${({ token }) => token.colorBgContainer}!important;
      border-bottom: 0px solid ${({ token }) => token.colorBgContainer}!important;
      border-right: 1px solid ${({ token }) => token.colorPrimary}!important;
      background-color: ${({ token }) => token.colorBgContainerDisabled}!important;
      border-radius: 0px!important;
      text-align: center!important;
      
    }
    th {
      border-bottom: 1px solid ${({ token }) => token.colorPrimary}!important;
    }
  }

  

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    /* .m-point-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    } */
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
  }
`;
