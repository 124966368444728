import { ViewMyProjectsContainer } from "../../container";


const MyProjectsPage = () => {
  
    
  return <ViewMyProjectsContainer/>
}

export default MyProjectsPage
MyProjectsPage.renderClassName = "__app_secondary";