import * as React from "react"

function Management(props: any) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentcolor">
        <path d="M21 12.75v3.75H3v-12h7.5V3H3a1.5 1.5 0 00-1.5 1.5v12A1.5 1.5 0 003 18h6v3H6v1.5h12V21h-3v-3h6a1.5 1.5 0 001.5-1.5v-3.75H21zM13.5 21h-3v-3h3v3z" />
        <path d="M22.5 7.5V6h-1.576a3.725 3.725 0 00-.549-1.315l1.118-1.117-1.06-1.06-1.118 1.117A3.726 3.726 0 0018 3.075V1.5h-1.5v1.576c-.47.096-.916.283-1.315.549l-1.117-1.118-1.06 1.06 1.117 1.118A3.727 3.727 0 0013.575 6H12v1.5h1.576c.096.47.283.916.549 1.315l-1.118 1.117 1.06 1.06 1.118-1.117c.4.266.845.453 1.315.55V12H18v-1.576c.47-.096.916-.283 1.315-.549l1.117 1.118 1.06-1.06-1.117-1.118c.266-.4.453-.846.55-1.315H22.5zM17.25 9a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5z" />
      </g>
    </svg>
  )
}

export default Management
