import { StepsWrapper } from "./index.styled";

interface StepComponentProps  {
    length: number;
    currentIndex: number;
}

export const StepComponent = (props: StepComponentProps) => {
    const {length, currentIndex} = props;
    const steps = [];
    for (let index = 0; index < length; index++) {
        steps.push(
            <svg key={index} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle className={index== currentIndex? 'active-circle':'circle'} cx="5" cy="5" r="5" fill="#ffffff"/>
            </svg>
        );
        
    }
    return (
            <StepsWrapper>
                {steps}
            </StepsWrapper>
    );
  };    