import { Button, Space, Typography } from "antd";
import { ExtraFeaturesComponent } from ".";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { ModalWrapper } from "../../view-project/libs/modal/index.styled";

interface ExtraFeatureModalProps{
    project: ProjectNamespace.Project,
    visible: boolean
    onFinish?: (() => void) | undefined
    
    onClose?: () => void
    loading: boolean
    loadUtils: (id: string)=>void
   // currentNetwork: NETWORKS_TYPE;
}
export const ExtraFeatureModal = (props: ExtraFeatureModalProps) => {
    const {visible, loadUtils, onFinish, onClose, project, loading } = props;
  
  


    return (
          <ModalWrapper  
                title="" 
                footer={null}
                visible={visible}
                onCancel={onClose}
               
                closable={true}
               
                >
            
            <div className="mt-10">
        <ExtraFeaturesComponent hideFinishButton={true} loadUtils={loadUtils}  project={project} loading={loading} onFinish={onFinish} onFinish2={onFinish}  />
        </div>
          </ModalWrapper>
    );
};

export const UnlockExtraFeatureButton = (props: {onClick: ()=>void}) => {
    return <Space direction="vertical" className="w-full mt-10 flex items-center justify-center text-xl"><Typography.Title className="mr-2">This Features is locked!</Typography.Title> <Button type={'primary'} size="large" onClick={props.onClick}>Unlock</Button></Space>
}