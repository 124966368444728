import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const CREATE_WHITELIST = createActionType(
  "CREATE_WHITELIST",
  "WHITELIST"
);

export const createWhitelist = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: CREATE_WHITELIST.START,
  meta: {
    ...options,
    payload,
  },
});
