import { ActionOption } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/state";
import { useEffect } from "react";
import {
  createProject,
  updateProject,
  deleteProject,
  getAllProjects,
  getProject,
} from "../../redux/actions";
import { ProjectNamespace } from "../../_shared/namespaces/project";

interface UseProject {
  project: ProjectNamespace.Project;
  allProjects: ProjectNamespace.Project[];
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => void;
  handleGetAll: (options?: ActionOption) => void;
  handleDelete: (id: string, options?: ActionOption) => void;
  handleGet: (id: string, options?: ActionOption) => void;
}

interface UseProjectProps {
  key: string;
  autoFetch?: boolean;
  options?: ActionOption;
}

export const useProject = (parameter: UseProjectProps): UseProject => {
  const { key, autoFetch = false, options: defaultOptions = {} } = parameter;

  const dispatch = useDispatch();

  const { project, allProjects } = useSelector((state: RootState) => {
    return {
      project: state.project.project[key] ?? {},
      allProjects: state.project.allProjects[key] ?? [],
    };
  });

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      createProject(payload, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleUpdate = (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => {
    dispatch(
      updateProject(payload, id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(
      deleteProject(id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGetAll = (options?: ActionOption) => {
    dispatch(
      getAllProjects({
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGet = (id: string, options?: ActionOption) => {
    dispatch(
      getProject(id, {
        key: key,
        ...defaultOptions,
        ...options,
        
      })
    );
  };

  useEffect(() => {
    if (autoFetch) {
      handleGetAll({
        key: key,
      });
    }
  }, [autoFetch]);

  return {
    project,
    allProjects,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleGetAll,
    handleGet,
  };
};
