import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_ALL_PROJECT = createActionType("GET_ALL_PROJECT", "PROJECT");

export const getAllProjects = (options?: ActionOption) => ({
  type: GET_ALL_PROJECT.START,
  meta: {
    ...options,
  },
});
