
import {
  
  Typography,
  theme,
} from "antd";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
const { Text } = Typography;
const { useToken } = theme;


interface VestingScheduleSectionProps {
  project?: ProjectNamespace.Project;
}
export const VestingScheduleSection = (props: VestingScheduleSectionProps) => {
  const { token } = useToken();
  const { project } = props;

  const vestingSchedule = project?.vestingSchedule ?? [];

  return (
    <div
      className="flex w-full flex-col relative sac-schedule "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout1 */}
      <div className="flex  mt-[35px] mb-[40px] mx-[40px]  justify-between">
        <Text className="text-lg font-semibold">Vesting Schedule</Text>
      </div>

      {/* layout 2 */}
      <div className="flex flex-col mb-[20px] mx-[50px] justify-between gap-5">
        <div className="flex gap-2 items-center justify-between">
          <Text className="" disabled>
            Day
          </Text>
          <Text className="" disabled>
            Percentage Claimable
          </Text>
        </div>
      </div>

      {/* layout3 */}
      <div className="flex flex-col mb-[40px] mx-[30px] justify-between gap-[2px]">
        {vestingSchedule.map((schedule) => (
          <div className="flex gap-2 py-4 items-center justify-between px-[20px] border-solid border-0 border-b-[1px]  ">
            <Text className="">
              {new Date(schedule.timestamp * 1000).toDateString()}
            </Text>
            <Text className="">{schedule.percentage}%</Text>
          </div>
        ))}
      </div>
    </div>
  );
};
