import { useContext, useEffect, useState } from "react";
import {
  LandingMainHeader,
  MainFooter,
  MainHeader,
  SectionOne,
  SectionThree,
  SectionTwo,
} from "../../components";
import { CarouselSection } from "../../components/landing/carousel";
import { CommunitySection } from "../../components/landing/community";
import { FeatureSection } from "../../components/landing/features";
import { SacTableSection } from "../../components/landing/sac_table";
import { FaqsSection } from "../../components/landing/section_3/lib/faqs";
import { WhySection } from "../../components/landing/why";
import { WhyPulsacSection } from "../../components/landing/why_pulsac";
import { Container } from "./index.styled";
import { useProject } from "../../hooks";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import Web3 from "web3";
import { WalletConnectorContext } from "../../contexts";
import { sacAdminAbi } from "../../_shared/ABIcode/sac-admin";
import { PSAC_TOKEN_ADDRESS, SAC_ADMIN_ADDRESS, sacToken } from "../../_shared";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export const LandingContainer = () => {
  const KEY = `@@landing-page`;
  const { hash } = useLocation();
  const navigate = useNavigate();

  

  useEffect(() => {
    if(!hash) return;
    document?.getElementById(hash.slice(1))?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }, [hash]);

  const { allProjects, handleGet } = useProject({
    key: KEY,
  });
  const { provider, address } = useContext(WalletConnectorContext);

  const [tableOrder, setTableOrder] = useState<"asc" | "desc">("desc");
  const [tableFilter, setTableFilter] = useState<
    "trending" | "recentlyListed" | "upcoming" | "completed"
  >("trending");
  const [sacProjects, setSacProjects] = useState<ProjectNamespace.Project[]>(
    []
  );
  const [spotlightProjects, setSpotlightProjects] = useState<
    ProjectNamespace.Project[]
  >([]);
  const [features, setFeatures] = useState<ProjectNamespace.FeatureData>();
  const [votingCost, setVotingCost] = useState("");
  const [toggleTable, setToggleTable] = useState(false);

  useEffect(() => {
    handleGet(`spotlights`, {
      onFinish: (data: any) => {
        console.log({ spotlights: "spotlights", data });
        setSpotlightProjects(data);
      },
    });
    handleGet(`features`, {
      onFinish: (data: any) => {
        console.log({ features: "features", data });
        setFeatures(data);
      },
    });
  }, []);

  useEffect(() => {
    handleGet(``, {
      onFinish: (data: any) => {
        setSacProjects(data);
      },
      params: {
        sort: tableFilter,
        order: tableOrder,
        perPage: 10,
      },
    });
  }, [tableFilter, tableOrder, toggleTable]);

  function checkContractAllowace() {
    console.log({ SAC_ADMIN_ADDRESS, address });
    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    psacContract.methods["allowance"](address, SAC_ADMIN_ADDRESS)
      .call()
      .then((amountInWei: any) => {
        console.log({ amountInWei });
      })
      .catch((e: any) => {
        console.log({ e });
      });
  }

  useEffect(() => {
    checkVoteCost();
  }, []);

  function updateContractActive() {
    console.log({ SAC_ADMIN_ADDRESS, address });
    const price = "1000000000000000000000000";
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    // allowance // call
    psacContract.methods["approve"](SAC_ADMIN_ADDRESS, price)
      .send({
        from: address,
      })
      .then((amountInWei: any) => {
        console.log({ amountInWei });
        contract.methods["activate"](
          "200",
          ["whitelistBonus", "referralBonus"],
          {
            timestamp: "1687788261",
            price,
            signature:
              "0x215b41def85ce18868d35a3e97b949473db77234dd4032b0abe026153d9f3ca9021c2c05b33a4fcd58acf33f5c963152a216c93f2894c35b4fe61c096123d9711c",
          }
        )
          .send({
            from: address,
          })
          .then((amountInWei: any) => {
            console.log({ amountInWei });
          })
          .catch((e: any) => {
            console.log({ e });
          });
      })
      .catch((e: any) => {
        console.log({ e });
      });
  }

  const checkVoteCost: () => Promise<String> = async () => {
    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );

    try {
      const amountInWei: any = await contract.methods["votingCost"]().call();
      console.log({ checkVoteCost: amountInWei });
      setVotingCost(amountInWei);
      return amountInWei;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  return (
    <Container className="main-select-bg">
      {/* <LandingMainHeader/> */}
      <MainHeader />


      
      <CarouselSection projects={spotlightProjects} />
      {/* <Button
        onClick={() => {
          // updateContractActive();
          checkContractAllowace();
        }}
      >
        Test
      </Button> */}
      <SacTableSection
        projects={sacProjects}
        tableFilter={tableFilter}
        setTableOrder={setTableOrder}
        setTableFilter={setTableFilter}
        votingCost={votingCost}
        setToggleTable={setToggleTable}
      />

      <WhyPulsacSection />
      <WhySection />
      <FeatureSection features={features} />
      <FaqsSection />
      <CommunitySection />
      <MainFooter />
    </Container>
  );
};
