import styled from "styled-components";
import { UtilCard } from "../../../utils";


export const CoverContainer = styled(UtilCard)`

    gap: 20px;
    position: relative;
    .disable{
        color: var(--disabled-color)!important;
    }  
    .muted{
        color: var(--accent2)!important;
        
    }
    .bg-success{
        background-color: var(--accent)!important;
        border-color: var(--accent)!important;
    }
    .ant-divider{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        border-color: var(--border)!important;
    }
    .title-text{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &-1{
            font-size: 18px;
        }
    } 

    .title-text-network{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    } 
    
    .select-after, .ant-select-selector{
        /* background-color: var(--accent)!important; */
        border-radius: 4px!important;
        color: #fff!important;
    }
    .ant-input-number-group-wrapper{
        width: 100%;
    }

    .spin-wrapper{
        position: absolute;
        top: 45%;
        right: 45%;
    }
    .error-text{
        color: #f00!important;
    }
    .ant-input-disabled{
        color: var(--input-placeholder-color)!important;
    }
    .__point_{
        margin:  5px 12px;
       
    }
`;
