import { GlobalToken } from "antd";
import styled from "styled-components";

export const WrapperContainer = styled.div<{ token: GlobalToken }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  .sac {
    .sac-btn {
      background: ${({ token }) => {
        return `linear-gradient(to right, ${token.colorPrimary}, grey, ${token.colorPrimary})`;
      }};
    }
    .top-curve {
      border: ${({ token }) => {
        return `1px solid  ${token.colorPrimary}`;
      }};
      background-color: ${({ token }) => token.colorBgContainerDisabled};
    }
  }

  .project-description {
    .ant-progress-steps-item {
      border-radius: 6px;
    }
  }
  .sac-leadersboard {
    .ant-tabs-nav {
      .ant-tabs-tab {
        color: ${({ token }) => token.colorTextDisabled};
        &.ant-tabs-tab-active .ant-tabs-tab-btn{
          color: ${({ token }) => token.colorText}!important;
        }
      }

      &::before {
        width: fit-content;
      }
    }
    table{
    tr{
      td, th{
        border-bottom: 20px solid ${({token})=>token.colorBgContainer}!important;
        border-right: 20px solid ${({token})=>token.colorBgContainer}!important;
      }
      td{
        background-color: ${({token})=>token.colorBgContainerDisabled}!important;
      }
      
      
    }
  }
  }
`;
