import { Action } from "../../types";
import {
  CALL_METHOD,
  GET_CONTRACTS,
  RESET_METHOD,
  SEND_METHOD,
} from "../../actions";

export interface ContractState {
  contracts: Record<string, any>;
  data: Record<string, Record<string, any>>;
}

export const ContractDefaultState: ContractState = {
  contracts: {},
  data: {},
};

const ContractReducer = (state = ContractDefaultState, action: Action) => {
  switch (action.type) {
    case GET_CONTRACTS.SUCCESS:
      return {
        ...state,
        contracts: action.payload,
      };
    case SEND_METHOD.SUCCESS:
    case CALL_METHOD.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.contractName]: {
            ...state.data[action.payload.contractName],
            ...action.payload.data,
          },
        },
      };
    case RESET_METHOD.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.contractName]: {},
        },
      };
    default:
      return state;
  }
};

export default ContractReducer;
