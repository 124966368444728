import styled from "styled-components";
import { UtilCard } from "../../../utils";


export const CoverContainer = styled(UtilCard)`
    @keyframes color-change {
    0% { color: var(--accent); }
    50% { color: var(--text-color); }
    100% { color: var(--accent); }
    }
    gap: 20px; 
    ._count_text{
        word-spacing: 20px;
        font-size: 32px;
        animation: color-change 4s infinite;
    }
    .__upper{
        text-transform: uppercase;
    }
`;
