import { NewProjectComponent } from "../../components";
import { useProject, useUIState } from "../../hooks";
import { message, notification } from "antd";

import { useContext, useState } from "react";
import { Container } from "./index.styled";
import { useNavigate } from "react-router-dom";
import { WalletConnectorContext } from "../../contexts";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ProjectNamespace } from "../../_shared/namespaces/project";
dayjs.extend(duration);

export const NewProjectContainer = () => {
  const KEY = `@@create-new-project`;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const { uiLoaders } = useUIState();
  const loading = uiLoaders[KEY];
  const [totalPhaseDays, setTotalPhaseDays] = useState(0);
  const [project, setProject] = useState<ProjectNamespace.Project>();
  const {
    address,
    connect,
    loading: loadingConnect,
  } = useContext(WalletConnectorContext);
  const { handleCreate } = useProject({
    key: KEY,
  });

  const handleComplete = (data: any, cb?: () => void) => {
    const { phases, uplineMustBeSacrificer, startDayTime } = data;
    const completeData = {
      ...formData,
      ...data,
      uplineMustBeSacrificer: !!uplineMustBeSacrificer,
      startDayTime: startDayTime.unix(),
      // vestingSchedule: data.vestingSchedule.map((vestingSchedule:any)=>({
      //   ...vestingSchedule,
      //   timestamp: vestingSchedule.timestamp.unix()
      // })),
      // vestingSchedule:[],
      introVideo: !!data.introVideo ? data.introVideo : null,
    };
    console.log("data: any", completeData, { ...data });
    handleCreate(completeData, {
      onFinish: (project: Record<string, any>) => {
        setProject(project as ProjectNamespace.Project);
        cb?.();
      },
    });

    // router.push('/projects/new/1')
  };
  const handleBackArrow = () => {
    navigate(-1);
  };
  const _OnSubmitFormOne = (data: any) => {
    const { spotlightImage } = data;
    // console.log({data})
    setFormData((oldData) => ({
      ...oldData,
      ...data,
      banner: spotlightImage,
    }));
  };

  const _OnSubmitFormTwo = (data: any) => {
    const { phases, uplineMustBeSacrificer, startDayTime } = data;
    setFormData((oldData) => ({
      ...oldData,
      ...data,
      uplineMustBeSacrificer: !!uplineMustBeSacrificer,
      startDayTime: startDayTime.unix(),
    }));

    const daysDiff =
      dayjs
        .duration(startDayTime.startOf("day").diff(dayjs().startOf("day")))
        .asDays() +
      phases.reduce((prev: number, current: any) => prev + current.duration, 0);
    setTotalPhaseDays(daysDiff);
    console.log("typeof startDay", typeof startDayTime);
    console.log(
      "data",
      { ...data, startDayTime: data.startDayTime.unix() },
      totalPhaseDays
    );
  };
  const handleConnect = () => {
    connect?.();
  };
  const onSubmitFormFour = () => {
    message.success("Project Created Successfully");
    navigate(`/projects/${project?.id}/success`);
  };
  return (
    <Container>
      <NewProjectComponent
        project={project}
        handleBackArrow={handleBackArrow}
        onComplete={handleComplete}
        _OnSubmitFormOne={_OnSubmitFormOne}
        _OnSubmitFormTwo={_OnSubmitFormTwo}
        onSubmitFormFour={onSubmitFormFour}
        loading={loading}
        loadingConnect={loadingConnect}
        handleConnect={handleConnect}
        address={address}
        totalPhaseDays={totalPhaseDays}
      />
    </Container>
  );
};
