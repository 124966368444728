import { Button, theme, Grid, Avatar } from "antd";
import { CameraOutlined, EyeOutlined, DollarOutlined } from "@ant-design/icons";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { useLocation, useNavigate } from "react-router-dom";
import Pen from "../../icons/Pen";
import Management from "../../icons/Management";
import Bonus from "../../icons/Bonus";
import Token from "../../icons/Token";
import { useState, useMemo } from "react";
import { ManageProjectForms, moneyFormat } from "../../../_shared";
import { toEther } from "../../../web3/libs/utilities";

const { useToken } = theme;
const { useBreakpoint } = Grid;

const menus = [
  {
    title: "Manage Network",
    icon: <Management className="text-primary" />,
    p: ManageProjectForms.network,
  },
  {
    title: "Whitelist Bonus",
    icon: <Bonus />,
    p: ManageProjectForms.whitelistBonus,
  },
  { title: "Volume Bonus", icon: <Bonus />, p: ManageProjectForms.volumeBonus },
  { title: "Admin Bonus", icon: <Bonus />, p: ManageProjectForms.adminBonus },
  {
    title: "PSAC Community Bonus",
    icon: <Bonus />,
    p: ManageProjectForms.psacBonus,
  },
  {
    title: "Distribute Tokens",
    icon: <Token />,
    p: ManageProjectForms.distributeToken,
  },
  {
    title: "Referral Bonus",
    icon: <DollarOutlined />,
    p: ManageProjectForms.referralBonus,
  },
];

interface ProjectSideBarProps {
  project: ProjectNamespace.Project;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const ProjectSideBar = (props: ProjectSideBarProps) => {
  const { project } = props;

  const { token } = useToken();
  const navigate = useNavigate();
  const query = useQuery();
  const { lg } = useBreakpoint();
  // const [selectedMenu, setSelectedMenu] = useState(0);
  const editProject = (id: number) => {
    navigate(`/projects/${id}/edit`)
  }

  return (
    <div className="hidden lg:block w-[317px] max-w-[317px] bg-[#00000026]">
      {/* profile component */}
      <div className="w-full   lg:min-w-[317px] lg:max-w-[317px]  lg:pb-10">
        <div className="pl-[7px] mx-5">
          <div className="lg:mt-[51px] lg:mx-auto w-fit relative">
            <Avatar
              className="border-[3px] border-solid border-[#666]"
              shape="square"
              size={95}
              src={project.logo}
            />
            <input type="file" className="hidden" name="avatar" id="avatar" />
            <div className="w-[29px] h-[26px] absolute bottom-0 lg:-bottom-1 -right-2 cursor-pointer bg-primary rounded-[3px] flex itme-center justify-center">
              <CameraOutlined className="text-white" />
            </div>
          </div>
          <h4 className="text-[26px] font-medium mt-6 text-white lg:text-center mb-0">
            {project.title}
          </h4>
          <div className="flex items-center justify-between lg:justify-end mt-5 lg:mt-0 lg:mr-5">
            <Button
            onClick={()=>editProject(project.id)}
              type="text"
              className="border-primary h-[42px] bg-primary lg:bg-transparent rounded-lg text-base leading-[18px] lg:leading-5 flex items-center lg:block"
            >
              <Pen  className="text-white lg:hidden mr-2" /> Edit{" "}
             
            </Button>
            <Button className="flex lg:hidden border-primary bg-transparent text-base font-medium h-10 items-center gap-2">
              <EyeOutlined className="text-lg h-[18px] pb-0.5" /> View Public
              Profile
            </Button>
          </div>
        </div>
        <div className="mt-5 border-0 border-t-4 border-solid border-[#28303F] ">
          <ul className="p-0 m-0 mx-0 pt-[31px] border-0 lg:border-t border-solid border-[#28303F]">
            <li className="px-[21px] py-[22px] flex items-center justify-between border-0 lg:border-b border-solid border-[#28303F]">
              <p className="text-text-primary text-base font-medium m-0">
                Amount Raised
              </p>
              <p className="text-[#18D040]  number-font text-base font-semibold m-0 number-font">
                $
                {moneyFormat(
                  toEther(String(project.totalSacrificedValue ?? 0), 2),
                  2
                )}
              </p>
            </li>
            <li className="px-[21px] py-[22px] flex items-center justify-between border-0 lg:border-b border-solid border-[#28303F]">
              <p className="text-text-primary text-base font-medium m-0">
                No. of Contributors
              </p>
              <p className="text-white text-base font-semibold m-0 number-font">
                {moneyFormat(project.contributors)}
              </p>
            </li>
            <li className="px-[21px] py-[22px] flex items-center justify-between border-0 lg:border-b border-solid border-[#28303F]">
              <p className="text-text-primary text-base font-medium m-0">
                Total Points Issued
              </p>
              <p className="text-[#18D040] text-base font-semibold m-0 number-font">
                {moneyFormat(project.totalPoints, 0)}
              </p>
            </li>
            {/* <li className="px-[21px] py-[22px] flex items-center justify-between border-0 lg:border-b border-solid border-[#28303F]">
                  <p className="text-text-primary text-base font-medium m-0">
                    Sacrifce Start Date
                  </p>
                  <p className="text-white text-base font-semibold m-0">
                    Mon May 29, 2023
                  </p>
                </li> */}
            {/* <li className="px-[21px] py-[22px] flex items-center justify-between border-0 lg:border-b border-solid border-[#28303F]">
                  <p className="text-text-primary text-base font-medium m-0">
                    Sacrifce End Date
                  </p>
                  <p className="text-white text-base font-semibold m-0">
                    Mon May 29, 2023
                  </p>
                </li> */}
          </ul>
        </div>
      </div>

      <ul className="space-y-[38px] text-text-primary text-base font-medium list-none font-antipasto-pro pl-0">
        {menus.map((menu: any, idx: number) => (
          <li
            onClick={() => navigate(`?p=${menu.p}`)}
            key={idx}
            className={`group w-fit py-3 flex gap-[22px] pl-[25px] hover:text-white hover:bg-[#242D35] cursor-pointer duration-300 pr-[22px] relative hover:after:content-[''] after:h-[48px] after:w-1 after:absolute after:-right-1 after:top-0 after:bg-primary ${
              query.get("p") === menu.p &&
              "after:content-[''] text-white bg-[#242D35]"
            }`}
          >
            {menu.icon}
            {menu.title}
          </li>
        ))}
      </ul>
    </div>
  );
};
