import { createActionType } from "../../../../_shared/utils";
import { ActionOption } from "../../../types";

export const GET_USER_PAST_PROJECT = createActionType(
  "GET_USER_PAST_PROJECT",
  "USER"
);

export const getUserPastProject = (
  id: string,
  suffix: string,
  options?: ActionOption
) => ({
  type: GET_USER_PAST_PROJECT.START,
  meta: {
    ...options,
    id,
    suffix,
  },
});
