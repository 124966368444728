import { Button, Dropdown, Menu, Space, Typography } from "antd";
import { toEther } from "../../../../web3/libs/utilities";
import {
  EMPTY_ADDRESS,
  NETWORKS,
  NETWORKS_TYPE,
  resolveNetworkObject,
} from "../../../../_shared";
import { AcceptedToken } from "../../../../_shared/utils/type";
import { ManageTokenLoadedWrapper } from "./index.styled";
import { ListItemComponent } from "./lib/list-item";
const { Text } = Typography;
export interface ListDataItem {
  name: string;
  price: string;
}
interface ManageTokenLoadedComponentProps {
  listData: ListDataItem[];
  handleAddNewToken: () => void;
  currentNetwork?: NETWORKS_TYPE;
  selectedTokenAddress?: string;
  totalSacUSD: string;
  loadingNetworkSelect: boolean;
  loadingAddToken: boolean;
  loadingWithdrawToken: boolean;
  acceptedTokens?: AcceptedToken[];
  networks: string[];
  projectOwners?: string;
  handleBackButton: (data: any) => void;
  handleRemoveTokenSubmit: (data: any) => void;
  handleWidthdrawToken: (data: any) => void;
}
export const ManageTokenLoadedComponent = (
  props: ManageTokenLoadedComponentProps
) => {
  const {
    handleAddNewToken,
    handleWidthdrawToken,
    handleRemoveTokenSubmit,
    handleBackButton,
    loadingWithdrawToken,
    loadingAddToken,
    networks,
    projectOwners,
    selectedTokenAddress,
    currentNetwork,
    acceptedTokens,
  } = props;

  const renderListData = acceptedTokens?.map((data, key) => (
    <ListItemComponent
      key={key}
      data={data}
      currentNetwork={currentNetwork}
      handleRemoveTokenSubmit={handleRemoveTokenSubmit}
      handleWidthdrawToken={handleWidthdrawToken}
      selectedTokenAddress={selectedTokenAddress}
      loading={loadingAddToken}
      loadingWithdrawToken={loadingWithdrawToken}
    />
  ));

  const _currentNetworkNotAddedNotAdded = !networks?.find((network: string) => {
    return network === currentNetwork?.chainId;
  });
  if (_currentNetworkNotAddedNotAdded) {
    return (
      <Space className="error-space">
        <Text>Current Network has not been Added to the Project</Text>
      </Space>
    );
  }
  if (projectOwners && projectOwners === EMPTY_ADDRESS) {
    return (
      <Space className="error-space">
        <Text>Project has not been deployed to the current network</Text>
      </Space>
    );
  }
  return (
    <ManageTokenLoadedWrapper>
      <div className="hint flex mt-10 w-full ">
        <Button
          type="text"
          className="mr-3"
          onClick={handleBackButton}
          icon={
            <svg
              width="22"
              height="14"
              viewBox="0 0 22 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 7H20.5M1.5 7L7.54545 1.5M1.5 7L7.54545 12.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          }
        />
        <Text style={{ fontSize: "16px" }}>
          Manage Accepted Currencies on the {currentNetwork?.label} network
        </Text>
      </div>
      <Space
        className="top-bar"
        align="center"
        style={{ justifyContent: "end" }}
      >
        <Button
          loading={loadingAddToken}
          onClick={handleAddNewToken}
          type="primary"
          ghost
          className="add-new-token-btn"
        >
          Add New Currency
        </Button>
      </Space>
      <Space className="list-container" direction="vertical">
        {renderListData}
      </Space>
      {!acceptedTokens?.length&&<div className="mt-8">
        <Text className="text-lg" disabled>No currency added </Text>
      </div>}
    </ManageTokenLoadedWrapper>
  );
};
