import styled from "styled-components";
import { UtilCard } from "../../../utils";

export const DesktopWrapper = styled(UtilCard)`
    display: flex!important;
    align-items: flex-start;
    flex-direction: column;
    width: 24%!important;
    border-radius: 4px;


    .sub-body{
        width: 100%;
        padding: 24px 16px!important;
        border-radius: 4px;
        
        .ant-space-item:nth-child(odd) {
            margin-bottom: 0px;
            margin-top: 12px;
        }
        .ant-space-item:nth-child(even) {
            margin-bottom: 12px;
        }
        .title-text{
            font-weight: 500;
            font-size: 25px;
            line-height: 33px;
        }
        .small-title {
            font-size: 13px;
        }
        .amount-text {
            margin-left: 10px;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .sub-body2{
        padding: 24px 16px!important;
        .ant-space-item:nth-child(odd) {
            margin-bottom: 0px;
            margin-top: 0px;
        }
        .ant-space-item:nth-child(even) {
            margin-bottom: 10px;
           
        }
    }
    
    .spin-wrapper{
        position: absolute;
        top: 45%;
        right: 45%;
    }
    .__section-title{
        margin-bottom: 32px;
    }
    @media (max-width: 768px) {
        display: none!important;
    }
`;


export const MobileWrapper = styled(UtilCard)`
    display: flex!important;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    overflow-x: scroll;
    .ant-space-item{
        text-align: center;
    }
    .ant-typography{
        overflow-wrap: normal!important;
        white-space: nowrap!important;
    }
    ::-webkit-scrollbar {
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--landing-background-3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--background-primary);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--landing-background-2);
    }
    .ant-divider{
        height: 3em!important;
    }
    .__width-100{
        width: 100%;
        justify-content: space-between;
        
    }
    .spin-wrapper{
        position: absolute;
        top: 45%;
        right: 45%;
    }

    

    @media (min-width: 769px) {
        display: none!important;
    }
`;